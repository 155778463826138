import { Box, Grid } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid'; // To generate a new GUID
import { useLoggedInUser } from '../../../app/hooks/useLoggedInUser';
import {
  usePostCustomerMutation,
  usePutCustomerCustomerMutation,
} from '../../../app/redux-fetch/apiQuery';
import SubmitButtons from '../../../components/Buttons/SubmitButtons';
import { ContactCategoryTypeId } from '../../../types/common';
import { useSideModalContext } from '../../AssetsManagement/modals/SideModalContext';
import ContactTable from '../../Contacts/ContactTable';
import Documents from '../../Documents/Documents';
import AddressDetails from '../../Locations/components/AddressDetails';
import DefaultValues from '../../Locations/components/DefaultValues';
import { ICustomer, ILocation } from '../../types';
import { getCustomerValidationSchema } from './Components/validationSchema';
import CustomerDetail from './CustomerDetail';

type CustomerFormProps = {
  customer: ICustomer
  isDisabled: boolean
}

const CustomerForm = (props: CustomerFormProps) => {
  const { customer, isDisabled } = props
  const { tenantId } = useLoggedInUser()

  const { onClose } = useSideModalContext()
  const { formatMessage } = useIntl()

  const isNew = !customer.id

  const validationSchema = getCustomerValidationSchema(formatMessage)

  const [createCustomer] = usePostCustomerMutation()
  const [putCustomer] = usePutCustomerCustomerMutation()

  return (
    <>
      <Formik
        initialValues={customer}
        enableReinitialize={true}
        onSubmit={async (values: FormikValues) => {
          if (!values.id) {
            const location = {
              id: 0,
              addressId: 0,
              customerId: 0,
              name: `${values.name}-${uuidv4()}`,
              tenantId,
              locationTypeId: 0,
              address: values.address,
              defaultTransportModeId: values.defaultTransportModeId,
              defaultCurrencyId: values.defaultCurrencyId,
              lat: values.lat,
              lng: values.lng,
              geofence: values.geofence,
            } as ILocation
            await createCustomer({ tenantId, body: { ...values, location: location } as ICustomer })
          } else {
            const location = {
              id: values.locationId,
              addressId: values.addressId,
              customerId: values.id,
              name: values.locationName,
              tenantId,
              locationTypeId: 0,
              address: values.address,
              defaultTransportModeId: values.defaultTransportModeId,
              defaultCurrencyId: values.defaultCurrencyId,
              lat: values.lat,
              lng: values.lng,
              geofence: values.geofence,
            } as ILocation
            await putCustomer({ tenantId, body: { ...values, location: location } as ICustomer })
          }
          onClose()
        }}
        validationSchema={validationSchema}
      >
        <Form>
          <Grid container spacing={2} sx={{ p: '48px', mt: '48px', mb: '48px' }}>
            <Grid display='flex' flexDirection='row' gap={4} width='100%'>
              <Grid display='flex' flexDirection='column' width='50%'>
                <CustomerDetail isDisabled={isDisabled} isNew={isNew} />
                <DefaultValues isDisabled={isDisabled} />
                <ContactTable isDisabled={isDisabled} contactCategoryId={ContactCategoryTypeId.customer} />
              </Grid>
              <Grid display='flex' flexDirection='column' width='50%'>
                <Grid item xs={12}>
                  <AddressDetails isDisabled={isDisabled} />
                </Grid>
                <Grid item xs={12}>
                  <Documents categoryId={2} entityId={customer.id} isDisabled={isDisabled} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              width: '100%',
              padding: '16px',
              backgroundColor: '#fff',
              borderTop: '1px solid #e0e0e0',
            }}
          >
            {!isDisabled && <SubmitButtons create={isNew} isEnabled={true} />}
          </Box>
        </Form>
      </Formik>
    </>
  )
}

export default CustomerForm
