import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useLoggedInUser } from '../../../app/hooks/useLoggedInUser'
import { useGetShipmentByIdQuery } from '../../../app/redux-fetch/apiShipment'
import SpinnerBoxBlock from '../../../components/Spinner/SpinnerBoxBlock'
import { IShipmentFormInput } from './schema'
import ShipmentForm from './ShipmentForm'
import { mapToShipmentFormInput } from './utils'

const ShipmentFormContainer: React.FC = () => {
  const { id } = useParams<{ id?: string }>()
  const [initialValues, setInitialValues] = useState<Partial<IShipmentFormInput>>({})

  const { tenantId, customerId } = useLoggedInUser()
  const navigate = useNavigate()
  const { data: shipmentData, isSuccess: shipmentIsLoaded } = useGetShipmentByIdQuery(
    {
      tenantId: tenantId,
      shipmentId: Number(id),
    },
    { skip: !id }
  )

  const isEdit = Boolean(id)

  useEffect(() => {
    if (isEdit && id) {
      if (shipmentData) {
        const isSubmitted = shipmentData.status === 'Submitted'
        const isAccepted = shipmentData.status === 'Accepted'
        if (isSubmitted || isAccepted) {
          navigate('/shipping/shipments')
        }
        setInitialValues(mapToShipmentFormInput(shipmentData))
      }
    } else {
      setInitialValues({
        customerId,
        tenantId,
        bookings: [
          {
            shipFrom: {
              transitDetails: {
                carrierOption: 'noPreference',
              },
            },
          } as any,
        ],
      })
    }
  }, [isEdit, id, shipmentData])

  const handleSubmit = async (data: IShipmentFormInput) => {
    // Stub for the hadnle submit
    console.log('')
  }

  if (isEdit && !shipmentIsLoaded) {
    return (
      <>
        <SpinnerBoxBlock />
      </>
    )
  }

  return <ShipmentForm initialValues={initialValues} onSubmit={handleSubmit} isEdit={isEdit} />
}

export default ShipmentFormContainer
