import { Box, Tab, Tabs, styled } from '@mui/material'
import { ReactNode, SyntheticEvent, useState } from 'react'
import { a11yProps } from '../../app/utils'
import TabPanel from '../TabPanel/TabPanel'

export interface TabItem {
    label: string | ReactNode
    content: ReactNode
    disabled?: boolean
}

export interface TabWrapperProps {
    tabs: TabItem[]
    ariaLabel?: string
    defaultTab?: number
    variant?: 'standard' | 'scrollable' | 'fullWidth'
    onChange?: (index: number) => void
}

const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.secondary.main
    }
}))

const TabWrapper = ({
    tabs,
    ariaLabel = 'scrollable tabs',
    defaultTab = 0,
    variant = 'standard',
    onChange
}: TabWrapperProps) => {
    const [activeTab, setActiveTab] = useState(defaultTab)

    const handleChange = (_event: SyntheticEvent, tabId: number) => {
        setActiveTab(tabId)
        onChange?.(tabId)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <StyledTabs
                value={activeTab}
                onChange={handleChange}
                variant={variant}
                scrollButtons={variant === 'scrollable'}
                textColor="secondary"
                aria-label={ariaLabel}
                sx={{
                    pb: '16px'
                }}
            >
                {tabs.map((tab, index) => (
                    <Tab
                        key={index}
                        label={tab.label}
                        disabled={tab.disabled}
                        {...a11yProps(index)}
                    />
                ))}
            </StyledTabs>
            {tabs.map((tab, index) => (
                <TabPanel key={index} value={activeTab} index={index}>
                    {tab.content}
                </TabPanel>
            ))}
        </Box>
    )
}

export default TabWrapper
