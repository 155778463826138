import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  serviceTableTitle: {
    id: 'service.tableTitle',
    defaultMessage: 'Services',
  },
  name: {
    id: 'service.name',
    defaultMessage: 'Name',
  },
  description: {
    id: 'service.description',
    defaultMessage: 'Description',
  },
  code: {
    id: 'service.code',
    defaultMessage: 'Code',
  },
  customerSelectable: {
    id: 'service.customerSelectable',
    defaultMessage: 'Customer Selectable',
  },
  enabled: {
    id: 'service.enabled',
    defaultMessage: 'Enabled',
  },
  loadTypes: {
    id: 'service.loadTypes',
    defaultMessage: 'Load Types',
  },
  inUse: {
    id: 'service.inUse',
    defaultMessage: 'In Use',
  },
  newServiceBtn: {
    id: 'service.newBtn',
    defaultMessage: 'Add',
  },
  editBtn: {
    id: 'service.editBtn',
    defaultMessage: 'Edit',
  },
  cancelBtn: {
    id: 'service.cancelBtn',
    defaultMessage: 'Cancel',
  },
  createEditService: {
    id: 'service.createEdit',
    defaultMessage: 'Create / Edit Service',
  },
  serviceDeleted: {
    id: 'service.deleted',
    defaultMessage: 'Service Deleted',
  },
  serviceCreated: {
    id: 'service.created',
    defaultMessage: 'Service Created',
  },
  serviceUpdated: {
    id: 'service.updated',
    defaultMessage: 'Service Updated',
  },
  serviceNotUpdated: {
    id: 'service.notUpdated',
    defaultMessage: 'Service Not Updated',
  },
  serviceNotAdded: {
    id: 'service.notAdded',
    defaultMessage: 'Service Not Added',
  },
  serviceNotDeleted: {
    id: 'service.notDeleted',
    defaultMessage: 'Service Not Deleted',
  },
  addLoadType: {
    id: 'service.addLoadType',
    defaultMessage: 'Add Load Type',
  },
  loadTypeName: {
    id: 'service.loadTypeName',
    defaultMessage: 'Load Type Name',
  },
  selectOrAddLoadType: {
    id: 'service.selectOrAddLoadType',
    defaultMessage: 'Select or add a load type...',
  },
})
