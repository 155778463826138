import { Box, Button, Switch, TextField } from '@mui/material'
import { ReactElement, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../app/hooks'
import { useGetLocationsQuery } from '../../../app/redux-fetch/apiCoreQuery'
import { useGetCustomersQuery } from '../../../app/redux-fetch/apiQuery'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'
import FormDropdown from '../../../components/FormDropDown/FormDropdown'
import RequiredAsterisk from '../../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography, SectionTitleTypography } from '../../../components/Typographies/styles'
import { LocationType } from '../../../types/common'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import { useLocationFormContext } from '../hooks/useLocationFormContext'
import messages from '../messages'

type MainInfoProps = {
    isDisabled?: boolean
    type: 'create' | 'view' | 'edit'
    isTenantUser?: boolean
    isCustomerDisabled?: boolean
    isOrderLocation?: boolean
    locationTypeId: number
    isSelectLocationType?: boolean
    canBeParented?: boolean
}



const MainInfo = ({ isDisabled, type, isTenantUser, isCustomerDisabled, locationTypeId, canBeParented = false, isOrderLocation = false, isSelectLocationType = false }: MainInfoProps): ReactElement<any, any> => {
    const { values, touched, errors, setFieldValue } = useLocationFormContext()
    const { formatMessage } = useIntl()



    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
    const { tenantId } = loggedInUser

    const { data: customersResult = [] } = useGetCustomersQuery(tenantId ?? -1)

    const handleTextFieldChange = ({
        target,
    }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setFieldValue(target.id, target.value)
    }

    const handleFormDropDownChange = (e: any, name: any, newValue: any) => {
        setFieldValue('customerSelectable', undefined)
        setFieldValue(name, newValue)
    }
    const customers = useMemo(() => {
        return isOrderLocation ? customersResult.filter(c => c.id === values.customerId).map((customer) => ({

            name: customer.name,
            id: customer.id,
        })) : customersResult.map((customer) => ({
            name: customer.name,
            id: customer.id,
        }))
    }, [customersResult, isOrderLocation])

    const isCustomerIdNotEditable = type === 'edit' || isCustomerDisabled
    const isCustomerDropdownEnabled = isTenantUser && (type !== 'edit' || (type === 'edit' && !!values.customerId))
    const isNew = type === 'create'

    const { data: locations = [] } = useGetLocationsQuery(
        {
            tenantId: tenantId ?? -1,
        }
    )

    const locationOptions = useMemo(() => {
        return locations.filter(location => {
            if (location.id === values.id) return false;

            if (locationTypeId === LocationType.Terminal) {
                return location.locationTypeId === LocationType.Airport ||
                    location.locationTypeId === LocationType.Seaport;
            }

            if (locationTypeId === LocationType.Standard || locationTypeId === LocationType.FuelStation) {
                return location.locationTypeId !== LocationType.Shipper;
            }

            return true;
        }).map((location) => ({
            id: location.id ?? -1,
            name: location.name ?? ''
        }));
    }, [locations])

    const locationTypeOptions = useMemo(() => [
        { id: LocationType.Standard, name: formatMessage(messages.standard) },
        { id: LocationType.Terminal, name: formatMessage(messages.terminal) },
        { id: LocationType.Seaport, name: formatMessage(messages.seaport) },
        { id: LocationType.Airport, name: formatMessage(messages.airport) },
        { id: LocationType.FuelStation, name: formatMessage(messages.fuelStation) }
    ], [formatMessage]);

    return (
        <Box sx={{ mb: '16px', width: '75%' }}>
            <Box sx={{ mb: '16px' }}>
                <SectionTitleTypography >{formatMessage(messages.mainInfo)}</SectionTitleTypography>
            </Box>
            <Box sx={{ mb: '8px' }}>
                <Box>
                    <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.name)}<RequiredAsterisk /></SectionLabelTypography>
                </Box>
                <TextField
                    disabled={isDisabled || (values.systemDefined && !isNew)}
                    fullWidth
                    id='name'
                    name='name'
                    value={values.name}
                    onChange={handleTextFieldChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={errors.name}
                />
            </Box>

            <ConditionalRender condition={isSelectLocationType}>
                <Box sx={{ mb: '8px' }}>
                    <Box>
                        <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.locationType)}<RequiredAsterisk /></SectionLabelTypography>
                    </Box>
                    <FormDropdown
                        id='locationTypeId'
                        disabled={isDisabled || (type !== 'create')}
                        onChange={handleFormDropDownChange}
                        value={values.locationTypeId}
                        items={locationTypeOptions}
                        error={touched.locationTypeId && Boolean(errors.locationTypeId)}
                        errorText={errors.locationTypeId}
                    />
                </Box>
            </ConditionalRender>

            <ConditionalRender condition={isCustomerDropdownEnabled}>
                <Box >
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.customerLabel)}
                            <ConditionalRender condition={values.locationTypeId === LocationType.Shipper}>
                                <RequiredAsterisk />
                            </ConditionalRender>
                        </SectionLabelTypography>
                        <Button
                            size='small'
                            onClick={() => {
                                setFieldValue('customerId', null)
                            }}
                            sx={{ textTransform: 'none', p: 0, mb: '6px' }}
                        >
                            {formatMessage(messages.clearSelection)}
                        </Button>
                    </Box>
                    <FormDropdown
                        id='customerId'
                        disabled={isDisabled || isCustomerIdNotEditable || (values.systemDefined && !isNew)}
                        onChange={handleFormDropDownChange}
                        value={values.customerId}
                        items={customers}
                        error={values.locationTypeId === LocationType.Shipper && touched.customerId && Boolean(errors.customerId)}
                        errorText={values.locationTypeId === LocationType.Shipper ? errors.customerId : (isCustomerIdNotEditable ? '' : formatMessage(messages.customerOptionalLabel))}
                    />
                </Box>
            </ConditionalRender>
            <ConditionalRender condition={canBeParented}>
                <Box sx={{ mt: 2 }}>
                    <Box>
                        <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.parentLocation)}</SectionLabelTypography>
                    </Box>
                    <FormDropdown
                        id='parentLocationId'
                        disabled={isDisabled || isCustomerIdNotEditable || (values.systemDefined && !isNew)}
                        onChange={handleFormDropDownChange}
                        value={values.parentLocationId}
                        items={locationOptions}
                        error={(touched.parentLocationId as any) && Boolean(errors.parentLocationId)}
                        errorText={errors.parentLocationId}
                    />
                </Box>
            </ConditionalRender>
            <ConditionalRender condition={!values.customerId}>
                <Box sx={{ mt: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <SectionLabelTypography variant="subtitle1">
                            {formatMessage(messages.customerSelectable)}
                        </SectionLabelTypography>
                        <Switch
                            id="isActive"
                            checked={values.customerSelectable}
                            onChange={(event) => setFieldValue('customerSelectable', event.target.checked)}
                            disabled={isDisabled}
                        />
                    </Box>
                </Box>
            </ConditionalRender>
        </Box>
    )
}

export default MainInfo