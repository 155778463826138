import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Collapse, List, ListItemText } from '@mui/material'
import { useEffect, useState } from 'react'
import { StyledSubCategoryListItemButton } from './styles'

interface SubCategoryItemProps {
  label: string
  action: () => void
  isSelected: boolean
  selectedPath?: string
  items?: Array<{
    name: string
    action: () => void
    items?: Array<any>
  }>
  level?: number
}

export const SubCategoryItem = ({
  label,
  action,
  isSelected,
  selectedPath = '',
  items = [],
  level = 0
}: SubCategoryItemProps) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (selectedPath.includes(label)) {
      setOpen(true)
    }
  }, [selectedPath, label])

  const isItemSelected = isSelected || selectedPath === label ||
    (selectedPath.startsWith(label + '/') && level === 0) ||
    (selectedPath.includes(label) && level > 0)

  const handleClick = (e: React.MouseEvent) => {
    if (items.length > 0) {
      e.stopPropagation()
      setOpen(!open)
    } else {
      action()
    }
  }

  return (
    <>
      <StyledSubCategoryListItemButton
        onClick={handleClick}
        isSelected={isItemSelected}
        sx={{
          pl: 2 + level * 2,
          margin: '4px 16px',
        }}
      >
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            fontSize: '14px',
          }}
        />
        {items.length > 0 && (open ? <ExpandLess /> : <ExpandMore />)}
      </StyledSubCategoryListItemButton>
      {items.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List disablePadding>
            {items.map((item, index) => (
              <SubCategoryItem
                key={index}
                label={item.name}
                action={item.action}
                isSelected={selectedPath === `${label}/${item.name}`}
                selectedPath={selectedPath}
                items={item.items}
                level={level + 1}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  )
}
