import { Grid } from '@mui/material'
import { useIntl } from 'react-intl'
import TabWrapper, { TabItem } from '../../../components/TabWrapper/TabWrapper'
import AssetCategoryTable from './components/AssetCategory/AssetCategoryTable'
import AssetClassTable from './components/AssetClass/AssetClassTable'
import AssetConfigurationTable from './components/AssetConfiguration/AssetConfigurationTable'
import AssetTypeTable from './components/AssetType/AssetTypeTable'
import { messages } from './messages'

const AssetSettings = () => {
  const { formatMessage } = useIntl()

  const tabs: TabItem[] = [
    {
      label: formatMessage(messages.assetCategories),
      content: (
        <Grid item xs={12}>
          <AssetCategoryTable />
        </Grid>
      )
    },
    {
      label: formatMessage(messages.assetTypes),
      content: (
        <Grid item xs={12}>
          <AssetTypeTable />
        </Grid>
      )
    },
    {
      label: formatMessage(messages.assetClasses),
      content: (
        <Grid item xs={12}>
          <AssetClassTable />
        </Grid>
      )
    },
    {
      label: formatMessage(messages.assetConfigurations),
      content: (
        <Grid item xs={12}>
          <AssetConfigurationTable />
        </Grid>
      )
    }
  ]

  return (
    <TabWrapper
      tabs={tabs}
      ariaLabel="Asset settings scrollable tabs"
    />
  )
}

export default AssetSettings
