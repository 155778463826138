import { z } from 'zod'
import { BookingPreference } from '../../../types/common'
import { normalizeDate } from '../../utils'

const toNumberOrUndefined = (val: any): number | undefined => {
  if (val === '' || val === null || val === undefined) return undefined
  const parsed =
    typeof val === 'string' ? parseInt(val, 10) : typeof val === 'number' ? val : Number(val)
  return isNaN(parsed) ? undefined : parsed
}

const containerCollectionSchema = z
  .object({
    id: z.number().optional(),
    shipmentBookingId: z.number().optional(),
    loadTemplateId: z.number().min(1, { message: 'Load template is required' }),
    quantity: z.preprocess(
      toNumberOrUndefined,
      z.number().min(1, { message: 'Quantity is required' })
    ),
    carrierId: z.number().optional(),
    locationId: z.number().optional(),
    croNumber: z.string().optional(),
    croValidFrom: z.date().optional(),
    croValidTo: z.date().optional(),
    carrierOption: z.nativeEnum(BookingPreference).optional(),
    shippingLineId: z.number().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.carrierOption === BookingPreference.PreferredLine) {
      if (!data.loadTemplateId) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Shipping Line is required when Preferred Shipping Line is selected',
          path: ['loadTemplateId'],
        })
      }
    } else if (data.carrierOption === BookingPreference.PreBooked) {
      if (!data.loadTemplateId) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Shipping Line is required',
          path: ['loadTemplateId'],
        })
      }
      if (!data.croNumber) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'CRO Number is required',
          path: ['croNumber'],
        })
      }
      if (!data.croValidFrom) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Valid From date is required',
          path: ['croValidFrom'],
        })
      }
      if (!data.croValidTo) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Valid To date is required',
          path: ['croValidTo'],
        })
      }
      if (!data.locationId) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Empty Collection Terminal is required',
          path: ['locationId'],
        })
      }
    }
  })

export type IContainerCollection = z.infer<typeof containerCollectionSchema>

const transitDetailSchema = z.object({
  id: z.number().optional(),
  carrierId: z.preprocess(toNumberOrUndefined, z.number().optional()),
  crossBorder: z.boolean().optional(),
  modeOfTransit: z
    .number({ message: 'Mode of transit is required' })
    .min(-1, { message: 'Mode of transit is required' }),
  transitIdentifier: z.string().optional(),
  consignmentStopId: z.number().optional(),
  carrierOption: z.string().optional(),
})

const serviceWaypointLoadSchema = z.object({
  id: z.number().optional(),
  serviceWaypointId: z.number().optional(),
  loadId: z.number().optional(),
})

const serviceWaypointSchema = z.object({
  id: z.number().optional(),
  serviceId: z.number().optional(),
  waypointId: z.number().optional(),
  loads: z.array(serviceWaypointLoadSchema).optional(),
})

export const schema = z.object({
  id: z.number().optional(),
  tenantId: z.number().min(1, { message: 'Tenant is required' }),
  customerId: z.number().min(1, { message: 'Customer is required' }),
  revision: z.number().optional(),
  customerReferenceNumber: z.string().min(1, { message: 'Customer Reference Number is required' }),
  shipmentNumber: z.string().optional(),
  submittedDate: z.date().optional(),
  statuses: z.array(z.any()).optional(),
  status: z.string().optional(),
  description: z.string().min(1, { message: 'Description is required' }),
  bookings: z.array(
    z
      .object({
        id: z.number().optional(),
        bookingId: z.number().optional(),
        referenceNumber: z.string(),
        trackingNumber: z.string().optional(),
        loadTypeId: z.number().min(1, { message: 'Load type is required' }),
        shipperId: z
          .number({ message: 'Shipper is required' })
          .min(1, { message: 'Shipper is required' }),
        shipperRevision: z.number().optional(),
        shipper: z.object({}).optional(),
        currencyId: z
          .number({ message: 'Currency is required' })
          .min(1, { message: 'Currency is required' }),
        description: z.string().min(1, { message: 'Description is required' }),
        emptyContainerReturn: z
          .object({
            id: z.number().optional(),
            locationId: z
              .number({ message: 'Empty Container Return location is required' })
              .min(1, { message: 'Empty Container Return location is required' }),
            contactId: z.number().optional().nullable(),
            scheduledDate: z.date().min(new Date('1900-01-01'), { message: 'Too old' }),
            location: z.object({}).optional(),
            locationRevision: z.number().optional(),
            specialInstructions: z.string().optional(),
          })
          .optional(),
        returnTo: z
          .object({
            id: z.number().optional(),
            locationId: z
              .number({ message: 'Location is required' })
              .min(1, { message: 'Empty Container Return location is required' }),
            contactId: z.number().optional().nullable(),
            defaultValue: z.boolean().optional(),
            location: z.object({}).optional(),
            locationRevision: z.number().optional(),
            specialInstructions: z.string().optional(),
          })
          .optional(),
        shipFrom: z.object({
          id: z.number().optional(),
          locationId: z
            .number({ message: 'Ship From location is required' })
            .min(1, { message: 'Ship From location is required' }),
          consignmentId: z.number().optional(),
          location: z.object({}).optional(),
          locationRevision: z.number().optional(),
          returnTo: z.boolean().optional(),
          emptyContainerCollection: z.boolean().optional(),
          contactId: z.number().nullable().optional(),
          clearingRequired: z.boolean().optional(),
          defaultValue: z.boolean().optional(),
          specialInstructions: z.string().optional(),
          loadingDate: z.date().min(new Date('1900-01-01'), { message: 'Too old' }),
          services: z.array(serviceWaypointSchema).optional(),
          transitDetails: transitDetailSchema,
        }),
        deliverTo: z.object({
          id: z.number().optional(),
          consignmentId: z.number().optional(),
          locationId: z
            .number({ message: 'Deliver To location is required' })
            .min(1, { message: 'Deliver To location is required' }),
          clearingRequired: z.boolean().optional().nullable(),
          location: z.object({}).optional(),
          locationRevision: z.number().optional(),
          contactId: z.number().nullable().optional(),
          deliveryDate: z.date().min(new Date('1900-01-01'), { message: 'Too old' }),
          specialInstructions: z.string().optional(),
          emptyContainerReturn: z.boolean().optional(),
          services: z.array(serviceWaypointSchema).optional(),
          transitDetails: transitDetailSchema.optional(),
        }),
        containerCollections: z.array(containerCollectionSchema).optional(),
        packaging: z
          .array(
            z.object({
              id: z.number().optional(),
              packagingTemplateId: z.number().optional(),
              isHazardous: z.boolean().optional(),
              physicalPropertiesId: z.number().optional(),
              shipmentBookingId: z.number().optional(),
              сonsignmentId: z.number().optional(),
              packagingDetailId: z.number().optional(),
              numberOfPackages: z.number({ message: 'Number of packages is required' }).optional(),
              useDeclaredValue: z.boolean().optional(),
              declaredContentDescription: z.string().nullable().optional(),
              weight: z.number().optional(),
              length: z.number().optional(),
              width: z.number().optional(),
              height: z.number().optional(),
              amountInsured: z.number().optional(),
              insuranceRequired: z.boolean().optional(),
              temperatureControlled: z.boolean().optional(),
              temperatureSettingId: z.number().optional(),
              temperatureRange: z.number().optional(),
              setPointUnitId: z.number().optional(),
              setPoint: z.preprocess(
                (val) => (val === '' ? undefined : val),
                z.number().optional()
              ),
              lowerWarning: z.preprocess(
                (val) => (val === '' ? undefined : val),
                z.number().optional()
              ),
              upperWarning: z.preprocess(
                (val) => (val === '' ? undefined : val),
                z.number().optional()
              ),
              lowerCritical: z.preprocess(
                (val) => (val === '' ? undefined : val),
                z.number().optional()
              ),
              upperCritical: z.preprocess(
                (val) => (val === '' ? undefined : val),
                z.number().optional()
              ),
              isStackable: z.boolean().optional(),
              weightUnit: z.number().optional(),
              dimensionUnit: z.number().optional(),
              declaredValue: z.preprocess(
                (val) => (val === '' ? null : val),
                z.number().nullable().optional()
              ),
              serviceId: z.string().nullable().optional(),
              products: z
                .array(
                  z
                    .object({
                      id: z.number().optional(),
                      productDescription: z.string().optional(),
                      productId: z.number().nullable().optional(),
                      countryOfOriginId: z.number().nullable().optional(),
                      quantity: z.number({ message: 'Quantity is required' }),
                      unitPrice: z.number().optional(),
                      useProduct: z.boolean().optional(),
                      defaultValue: z.boolean().optional(),
                      productName: z.string().optional(),
                    })
                    .superRefine((data, ctx) => {
                      if (data.useProduct) {
                        if (!data.productId) {
                          ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: 'Product is required when using product',
                            path: ['productId'],
                          })
                        }
                      } else if (
                        !data.productDescription ||
                        data.productDescription.trim() === ''
                      ) {
                        ctx.addIssue({
                          code: z.ZodIssueCode.custom,
                          message: 'Product description is required when not using product',
                          path: ['productDescription'],
                        })
                      }
                    })
                )
                .optional(),
            })
          )
          .optional(),
        waypoints: z
          .array(
            z.object({
              id: z.number().optional(),
              locationId: z
                .number({ message: 'Waypoint location is required' })
                .min(1, { message: 'Waypoint location is required' }),
              clearingRequired: z.boolean().optional(),
              loadingDate: z.date().min(new Date('1900-01-01'), { message: 'Too old' }),
              specialInstructions: z.string().optional(),
              consignmentId: z.number().optional(),
              location: z.object({}).optional(),
              locationRevision: z.number().optional(),
              contactId: z.number().nullable().optional(),
              transitDetails: transitDetailSchema,
              services: z.array(serviceWaypointSchema).optional(),
            })
          )
          .optional(),
      })
      .superRefine((data, context) => {
        const { shipFrom, waypoints, deliverTo, emptyContainerReturn } = data
        const loadingDate = shipFrom?.loadingDate
        const deliveryDate = deliverTo?.deliveryDate
        const emptyContainerReturnDate = emptyContainerReturn?.scheduledDate

        let previousDate: any = null

        if (!loadingDate) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Ship From Scheduled Date is required',
            path: ['shipFrom', 'loadingDate'],
          })
          return
        } else {
          const loadDate = normalizeDate(loadingDate)
          if (previousDate && loadDate <= previousDate) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Scheduled Date must be after previous Waypoint Scheduled Date.',
              path: ['shipFrom', 'loadingDate'],
            })
          }
          previousDate = loadDate
        }

        if (waypoints && waypoints.length > 0) {
          waypoints.forEach((waypoint, index) => {
            const waypointDate = waypoint.loadingDate
            if (!waypointDate) {
              context.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Waypoint ${index + 1}: Scheduled date is required`,
                path: ['waypoints', index, 'loadingDate'],
              })
            } else {
              const wDate = normalizeDate(waypointDate)
              if (previousDate && wDate <= previousDate) {
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Scheduled Date must be after previous Waypoint Scheduled Date.',
                  path: ['waypoints', index, 'loadingDate'],
                })
              }
              previousDate = wDate
            }
          })
        }

        if (deliveryDate) {
          const delDate = normalizeDate(deliveryDate)
          if (previousDate && delDate <= previousDate) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Scheduled Date must be after previous Waypoint Scheduled Date.',
              path: ['deliverTo', 'deliveryDate'],
            })
          }
          previousDate = delDate
        }

        if (emptyContainerReturnDate) {
          const ecrDate = normalizeDate(emptyContainerReturnDate)
          if (previousDate && ecrDate <= previousDate) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Scheduled Date must be after previous Waypoint Scheduled Date.',
              path: ['emptyContainerReturn', 'scheduledDate'],
            })
          }
        }

        // Cross border validation
        const isCrossBorder =
          shipFrom?.transitDetails?.crossBorder === true ||
          deliverTo?.transitDetails?.crossBorder === true ||
          (waypoints || []).some((wp) => wp?.transitDetails?.crossBorder === true)

        if (isCrossBorder) {
          if (data.packaging && Array.isArray(data.packaging)) {
            data.packaging.forEach((pack, pIndex) => {
              if (pack.products && Array.isArray(pack.products)) {
                pack.products.forEach((product, prodIndex) => {
                  if (!product.countryOfOriginId) {
                    context.addIssue({
                      code: z.ZodIssueCode.custom,
                      message: 'Country of Origin is required',
                      path: ['packaging', pIndex, 'products', prodIndex, 'countryOfOriginId'],
                    })
                  }
                })
              }
            })
          }
        }

        // Temperature control validation
        if (data.packaging && Array.isArray(data.packaging)) {
          data.packaging.forEach((pack, pIndex) => {
            if (pack.temperatureControlled) {
              if (pack.temperatureRange === undefined || pack.temperatureRange === null) {
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Temperature Range is required',
                  path: ['packaging', pIndex, 'temperatureRange'],
                })
              }
              if (pack.setPoint === undefined || pack.setPoint === null) {
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Set Point is required',
                  path: ['packaging', pIndex, 'setPoint'],
                })
              }
              if (!pack.setPointUnitId) {
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Set Point Unit is required',
                  path: ['packaging', pIndex, 'setPoint'],
                })
              }
              if (pack.lowerWarning === undefined || pack.lowerWarning === null) {
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Lower Warning is required',
                  path: ['packaging', pIndex, 'lowerWarning'],
                })
              }
              if (pack.upperWarning === undefined || pack.upperWarning === null) {
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Upper Warning is required',
                  path: ['packaging', pIndex, 'upperWarning'],
                })
              }
              if (pack.lowerCritical === undefined || pack.lowerCritical === null) {
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Lower Critical is required',
                  path: ['packaging', pIndex, 'lowerCritical'],
                })
              }
              if (pack.upperCritical === undefined || pack.upperCritical === null) {
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Upper Critical is required',
                  path: ['packaging', pIndex, 'upperCritical'],
                })
              }
            }
          })
        }

        // Carrier validation
        const validateCarrierForTransitDetails = (
          transitDetails: any,
          path: (string | number)[]
        ) => {
          if (
            (transitDetails?.carrierOption === 'preferredCarrier' ||
              transitDetails?.carrierOption === 'preBookedTransit') &&
            !transitDetails?.carrierId
          ) {
            switch (transitDetails?.carrierOption) {
              case 'preferredCarrier':
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Carrier is required when Preferred Carrier is selected',
                  path: [...path, 'transitDetails', 'carrierId'],
                })
                break
              case 'preBookedTransit':
                context.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Carrier is required when Pre-Booked Transit is selected',
                  path: [...path, 'transitDetails', 'carrierId'],
                })
                break
            }
          }
        }

        // Check Ship From
        if (shipFrom?.transitDetails) {
          validateCarrierForTransitDetails(shipFrom.transitDetails, ['shipFrom'])
        }

        // Check Waypoints
        if (waypoints) {
          waypoints.forEach((waypoint, index) => {
            if (waypoint.transitDetails) {
              validateCarrierForTransitDetails(waypoint.transitDetails, ['waypoints', index])
            }
          })
        }

        // Check Deliver To
        if (deliverTo?.transitDetails) {
          validateCarrierForTransitDetails(deliverTo.transitDetails, ['deliverTo'])
        }
      })
  ),
})

export type IShipmentFormInput = z.infer<typeof schema>
