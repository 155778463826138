import CancelIcon from '@mui/icons-material/CancelOutlined'
import { Box, Link, Typography } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../../app/hooks'
import { useNotificationStack } from '../../../../../app/hooks/useNotificationStack'
import { useDeleteAssetClassMutation, useGetAssetClassByTenantIdQuery } from '../../../../../app/redux-fetch/apiAssets'
import { useGetTransportModeQuery } from '../../../../../app/redux-fetch/apiCoreQuery'
import AddButton from '../../../../../components/Buttons/AddButton'
import ConditionalRender from '../../../../../components/ConditionalRender/ConditionalRender'
import MRTDataGridv2 from '../../../../../components/MRTDataGrid/MRTDataGridv2'
import YesNoTypography from '../../../../../components/YesNoTypograpphy/YesNoTypography'
import { fetchLoggedInUserSelector } from '../../../../selectors'
import { ILoggedInUser } from '../../../../types'
import { messages } from '../../messages'
import { AssetClass } from '../../types'
import AssetClassForm from './AssetClassForm'

const AssetClassTable = () => {

    const [createOpen, setCreateOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)

    const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

    const { data: assetClasses = [], isLoading: assetClassesLoading } = useGetAssetClassByTenantIdQuery({ tenantId })
    const [deleteAssetClass] = useDeleteAssetClassMutation()
    const [selectedAssetClassId, setSelectedAssetClassId] = useState(-1)
    const { enqueueSuccess, enqueueFailure } = useNotificationStack()

    const { formatMessage } = useIntl()
    const { data: transportModes = [] } = useGetTransportModeQuery()

    const columns = useMemo<MRTColumnDef<AssetClass>[]>(() => {
        return [
            {
                accessorKey: 'name',
                Cell: ({ cell }: any) => {
                    return (
                        <Typography sx={{ fontWeight: 700 }}>{cell.getValue()}</Typography>)
                },
                header: formatMessage(messages.assetClassName),
            },
            {
                id: 'categoryName',
                accessorFn: (row) => {
                    return row.assetCategory?.name ?? ''
                },
                header: formatMessage(messages.category),
            },
            {
                id: 'transportModeId',
                accessorFn: (row) => {
                    return transportModes.find((tm: any) => tm.id === row.assetCategory?.transportModeId)?.name
                },
                Cell: ({ cell }: any) => {
                    return (<>{cell.getValue()}</>)
                },
                header: formatMessage(messages.transportMode),
            },
            {
                accessorFn: (row) => {
                    return row.enabled ? 'true' : 'false'
                },
                filterVariant: 'checkbox',
                header: formatMessage(messages.enabled),
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                Cell: ({ cell }: any) => {
                    return <YesNoTypography isYes={cell.getValue() === 'true'} />
                }
            },
            {
                accessorFn: (row) => {
                    return row.inUse ? 'true' : 'false'
                },
                filterVariant: 'checkbox',
                header: formatMessage(messages.inUse),
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                Cell: ({ cell }: any) => {
                    return <YesNoTypography isYes={cell.getValue() === 'true'} />
                }
            }
        ]
    }, [assetClasses, transportModes])

    return (<>
        <ConditionalRender condition={createOpen} >
            <AssetClassForm isOpen={createOpen} handleClose={() => {
                setCreateOpen((pv) => {
                    return !pv
                })
            }} assetClass={{
                enabled: true,
                tenantId,
            }} />
        </ConditionalRender>
        <ConditionalRender condition={editOpen} >
            <AssetClassForm isOpen={editOpen} handleClose={() => {
                setEditOpen((pv) => {
                    return !pv
                })
            }}
                assetClass={assetClasses.find((at) => at.id === selectedAssetClassId)}
            />
        </ConditionalRender>
        <MRTDataGridv2
            initialState={{
                pagination: { pageSize: 100, pageIndex: 0 },
                sorting: [
                    {
                        id: 'transportModeId',
                        desc: false,
                    },
                    {
                        id: 'categoryName',
                        desc: false
                    },
                    {
                        id: 'name',
                        desc: false,
                    }
                ]
            }
            }
            state={{ isLoading: assetClassesLoading }}
            rightHeadingComponent={
                <AddButton
                    text={formatMessage(messages.newAssetClass)}
                    onClick={() => setCreateOpen(true)}
                />
            }
            columns={columns}
            data={assetClasses}
            getRowId={(row) => row.id as any}
            enableEditing
            enableRowActions
            positionActionsColumn='last'
            renderRowActions={({ row, table }: any) => {
                return (
                    <>
                        <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                            <Link
                                underline='none'
                                color='secondary'
                                onClick={() => {
                                    setSelectedAssetClassId(row.id)
                                    setEditOpen(true)
                                }}
                                sx={{
                                    fontWeight: 700,
                                    cursor: 'pointer',
                                }}
                            >
                                {formatMessage(messages.editActionLbl)}
                            </Link>
                            <ConditionalRender condition={row.original.inUse == false}>
                                <CancelIcon
                                    color='secondary'
                                    sx={{
                                        cursor: 'pointer',
                                        pl: '4px',
                                    }}
                                    onClick={() => {
                                        deleteAssetClass({ tenantId, assetClassId: row.id })
                                            .then(() => {
                                                enqueueSuccess(messages.assetClassDeleted)
                                            })
                                            .catch(() => {
                                                enqueueFailure(messages.assetClassDeleted)
                                            })
                                    }}
                                />
                            </ConditionalRender>
                        </Box>
                    </>
                )
            }}
        />
    </>
    )

}

export default AssetClassTable
