import { Box, FormControlLabel, Switch } from '@mui/material'
import TextField from '@mui/material/TextField'
import isEmpty from 'lodash/isEmpty'
import { useIntl } from 'react-intl'
import {
  useAssetCategoriesByTransportModeIdQuery,
  useGetAssetClassesByCategoryQuery,
  useGetAssetTypesByCategoryQuery,
} from '../../../app/redux-fetch/apiAssets'
import { useGetTransportModeQuery } from '../../../app/redux-fetch/apiCoreQuery'
import { ConditionalRender } from '../../../components/ConditionalRender/ConditionalRender'
import FormDropdown, { DropdownItem } from '../../../components/FormDropDown/FormDropdown'
import RequiredAsterisk from '../../../components/RequiredAsterisk/RequiredAsterisk'
import {
  SectionLabelTypography,
  SectionTitleTypography,
} from '../../../components/Typographies/styles'
import { useAssetManagementFormContext } from '../hooks/useAssetManagementFormContext'
import { messages } from '../messages'

interface AssetAttributeGroupProps {
  disabled?: boolean
}

export function AssetSettingsGroup({ disabled }: AssetAttributeGroupProps) {
  const { formatMessage } = useIntl()
  const { values, setFieldValue, errors, touched } = useAssetManagementFormContext()
  const {
    assetCategoryId = 0,
    assetClassId = 0,
    assetTypeId = 0,
    transportModeId = 0,
    tenantId = 0,
    detail,
    identification,
  } = values

  const { data: transportModesData = [] } = useGetTransportModeQuery()
  const { data: assetCategoriesData = [], isLoading: isLoadingAssetCategories } =
    useAssetCategoriesByTransportModeIdQuery({ tenantId, transportModeId })

  const { data: assetTypesData = [], isLoading: isLoadingAssetTypes } =
    useGetAssetTypesByCategoryQuery({ tenantId, assetCategoryId })

  const { data: assetClassesData = [], isLoading: isLoadingAssetClasses } =
    useGetAssetClassesByCategoryQuery({ tenantId, assetCategoryId })

  const isDisabled =
    disabled || isLoadingAssetCategories || isLoadingAssetTypes || isLoadingAssetClasses

  return (
    <Box sx={{ ml: '32px' }}>
      <Box
        sx={{ mb: '16px', display: 'flex', height: '32px', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <SectionTitleTypography>{formatMessage(messages.assetSettings)}</SectionTitleTypography>
        <FormControlLabel
          sx={{ justifyContent: 'start', minHeight: '56px' }}
          value={detail?.isOwned}
          defaultChecked={detail?.isOwned}
          labelPlacement='start'
          control={
            <Switch
              color='secondary'
              checked={detail?.isOwned}
              value={detail?.isOwned}
              defaultChecked={detail?.isOwned}
              onChange={({ target }) => {
                setFieldValue('detail.isOwned', target.checked)
                if (!target.checked) setFieldValue('detail.ownerName', '')
              }}
              disabled={disabled}
            />
          }
          label={formatMessage(messages.assetsDetailsIsOwned)}
          disabled={disabled}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.transportMode)}
          <RequiredAsterisk />
        </SectionLabelTypography>
        <FormDropdown
          id='transportModeId'
          items={transportModesData as DropdownItem[]}
          onChange={(_e: any, name: string, value: number) => setFieldValue(name, value)}
          value={transportModeId}
          defaultValue={transportModeId}
          disabled={isDisabled}
          error={touched.transportModeId && !!errors.transportModeId}
          errorText={touched.transportModeId && errors.transportModeId}
          required
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          Asset Category
          <RequiredAsterisk />
        </SectionLabelTypography>
        <FormDropdown
          id='assetCategoryId'
          items={assetCategoriesData as DropdownItem[]}
          onChange={(_e: any, name: string, value: number) => setFieldValue(name, value)}
          value={assetCategoryId}
          defaultValue={assetCategoryId}
          disabled={isDisabled || isEmpty(assetCategoriesData)}
          error={touched.assetCategoryId && !!errors.assetCategoryId}
          errorText={touched.assetCategoryId && errors.assetCategoryId}
          required
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.assetsTypes)}
          <RequiredAsterisk />
        </SectionLabelTypography>
        <FormDropdown
          id='assetTypeId'
          items={assetTypesData as DropdownItem[]}
          onChange={(_e: any, name: string, value: number) => setFieldValue(name, value)}
          value={assetTypeId}
          disabled={isDisabled || isEmpty(assetTypesData)}
          defaultValue={assetTypeId}
          error={touched.assetTypeId && !!errors.assetTypeId}
          errorText={touched.assetTypeId && errors.assetTypeId}
          required
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.assetsClasses)}
          <RequiredAsterisk />
        </SectionLabelTypography>
        <FormDropdown
          id='assetClassId'
          disabled={isDisabled || isEmpty(assetClassesData)}
          items={assetClassesData as DropdownItem[]}
          onChange={(_e: any, name: string, value: number) => setFieldValue(name, value)}
          error={touched.assetClassId && !!errors.assetClassId}
          errorText={touched.assetClassId && errors.assetClassId}
          value={assetClassId}
          defaultValue={assetClassId}
          required
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.assetsDetailsIdentification)}
          <RequiredAsterisk />
        </SectionLabelTypography>
        <TextField
          id='identification'
          name='identification'
          onChange={({ target }) => setFieldValue('identification', target.value)}
          disabled={disabled}
          value={identification}
          defaultValue={identification}
          InputLabelProps={{ shrink: true }}
          error={touched.identification && !!errors.identification}
          helperText={Boolean(touched.identification) && (errors.identification as string)}
          fullWidth
        />
      </Box>
      <ConditionalRender condition={detail && !detail?.isOwned}>
        <Box sx={{ mb: '8px' }}>
          <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
            {formatMessage(messages.ownershipName)}
          </SectionLabelTypography>
          <TextField
            id='detail.ownerName'
            name='detail.ownerName'
            onChange={({ target }) => setFieldValue('detail.ownerName', target.value)}
            disabled={disabled}
            value={detail?.ownerName}
            defaultValue={detail?.ownerName}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Box>
      </ConditionalRender>
    </Box>
  )
}
