import { AccordionDetails, Box, Button, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import { useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useGetAllLoadTemplatesQuery, useGetCarriersQuery } from '../../../../app/redux-fetch/apiShipment'
import { convertToLabelValue } from '../../../../app/utils'
import StyledAccordion from '../../../../components/Accordions/StyledAccordion'
import StyledAccordionSummary from '../../../../components/Accordions/StyledAccordionSummary'
import FormDatePickerInput from '../../../../components/Forms/FormDatePickerInput'
import FormInput from '../../../../components/Forms/FormInput'
import FormSelect from '../../../../components/Forms/FormSelect'
import { AccordionHeader } from '../../../../components/Headers/styles'
import commonMessages from '../../../../components/Messages/commonMessages'
import { LoadType, LocationType } from '../../../../types/common'
import { useLocationModal } from '../../hooks/useLocationModal'
import messages from '../messages'
import { IShipmentFormInput } from '../schema'
import { LoadTemplateDto } from '../types'

interface EmptyContainerRequestProps {
    consignmentIndex: number
    requestIndex: number
    onRemove?: () => void
}

const EmptyContainerRequest: React.FC<EmptyContainerRequestProps> = ({
    consignmentIndex,
    requestIndex,
    onRemove
}) => {
    const { setValue, watch, control } = useFormContext()
    const [expanded, setExpanded] = useState(true)
    const { formatMessage } = useIntl()

    const handleToggleAccordion = () => {
        setExpanded((prev) => !prev)
    }

    const basePath = `bookings.${consignmentIndex}.containerCollections.${requestIndex}`;

    const customerId = watch('customerId')
    const locationId = watch(`${basePath}.locationId`);
    const carrierOption = watch(`${basePath}.carrierOption`)
    const tenantId = watch('tenantId')

    const {
        locations,
        openModal,
        ModalComponent,
    } = useLocationModal({
        onSave: (id) => {
            setValue(`${basePath}.locationId`, id)
        },
        customerId,
        locationTypeId: LocationType.Terminal,
        oldLocations: locationId ? [locationId] : [],
    })

    const { data: packagingTemplates = [] } = useGetAllLoadTemplatesQuery()

    const { data: carries = [] } = useGetCarriersQuery({ tenantId }, { skip: !tenantId })

    const palletPackagingTemplatesOptions = useMemo(
        () =>
            convertToLabelValue(
                packagingTemplates
                    .filter((x: LoadTemplateDto) => x.loadTypeId === LoadType.container)
                    .map((x: LoadTemplateDto) => ({ name: x.name, id: x.id }))
            ),
        [packagingTemplates]
    )

    const terminalOptions = useMemo(
        () =>
            convertToLabelValue(
                locations.map((location) => ({
                    name: location.name,
                    id: location.id,
                }))
            ),
        [locations]
    )

    const carrierOptions = useMemo(
        () =>
            convertToLabelValue(
                carries.filter(c => c.transportModeId === 2).map((carrier) => ({
                    name: carrier.name,
                    id: carrier.id,
                }))
            ),
        [carries]
    )
    return (
        <>
            {ModalComponent}
            <StyledAccordion
                defaultExpanded
                sx={{ marginTop: '10px' }}
                onChange={handleToggleAccordion}
            >
                <StyledAccordionSummary
                    index={`emptyContainerRequest-content-${consignmentIndex}-${requestIndex}`}
                    expanded={expanded}
                >
                    <Grid container alignItems="center">
                        <Grid item xs={6} md={8}>
                            <AccordionHeader>
                                {`${formatMessage(messages.emptyContainerRequest)} ${requestIndex + 1}`}
                            </AccordionHeader>
                        </Grid>
                        <Grid item xs={6} md={4} sx={{ textAlign: 'right' }}>
                            {onRemove && (
                                <Button
                                    size='small'
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        onRemove();
                                    }}
                                    sx={{ textTransform: 'none', p: 0, mb: '6px', color: 'red' }}
                                >
                                    {formatMessage(commonMessages.remove)}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </StyledAccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={2}>
                            <FormSelect
                                name={`${basePath}.loadTemplateId`}
                                label={formatMessage(messages.containerType)}
                                options={palletPackagingTemplatesOptions}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <FormInput
                                name={`${basePath}.quantity`}
                                label={formatMessage(messages.quantity)}
                                type='number'
                                required
                                numberFormatProps={{
                                    allowOnlyIntegers: true,
                                    minValue: 1,
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <Controller
                                    name={`${basePath}.carrierOption` as keyof IShipmentFormInput}
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup
                                            {...field}
                                            row
                                            sx={{ margin: 'auto' }}
                                            onChange={(e) => {
                                                field.onChange(e)
                                                const value = e.target.value

                                                switch (value) {
                                                    case 'noPreferredLine':
                                                    case 'preferredLine':
                                                    case 'preBooked':
                                                        setValue(`${basePath}.shippingLineId`, undefined)
                                                        setValue(`${basePath}.locationId`, undefined)
                                                        setValue(`${basePath}.croNumber`, undefined)
                                                        setValue(`${basePath}.validFrom`, undefined)
                                                        setValue(`${basePath}.validTo`, undefined)
                                                        break;
                                                }

                                            }}
                                        >
                                            <FormControlLabel
                                                value='noPreferredLine'
                                                control={<Radio />}
                                                label={formatMessage(messages.noPreferredShippingLine)}
                                            />
                                            <FormControlLabel
                                                value='preferredLine'
                                                control={<Radio />}
                                                label={formatMessage(messages.preferredShippingLine)}
                                            />
                                            <FormControlLabel
                                                value='preBooked'
                                                control={<Radio />}
                                                label={formatMessage(messages.preBookedContainerReleaseOrder)}
                                            />
                                        </RadioGroup>
                                    )}
                                />
                            </Box>
                        </Grid>
                        {(carrierOption === 'preferredLine' || carrierOption === 'preBooked') && (
                            <>
                                <Grid item xs={12} md={2}>
                                    <FormSelect
                                        name={`${basePath}.carrierId`}
                                        label={formatMessage(messages.shippingLine)}
                                        options={carrierOptions}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                            </>
                        )}
                        {(carrierOption === 'preBooked') && (
                            <>
                                <Grid item xs={12} md={2}>
                                    <FormInput
                                        name={`${basePath}.croNumber`}
                                        label={formatMessage(messages.croNumber)}
                                        placeholder={formatMessage(messages.croNumber)}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <FormDatePickerInput
                                        name={`${basePath}.croValidFrom`}
                                        label={formatMessage(messages.validFrom)}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <FormDatePickerInput
                                        name={`${basePath}.croValidTo`}
                                        required
                                        label={formatMessage(messages.validTo)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormSelect
                                        name={`${basePath}.locationId`}
                                        label={formatMessage(messages.emptyCollectionTerminal)}
                                        options={terminalOptions}
                                        required
                                        fullWidth
                                        actionLabel={
                                            <Button
                                                size='small'
                                                onClick={() => {
                                                    openModal()
                                                }}
                                                sx={{ textTransform: 'none', p: 0, mb: '6px' }}
                                            >
                                                {formatMessage(commonMessages.addNew)}
                                            </Button>
                                        }
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </AccordionDetails>
            </StyledAccordion>
        </>
    )
}

export default EmptyContainerRequest
