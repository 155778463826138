import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useGetLocationsQuery } from '../../../app/redux-fetch/apiCoreQuery';
import { useGetAllLoadTypesQuery, useGetCurrenciesByCustomerIdQuery } from '../../../app/redux-fetch/apiShipment';
import BoxWhiteContainer from '../../../components/Containers/BoxWhiteContainer';
import InfoDisplayItem from '../../../components/Typographies/InfoDisplayItem';
import { LabelItem } from '../../../components/Typographies/LabelItem';
import messages from './messages';

interface ConsignmentInfoProps {
    consignmentIndex: number;
}

const ConsignmentInfo: React.FC<ConsignmentInfoProps> = ({ consignmentIndex }) => {
    const { formatMessage } = useIntl();
    const { control } = useFormContext();

    const tenantId = useWatch({ control, name: 'tenantId' });
    const customerId = useWatch({ control, name: 'customerId' });
    const shipperId = useWatch({ control, name: `bookings.${consignmentIndex}.shipperId` });
    const currencyId = useWatch({ control, name: `bookings.${consignmentIndex}.currencyId` });
    const consignmentReferenceNumber = useWatch({ control, name: `bookings.${consignmentIndex}.referenceNumber` });
    const consignmentTrackingNumber = useWatch({ control, name: `bookings.${consignmentIndex}.trackingNumber` });
    const description = useWatch({ control, name: `bookings.${consignmentIndex}.description` });
    const shipFrom = useWatch({ control, name: `bookings.${consignmentIndex}.shipFrom.locationId` });
    const deliverTo = useWatch({ control, name: `bookings.${consignmentIndex}.deliverTo.locationId` });
    const loadingDate = dayjs(useWatch({ control, name: `bookings.${consignmentIndex}.shipFrom.loadingDate` })).format('DD/MM/YYYY');
    const deliveryDate = dayjs(useWatch({ control, name: `bookings.${consignmentIndex}.deliverTo.deliveryDate` })).format('DD/MM/YYYY');
    const loadTypeId = useWatch({ control, name: `bookings.${consignmentIndex}.loadTypeId` });

    const { data: currencies = [] } = useGetCurrenciesByCustomerIdQuery({
        customerId: customerId ?? 0,
        hideDisabled: true,
    });

    const { data: locations = [] } = useGetLocationsQuery({ tenantId, customerId });

    const getCurrencyLabel = useCallback(() => {
        const currency = currencies.find((currency) => currency.id === currencyId);
        if (!currency) return 'N/A';
        return `${currency.iso3} - ${currency.localisedSymbol}`;
    }, [currencies, currencyId]);

    const getLocationName = (locationId: number) => {
        return locations.find((location: any) => location.id === locationId)?.name;
    };

    const { data: packagingTypes = [] } = useGetAllLoadTypesQuery()

    const getLoadTypeLabel = () => {
        const loadTypeData = packagingTypes.find((type: any) => type.id === loadTypeId);
        if (!loadTypeData) return 'N/A';
        return loadTypeData.name;
    };

    return (
        <>
            <BoxWhiteContainer sx={{ mb: '32px' }}>
                <Grid container>
                    <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <LabelItem>{formatMessage(messages.consignmentInformation)}</LabelItem>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <InfoDisplayItem
                            label={formatMessage(messages.shipper)}
                            value={getLocationName(shipperId) ?? 'N/A'}
                            isSmallScreen={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <InfoDisplayItem
                            label={formatMessage(messages.currency)}
                            value={getCurrencyLabel() ?? 'N/A'}
                            isSmallScreen={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <InfoDisplayItem
                            label={formatMessage(messages.customerReferenceNumber)}
                            value={consignmentReferenceNumber ?? 'N/A'}
                            isSmallScreen={false}
                        />
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <InfoDisplayItem
                            label={formatMessage(messages.loadType)}
                            value={getLoadTypeLabel()}
                            isSmallScreen={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <InfoDisplayItem
                            label={formatMessage(messages.consignmentTrackingNumber)}
                            value={consignmentTrackingNumber ?? 'N/A'}
                            isSmallScreen={false}
                        />
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={12} md={9} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <InfoDisplayItem
                            label={formatMessage(messages.description)}
                            value={description ?? 'N/A'}
                            isSmallScreen={false}
                        />
                    </Grid>
                </Grid>
            </BoxWhiteContainer>
            <BoxWhiteContainer sx={{ mb: '32px' }}>
                <Grid container>
                    <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <LabelItem>{formatMessage(messages.shipFromDeliverTo)}</LabelItem>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <InfoDisplayItem
                            label={formatMessage(messages.shipFrom)}
                            value={getLocationName(shipFrom) ?? 'N/A'}
                            isSmallScreen={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <InfoDisplayItem
                            label={formatMessage(messages.deliverTo)}
                            value={getLocationName(deliverTo) ?? 'N/A'}
                            isSmallScreen={false}
                        />
                    </Grid>
                    <Grid item md={3}></Grid>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <InfoDisplayItem
                            label={formatMessage(messages.requestedPickupDate)}
                            value={loadingDate ?? 'N/A'}
                            isSmallScreen={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <InfoDisplayItem
                            label={formatMessage(messages.requestedDeliveryDate)}
                            value={deliveryDate ?? 'N/A'}
                            isSmallScreen={false}
                        />
                    </Grid>
                </Grid>
            </BoxWhiteContainer>
        </>
    );
};

export default ConsignmentInfo;
