import { Box } from '@mui/material'
import { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useGetAllTransportModesQuery } from '../../../app/redux-fetch/apiCoreQuery'
import FormDropdown from '../../../components/FormDropDown/FormDropdown'
import RequiredAsterisk from '../../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography, SectionTitleTypography } from '../../../components/Typographies/styles'
import { useLocationFormContext } from '../hooks/useLocationFormContext'
import messages from '../messages'

type DefaultValuesProps = {
    isDisabled?: boolean
}

export const DefaultValues = ({ isDisabled = false }: DefaultValuesProps): ReactElement<any, any> => {
    const { data: transportModes = [] } = useGetAllTransportModesQuery()
    const { values, setFieldValue } = useLocationFormContext()
    const { formatMessage } = useIntl()
    const isNew = !values.id

    return (
        <Box sx={{ mb: '16px', width: '75%' }}>
            <Box sx={{ mb: '16px' }}>
                <SectionTitleTypography >{formatMessage(messages.defaultValues)}</SectionTitleTypography>
            </Box>
            <Box sx={{ mb: '16px' }}>
                <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.transportMode)}<RequiredAsterisk /></SectionLabelTypography>
                <FormDropdown
                    disabled={isDisabled || (values.systemDefined && !isNew)}
                    id='defaultTransportModeId'
                    onChange={(e, name, newValue) => setFieldValue(name, newValue)}
                    value={values.defaultTransportModeId}
                    items={transportModes}
                />
            </Box>
        </Box>
    )
}

export default DefaultValues