import { AccordionDetails } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useGetLocationsQuery } from '../../../app/redux-fetch/apiCoreQuery';
import StyledAccordion from '../../../components/Accordions/StyledAccordion';
import StyledAccordionSummary from '../../../components/Accordions/StyledAccordionSummary';
import CustomAlert from '../../../components/Alerts/CustomAlert';
import { AccordionHeader } from '../../../components/Headers/styles';
import { LocationType } from '../../../types/common';
import ConsignmentInformationForm from './ConsignmentInformationForm';
import DeliverToForm from './DeliverToForm';
import messages from './messages';
import ShipFromForm from './ShipFromForm';

interface ShipFromDeliverToContainerProps {
    consignmentIndex: number;
}


const ShipFromDeliverToContainer: React.FC<ShipFromDeliverToContainerProps> = ({
    consignmentIndex,
}: ShipFromDeliverToContainerProps) => {
    const [expanded, setExpanded] = useState(true);
    const { formatMessage } = useIntl();

    const handleToggleAccordion = () => {
        setExpanded((prev) => !prev);
    };

    const { getValues } = useFormContext()

    const tenantId = getValues('tenantId')
    const customerId = getValues('customerId')

    const { data: locations = [], isSuccess: shipperLoaded } = useGetLocationsQuery({ tenantId, customerId, locationTypeId: LocationType.Shipper })
    const isShipperAvailable = locations.length > 0

    return (
        <>
            {(!isShipperAvailable && shipperLoaded) ? (<>
                <CustomAlert
                    severity='error'
                    title='Error'
                    message={formatMessage(messages.noShipperAvailable)}
                />
                <ConsignmentInformationForm index={consignmentIndex} />
            </>) : (
                <>
                    <ConsignmentInformationForm index={consignmentIndex} />
                    <StyledAccordion title={formatMessage(messages.shipFrom)} expanded={expanded} onChange={handleToggleAccordion}
                    >
                        <StyledAccordionSummary index={`ship-from-${consignmentIndex}`}>
                            <AccordionHeader sx={{ pr: '16px' }}>
                                {formatMessage(messages.shipFromDeliverTo)}
                            </AccordionHeader>
                        </StyledAccordionSummary>
                        <AccordionDetails>
                            <ShipFromForm index={consignmentIndex} />
                            <DeliverToForm index={consignmentIndex} />
                        </AccordionDetails>
                    </StyledAccordion >
                </>
            )}
        </>
    );
};

export default ShipFromDeliverToContainer;