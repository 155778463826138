import { zodResolver } from '@hookform/resolvers/zod'
import {
    Box,
    Button,
    FormControlLabel,
    Switch,
    TextField,
} from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useNotificationStack } from '../../app/hooks/useNotificationStack'
import { useCreateServiceMutation, useEditServiceMutation } from '../../app/redux-fetch/apiShipment'
import RequiredAsterisk from '../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography } from '../../components/Typographies/styles'
import LoadTypesMultiSelect from './LoadTypesMultiSelect'
import { messages } from './messages'
import { ServiceFormData, serviceSchema } from './schema'
import { IService } from './types'

type ServiceFormProps = {
    service: IService
    handleClose: () => void
}

const ServiceForm = ({ service, handleClose }: ServiceFormProps) => {

    const { formatMessage } = useIntl()
    const isNew = !service?.id

    const [addService] = useCreateServiceMutation()
    const [updateService] = useEditServiceMutation()
    const { enqueueSuccess, enqueueFailure } = useNotificationStack()

    const methods = useForm<ServiceFormData>({
        resolver: zodResolver(serviceSchema),
        defaultValues: {
            ...service,
            loadTypes: service.loadTypes || []
        },
    });

    const { register, handleSubmit, formState: { errors } } = methods;

    const onSubmit = async (data: ServiceFormData) => {
        if (isNew) {
            await addService({ tenantId: data.tenantId, body: data })
                .then(() => {
                    enqueueSuccess(messages.serviceCreated)
                    handleClose()
                })
                .catch(() => enqueueFailure(messages.serviceNotAdded))
        } else {
            await updateService({ tenantId: data.tenantId, body: data })
                .then(() => {
                    enqueueSuccess(messages.serviceUpdated)
                    handleClose()
                })
                .catch(() => enqueueFailure(messages.serviceNotUpdated))
        }
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ mb: '16px' }}>
                    <Box sx={{ mb: '16px' }}>
                        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                            {formatMessage(messages.name)}
                            <RequiredAsterisk />
                        </SectionLabelTypography>
                        <TextField
                            fullWidth
                            {...register('name')}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />
                    </Box>

                    <Box sx={{ mb: '16px' }}>
                        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                            {formatMessage(messages.code)}
                            <RequiredAsterisk />
                        </SectionLabelTypography>
                        <TextField
                            fullWidth
                            {...register('code')}
                            error={!!errors.code}
                            helperText={errors.code?.message}
                        />
                    </Box>

                    <Box sx={{ mb: '16px' }}>
                        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                            {formatMessage(messages.description)}
                        </SectionLabelTypography>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            {...register('description')}
                            error={!!errors.description}
                            helperText={errors.description?.message}
                        />
                    </Box>

                    <Box sx={{ mb: '16px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    {...register('customerSelectable')}
                                    defaultChecked={service.customerSelectable}
                                    color="secondary"
                                />
                            }
                            label={formatMessage(messages.customerSelectable)}
                        />

                        <FormControlLabel
                            control={
                                <Switch
                                    {...register('enabled')}
                                    defaultChecked={service.enabled}
                                    color="secondary"
                                />
                            }
                            label={formatMessage(messages.enabled)}
                        />
                    </Box>

                    <Box sx={{ mb: '16px' }}>
                        <LoadTypesMultiSelect />
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button color='secondary' variant='outlined' onClick={handleClose}>
                        {formatMessage(messages.cancelBtn)}
                    </Button>
                    <Button
                        color='secondary'
                        variant='contained'
                        type='submit'
                    >
                        {isNew ? formatMessage(messages.newServiceBtn) : formatMessage(messages.editBtn)}
                    </Button>
                </Box>
            </form>
        </FormProvider>
    )
}

export default ServiceForm
