import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined'
import { AccordionDetails, Button, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import StyledAccordion from '../../../../components/Accordions/StyledAccordion'
import StyledAccordionSummary from '../../../../components/Accordions/StyledAccordionSummary'
import { AccordionHeader } from '../../../../components/Headers/styles'
import FlowSection from '../FlowSection'
import messages from '../messages'
import EmptyContainerRequest from './EmptyContainerRequest'

interface EmptyContainerCollectionProps {
  consignmentIndex: number
}

const EmptyContainerCollection: React.FC<EmptyContainerCollectionProps> = ({
  consignmentIndex,
}) => {
  const { watch, control } = useFormContext()
  const [expanded, setExpanded] = useState(true)
  const { formatMessage } = useIntl()

  const { fields, append, remove } = useFieldArray({
    control,
    name: `bookings.${consignmentIndex}.containerCollections`
  });

  const emptyContainerCollection = watch(
    `bookings.${consignmentIndex}.shipFrom.emptyContainerCollection`
  )

  useEffect(() => {
    if (emptyContainerCollection && fields.length === 0) {
      append({
        carrierOption: 'noPreferredLine'
      });
    }
  }, [emptyContainerCollection, fields.length, append]);

  const handleToggleAccordion = () => {
    setExpanded((prev) => !prev)
  }

  const addContainerCollection = () => {
    append({});
  };

  if (!emptyContainerCollection) {
    return null;
  }

  return (
    <>
      <FlowSection>
        <StyledAccordion
          defaultExpanded
          sx={{ marginTop: '10px' }}
          onChange={handleToggleAccordion}
        >
          <StyledAccordionSummary
            index={`emptyContainerCollection-content-${consignmentIndex}`}
            expanded={expanded}
            title={formatMessage(messages.emptyContainerCollection)}
          >
            <AccordionHeader sx={{ flexGrow: 1 }}>
              {formatMessage(messages.emptyContainerCollection)}
            </AccordionHeader>
            {expanded && (<Button
              startIcon={<AddCircleOutlinedIcon />}
              size='small'
              onClick={(event) => {
                event.stopPropagation();
                addContainerCollection();
              }}
              sx={{ textTransform: 'none', p: 0, mb: '6px' }}
            >
              {formatMessage(messages.addRequest)}
            </Button>)}
          </StyledAccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {fields.map((field, index) => (
                <Grid item xs={12} key={field.id}>
                  <EmptyContainerRequest
                    consignmentIndex={consignmentIndex}
                    requestIndex={index}
                    onRemove={fields.length > 1 ? () => remove(index) : undefined}
                  />
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
      </FlowSection>
    </>
  )
}

export default EmptyContainerCollection
