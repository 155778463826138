enum RoutesEnum {
  ROOT = '/',
  DASHBOARD = '/dashboard',
  SETTINGS = '/settings',
  SHIPMENTS = '/shipping/shipments',
  JOURNEY = '/shipping/journeys',
  VIEW_SHIPMENT = '/shipping/shipments/:id',
  EDIT_SHIPMENT = '/shipping/shipments/:id/edit',
  ASSET_HIRES = '/assetHire',
  VIEW_ASSET_HIRE = '/assetHire/:id',
  EDIT_ASSET_HIRE = '/assetHire/:id/edit',
  CREATE_ASSET_HIRE = '/assetHire/create',
  LOCATIONS = '/datasets/locations',
  LOCATIONS_FUEL_STATIONS = '/datasets/locations/fuel-stations',
  LOCATIONS_TERMINALS = '/datasets/locations/terminals',
  LOCATIONS_AIRPORTS = '/datasets/locations/airports',
  LOCATIONS_RAILWAY_STATIONS = '/datasets/locations/railway-stations',
  VIEW_LOCATION = '/datasets/locations/:id',
  EDIT_LOCATION = '/datasets/locations/:id/edit',
  CREATE_LOCATION = '/datasets/locations/create',
  CREATE_ASSET = '/datasets/assets/create',
  UNASSIGNED_ROUTES = '/shipping/transport/routes/unassigned',
  ASSETS = '/datasets/assets',
  WORKFORCE = '/datasets/workforce',
  WORKFORCE_TYPE = '/datasets/workforceType',
  SHIPMENT_STEPPER = '/shipping/shipments/create',
  CUSTOMERS = '/datasets/customers',
  SERVICES = '/datasets/services',
  CREATE_TRANSPORT = '/shipping/journeys/:journeyId/route/:id/transport/create',
  COMING_SOON = '/comingSoon',
  NO_MATCH = '*',
  BRANDING = '/settings/branding',
  ASSET_SETTINGS = '/settings/assetSettings',
  USER_MANAGEMENT = '/settings/userManagement',
  ASSET_MANAGEMENT = '/settings/assetManagement',
  INTEGRATIONS = '/settings/integrations',
  SHIPPING = '/settings/shipping',
  ROLES_PERMISSIONS = '/settings/rolesPermissions',
  CURRENCY_SETTINGS = '/settings/currencies',
  CONTACTS_SETTINGS = '/settings/contacts',
  PRODUCT_GROUPS = '/settings/productGroups',
  PRODUCTS = '/datasets/products',
  TRANSPORT_JOB_LISTS = '/shipping/transportJobs',
  CARRIERS = '/datasets/carriers',
}

export default RoutesEnum
