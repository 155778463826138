import { Grid } from '@mui/material'
import { useState } from 'react'
import { useAppSelector } from '../../app/hooks'
import { useGetCustomersQuery } from '../../app/redux-fetch/apiQuery'
import SpinnerBlock from '../../components/Spinner/SpinnerBlock'
import TabWrapper from '../../components/TabWrapper/TabWrapper'
import { fetchLoggedInUserSelector } from '../selectors'
import { ILoggedInUser } from '../types'
import ProductCategoryTab from './ProductCategoryTab/ProductCategoryTab'
import ProductTab from './ProductTab/ProductTab'
import { ProductTabContext } from './ProductTabContext'

const ProductTabs = () => {
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const [customerId, setCustomerId] = useState<number>(loggedInUser.customerId ?? -1)
  const { data, isSuccess: isCustomerLoaded } = useGetCustomersQuery(loggedInUser.tenantId ?? -1)


  if (!isCustomerLoaded) return <SpinnerBlock />

  const tabs = [
    {
      label: 'Products',
      content: (
        <Grid item xs={12}>
          <ProductTab />
        </Grid>
      ),
    },
    {
      label: 'Product Categories',
      content: (
        <Grid item xs={12}>
          <ProductCategoryTab />
        </Grid>
      ),
    },
  ]

  return (
    <ProductTabContext.Provider value={{ customerId, setCustomerId }}>
      <TabWrapper tabs={tabs} ariaLabel="Product management tabs" />
    </ProductTabContext.Provider>
  )
}

export default ProductTabs
