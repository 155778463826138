import AddIcon from '@mui/icons-material/Add'

import {
  AccordionDetails,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useGetContactByEntityIdQuery } from '../../../app/redux-fetch/apiCoreQuery'
import StyledAccordion from '../../../components/Accordions/StyledAccordion'
import StyledAccordionSummary from '../../../components/Accordions/StyledAccordionSummary'
import ToggleContainer from '../../../components/Containers/ToggleContainer'
import FormDatePickerInput from '../../../components/Forms/FormDatePickerInput'
import FormInput from '../../../components/Forms/FormInput'
import FormSelect from '../../../components/Forms/FormSelect'
import commonMessages from '../../../components/Messages/commonMessages'
import InfoDisplayItem from '../../../components/Typographies/InfoDisplayItem'
import { useLocationModal } from '../hooks/useLocationModal'
import FlowSection from './FlowSection'
import TransitDetailsForm from './TransitDetailsForm'
import TransportIcon from './TransportIcon'
import messages from './messages'
import { getAvailableLocations, getLocationAddress, getLocationRevision, mapContactsToOptions } from './utils'

interface WaypointFormProps {
  consignmentIndex: number
  waypointIndex: number
  removeWaypoint: (index: number) => void
  insertWaypoint: (index: number, value: any) => void
}

const WaypointForm: React.FC<WaypointFormProps> = ({
  consignmentIndex,
  waypointIndex,
  removeWaypoint,
  insertWaypoint,
}) => {
  const { control, setValue, watch } = useFormContext()
  const [expanded, setExpanded] = useState(true)
  const [isOpenTransitDetails, setIsOpenTransitDetails] = useState(true)
  const { formatMessage } = useIntl()

  const handleToggleAccordion = () => {
    setExpanded((prev) => !prev)
  }

  const handleToggleTransitDetail = () => {
    setIsOpenTransitDetails((prev) => !prev)
  }

  const customerId = watch('customerId')
  const oldLocations = watch(
    `bookings.${consignmentIndex}.waypoints.${waypointIndex}.locationId`
  )

  const {
    openModal,
    ModalComponent,
    locationOptions,
    locations,
  } = useLocationModal({
    onSave: (id) => {
      setValue(`bookings.${consignmentIndex}.waypoints.${waypointIndex}.locationId`, +id)
    },
    customerId,
    isSelectLocationType: true,
    filterFunction: (location) => {
      return (
        location.enabled &&
        location.locationTypeId !== 0 &&
        location.locationTypeId !== 1
      )
    },
    oldLocations: oldLocations ? [oldLocations] : [],
  })

  const consignments = watch('bookings') || []

  const currentConsignment = consignments[consignmentIndex]

  const usedLocationIds: number[] = []

  if (currentConsignment?.shipFrom?.locationId) {
    usedLocationIds.push(currentConsignment.shipFrom.locationId)
  }

  if (currentConsignment?.deliverTo?.locationId) {
    usedLocationIds.push(currentConsignment.deliverTo.locationId)
  }

  const getLocationName = (locationId: number) => {
    return locationOptions.find((location) => location.value === locationId)?.label
  }

  const locationId = watch(`bookings.${consignmentIndex}.waypoints.${waypointIndex}.locationId`)

  const loadingDate = dayjs(
    watch(`bookings.${consignmentIndex}.waypoints.${waypointIndex}.loadingDate`)
  ).format('DD/MM/YYYY')
  const clearingRequired = watch(
    `bookings.${consignmentIndex}.waypoints.${waypointIndex}.clearingRequired`
  )

  const { data: contacts } = useGetContactByEntityIdQuery(
    locationId,
    {
      skip: !locationId,
      refetchOnMountOrArgChange: true,
    }
  )

  const getContactOptions = useCallback(() => {
    return mapContactsToOptions(contacts)
  }, [contacts])

  const transportMode = watch(
    `bookings.${consignmentIndex}.waypoints.${waypointIndex}.transitDetails.modeOfTransit`
  )

  const selectedLocation = locations.find(
    (l: any) => l.id === locations.find((loc: any) => loc.id === locationId)?.id
  )

  useEffect(() => {
    if (transportMode > -1) return
    if (selectedLocation?.defaultTransportModeId) {
      setValue(
        `bookings.${consignmentIndex}.waypoints.${waypointIndex}.transitDetails.modeOfTransit`,
        selectedLocation?.defaultTransportModeId
      )
    } else {
      setValue(
        `bookings.${consignmentIndex}.waypoints.${waypointIndex}.transitDetails.modeOfTransit`,
        -1
      )
    }
  }, [selectedLocation, locationId, transportMode])

  return (
    <>
      {ModalComponent}
      <FlowSection>
        <StyledAccordion expanded={expanded} onChange={handleToggleAccordion} sx={{ pt: '0px' }}>
          <StyledAccordionSummary
            index={`waypoint-content-${consignmentIndex}-${waypointIndex}`}
            expanded={expanded}
          >
            <Grid container>
              <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Box display='flex' alignItems='center' width='100%'>
                  {formatMessage(messages.waypoint)} #{waypointIndex + 1}
                  <Typography
                    variant='body2'
                    sx={{ color: '#FF6161', cursor: 'pointer', ml: '16px' }}
                    onClick={() => removeWaypoint(waypointIndex)}
                  >
                    {formatMessage(messages.remove)}
                  </Typography>
                </Box>
              </Grid>
              {!expanded && (
                <>
                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <InfoDisplayItem
                      label={formatMessage(messages.waypoint)}
                      value={getLocationName(locationId) ?? 'N/A'}
                      isSmallScreen={false}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <InfoDisplayItem
                      label={formatMessage(messages.scheduledDate)}
                      value={loadingDate ?? 'N/A'}
                      isSmallScreen={false}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <InfoDisplayItem
                      label={formatMessage(messages.clearingRequired)}
                      value={clearingRequired ? 'YES' : 'NO'}
                      isSmallScreen={false}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </StyledAccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormSelect
                  name={`bookings.${consignmentIndex}.waypoints.${waypointIndex}.locationId`}
                  label={formatMessage(messages.location)}
                  options={getAvailableLocations(locationOptions, usedLocationIds)}
                  allOptions={locationOptions}
                  onChange={(e) => {
                    setValue(
                      `bookings.${consignmentIndex}.waypoints.${waypointIndex}.locationId`,
                      undefined
                    )
                    const revision = getLocationRevision(locations, +e.target.value)
                    setValue(`bookings.${consignmentIndex}.waypoints.${waypointIndex}.locationRevision`, revision)
                  }}
                  actionLabel={
                    <Button
                      size='small'
                      onClick={() => {
                        openModal()
                      }}
                      sx={{ textTransform: 'none', p: 0, mb: '6px' }}
                    >
                      {formatMessage(commonMessages.addNew)}
                    </Button>
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormDatePickerInput
                  name={`bookings.${consignmentIndex}.waypoints.${waypointIndex}.loadingDate`}
                  label={formatMessage(messages.scheduledDate)}
                  format='DD/MM/YYYY HH:mm'
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormSelect
                  name={`bookings.${consignmentIndex}.waypoints.${waypointIndex}.contactId`}
                  label='Contact'
                  options={getContactOptions()}
                  disableLabel={formatMessage(messages.noContactsAvailable)}
                  actionLabel={
                    <Button
                      size='small'
                      onClick={() => {
                        setValue(
                          `bookings.${consignmentIndex}.waypoints.${waypointIndex}.contactId`,
                          null
                        )
                      }}
                      sx={{ textTransform: 'none', p: 0, mb: '6px' }}
                    >
                      {formatMessage(messages.clearSelection)}
                    </Button>
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Typography variant='subtitle1' gutterBottom sx={{ color: '#636363' }}>
                    {formatMessage(messages.address)}
                  </Typography>
                </Box>
                <Typography variant='body2' sx={{ color: '#636363' }}>
                  {getLocationAddress(locations, locationId) || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  name={`bookings.${consignmentIndex}.waypoints.${waypointIndex}.specialInstructions`}
                  label={formatMessage(messages.specialInstructions)}
                  placeholder={formatMessage(commonMessages.pleaseEnter)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    height: '100%',
                  }}
                >
                  <Controller
                    name={`bookings.${consignmentIndex}.waypoints.${waypointIndex}.clearingRequired`}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Switch {...field} checked={Boolean(field.value)} />}
                        label={formatMessage(messages.clearingRequired)}
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
      </FlowSection>
      <FlowSection icon={<TransportIcon id={transportMode} />} showIcon={isOpenTransitDetails}>
        <Box sx={{ p: `${!isOpenTransitDetails ? '8px' : '32px'}` }}>
          <ToggleContainer
            title={formatMessage(messages.transitDetails)}
            isOpen={isOpenTransitDetails}
            handleToggle={handleToggleTransitDetail}
          >
            <TransitDetailsForm
              namePrefix={`bookings.${consignmentIndex}.waypoints.${waypointIndex}.transitDetails`}
            />
          </ToggleContainer>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            {!isOpenTransitDetails && (
              <Button onClick={handleToggleTransitDetail} sx={{ textTransform: 'none' }}>
                {formatMessage(messages.showTransitDetails)}
              </Button>
            )}
          </Box>
          <Box display='flex' justifyContent='flex-end'>
            <Button
              startIcon={<AddIcon />}
              onClick={() => {
                insertWaypoint(waypointIndex + 1, {
                  test: 1,
                  transitDetails: {
                    carrierOption: 'noPreference',
                  },
                })
              }}
              sx={{ textTransform: 'none', marginBottom: 2 }}
            >
              {formatMessage(messages.addWaypoint)}
            </Button>
          </Box>
        </Box>
      </FlowSection>
    </>
  )
}

export default WaypointForm
