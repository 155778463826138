import { Box, FormControlLabel, Link, Switch, Typography } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useMemo, useState, type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useLoggedInUser } from '../../app/hooks/useLoggedInUser'
import { useGetLocationsQuery } from '../../app/redux-fetch/apiCoreQuery'
import AddButton from '../../components/Buttons/AddButton'
import MRTDataGridv2 from '../../components/MRTDataGrid/MRTDataGridv2'
import { TableTitleTypography } from '../../components/Typographies/TableTitleTypography'
import { LocationType } from '../../types/common'
import SideModalWrapper from '../AssetsManagement/modals/SideModalWrapper'
import { ILocationByTenantCustomer } from '../Shipments/types'
import EditLocation from './EditLocation'
import LocationForm from './LocationForm'
import messages from './messages'

export interface ILocationsTableProps {
  columns: MRTColumnDef<ILocationByTenantCustomer>[]
  locationTypeId?: number
  title?: string
}

const LocationsTable = ({
  columns,
  title,
  locationTypeId,
}: ILocationsTableProps): ReactElement<any, any> => {
  const { formatMessage } = useIntl()
  const { tenantId, customerId, isTenantUser } = useLoggedInUser()
  const [showLocations, setShowLocations] = useState(locationTypeId === LocationType.Shipper)

  const {
    data: locationsData = [],
    isSuccess: isLocationsLoaded,
    refetch,
  } = useGetLocationsQuery({
    tenantId: tenantId ?? -1,
    customerId: customerId,
    locationTypeId: locationTypeId === LocationType.Standard ? undefined : locationTypeId,
    includeCustomerLocations: showLocations,
  })

  const [isViewModalOpen, setIsViewModalOpen] = useState(false)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedLocationId, setSelectedLocation] = useState<number>(-1)

  const isLoading = !isLocationsLoaded

  const locations = useMemo(() => {
    if (!isLocationsLoaded) return []

    if (locationTypeId === LocationType.Standard) {
      return locationsData?.filter(
        (l) =>
          l.locationTypeId !== LocationType.Shipper && l.locationTypeId !== LocationType.Customer
      )
    }

    return locationsData
  }, [locationsData, isLocationsLoaded, locationTypeId])

  const locationButtonTitle = useMemo(() => {
    switch (locationTypeId) {
      case LocationType.Shipper: {
        return formatMessage(messages.shipper, { locationType: formatMessage(messages.shipper) })
      }
      case LocationType.FuelStation: {
        return formatMessage(messages.fuelStation, {
          locationType: formatMessage(messages.fuelStations),
        })
      }
      case LocationType.Terminal: {
        return formatMessage(messages.terminal, { locationType: formatMessage(messages.terminals) })
      }
      case LocationType.Airport: {
        return formatMessage(messages.airport, { locationType: formatMessage(messages.airports) })
      }
      case LocationType.Seaport: {
        return formatMessage(messages.seaport, { locationType: formatMessage(messages.seaports) })
      }
      case LocationType.Standard: {
        return formatMessage(messages.standard, {
          locationType: formatMessage(messages.standardLocations),
        })
      }
      default: {
        return formatMessage(messages.createLocationTitle)
      }
    }
  }, [locationTypeId])

  const modalNewTitle = useMemo(() => {
    switch (locationTypeId) {
      case LocationType.Airport:
        return formatMessage(messages.createLocationTitle, {
          locationType: formatMessage(messages.airports),
        })
      case LocationType.FuelStation:
        return formatMessage(messages.createLocationTitle, {
          locationType: formatMessage(messages.fuelStations),
        })
      case LocationType.Terminal:
        return formatMessage(messages.createLocationTitle, {
          locationType: formatMessage(messages.terminals),
        })
      case LocationType.Seaport:
        return formatMessage(messages.createLocationTitle, {
          locationType: formatMessage(messages.seaports),
        })
      case LocationType.Shipper:
        return formatMessage(messages.createLocationTitle, {
          locationType: formatMessage(messages.shipper),
        })
      default:
        return formatMessage(messages.createLocationTitle, {
          locationType: formatMessage(messages.standard),
        })
    }
  }, [locationTypeId])

  const modalEditTitle = useMemo(() => {
    switch (locationTypeId) {
      case LocationType.Airport:
        return formatMessage(messages.editLocationTitle, {
          locationType: formatMessage(messages.airports),
        })
      case LocationType.FuelStation:
        return formatMessage(messages.editLocationTitle, {
          locationType: formatMessage(messages.fuelStations),
        })
      case LocationType.Terminal:
        return formatMessage(messages.editLocationTitle, {
          locationType: formatMessage(messages.terminals),
        })
      case LocationType.Seaport:
        return formatMessage(messages.editLocationTitle, {
          locationType: formatMessage(messages.seaports),
        })
      case LocationType.Shipper:
        return formatMessage(messages.editLocationTitle, {
          locationType: formatMessage(messages.shipper),
        })
      default:
        return formatMessage(messages.editLocationTitle, {
          locationType: formatMessage(messages.standard),
        })
    }
  }, [locationTypeId])

  const modalViewTitle = useMemo(() => {
    switch (locationTypeId) {
      case LocationType.Airport:
        return formatMessage(messages.viewLocationTitle, {
          locationType: formatMessage(messages.airports),
        })
      case LocationType.FuelStation:
        return formatMessage(messages.viewLocationTitle, {
          locationType: formatMessage(messages.fuelStations),
        })
      case LocationType.Terminal:
        return formatMessage(messages.viewLocationTitle, {
          locationType: formatMessage(messages.terminals),
        })
      case LocationType.Seaport:
        return formatMessage(messages.viewLocationTitle, {
          locationType: formatMessage(messages.seaports),
        })
      case LocationType.Shipper:
        return formatMessage(messages.viewLocationTitle, {
          locationType: formatMessage(messages.shipper),
        })
      default:
        return formatMessage(messages.viewLocationTitle, {
          locationType: formatMessage(messages.standard),
        })
    }
  }, [locationTypeId])

  return (
    <>
      <SideModalWrapper
        open={isCreateModalOpen}
        headerLabel={modalNewTitle}
        onClose={() => {
          refetch && refetch()
          setIsCreateModalOpen((pv) => !pv)
        }}
      >
        <LocationForm locationTypeId={locationTypeId} />
      </SideModalWrapper>
      <SideModalWrapper
        open={isEditModalOpen}
        headerLabel={modalEditTitle}
        onClose={() => {
          refetch && refetch()
          setIsEditModalOpen((pv) => !pv)
        }}
      >
        <EditLocation id={selectedLocationId} locationTypeId={locationTypeId} />
      </SideModalWrapper>
      <SideModalWrapper
        open={isViewModalOpen}
        headerLabel={modalViewTitle}
        onClose={() => {
          setIsViewModalOpen((pv) => !pv)
        }}
      >
        <EditLocation id={selectedLocationId} isDisabled={true} locationTypeId={locationTypeId} />
      </SideModalWrapper>
      <MRTDataGridv2
        initialState={{
          pagination: { pageSize: 100, pageIndex: 0 },
          columnPinning: { left: ['name'] },
          sorting: [
            {
              id: 'name',
              desc: false,
            },
          ],
        }}
        state={{ isLoading: isLoading }}
        leftHeadingComponent={
          <Box sx={{ display: 'inline-flex' }}>
            <TableTitleTypography>
              {title || formatMessage(messages.standardLocations)}
            </TableTitleTypography>
            {isTenantUser && locationTypeId !== LocationType.Shipper && (
              <FormControlLabel
                sx={{
                  marginLeft: '20px',
                  marginTop: '2px',
                  '& .MuiTypography-root': {
                    color: '#636363',
                  },
                }}
                control={
                  <Switch
                    size='small'
                    id='showLocations'
                    name='showLocations'
                    checked={showLocations}
                    onChange={() => setShowLocations((pv) => !pv)}
                  />
                }
                label='Include Customer Locations'
              />
            )}
          </Box>
        }
        rightHeadingComponent={
          <AddButton text={locationButtonTitle} onClick={() => setIsCreateModalOpen(true)} />
        }
        columns={columns as any}
        data={locations}
        getRowId={(row) => row.id as any}
        enableEditing
        enableColumnPinning={true}
        enableRowActions
        positionActionsColumn='last'
        renderRowActions={({ row, table }: any) => {
          return (
            <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
              {!row?.original.systemDefined && (
                <>
                  <Link
                    underline='none'
                    color='secondary'
                    onClick={() => {
                      setSelectedLocation(row?.original.id)
                      setIsEditModalOpen(true)
                    }}
                    sx={{
                      fontWeight: 700,
                      cursor: 'pointer',
                    }}
                  >
                    {formatMessage(messages.editButtonLbl)}
                  </Link>
                  <Typography color='secondary'>/</Typography>
                </>
              )}
              <Link
                underline='none'
                color='secondary'
                onClick={() => {
                  setSelectedLocation(row?.original.id)
                  setIsViewModalOpen(true)
                }}
                sx={{
                  fontWeight: 700,
                  cursor: 'pointer',
                }}
              >
                {formatMessage(messages.viewButtonLbl)}
              </Link>
            </Box>
          )
        }}
      />
    </>
  )
}

export default LocationsTable
