import { Box, Grid, Link, Typography } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef, MRT_Row as MRTRow } from 'material-react-table'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../app/hooks'
import { useGetAssetsQuery } from '../../app/redux-fetch/apiAssets'
import { useGetTransportModeQuery } from '../../app/redux-fetch/apiCoreQuery'
import AddButton from '../../components/Buttons/AddButton'
import MRTDataGridv2 from '../../components/MRTDataGrid/MRTDataGridv2'
import { TableTitleTypography } from '../../components/Typographies/TableTitleTypography'
import YesNoTypography from '../../components/YesNoTypograpphy/YesNoTypography'
import { fetchLoggedInUserSelector } from '../selectors'
import { IAssetManagement, ILoggedInUser } from '../types'
import CreateAsset from './CreateAsset'
import EditAsset from './EditAsset'
import ViewAsset from './ViewAsset'
import { messages } from './messages'
import SideModalWrapper from './modals/SideModalWrapper'

export function AssetTable() {
  const { formatMessage } = useIntl()
  const { data: transportModes = [], isLoading: transportModesLoading } = useGetTransportModeQuery()

  const columns = useMemo<MRTColumnDef<Partial<IAssetManagement>>[]>(() => {
    return [
      {
        id: 'identification',
        accessorFn(row) {
          return row.identification
        },
        header: formatMessage(messages.fleetNumber),
      },
      {
        accessorFn(row) {
          return transportModes.find(
            (tm) => tm.id === row.assetType?.assetCategory?.transportModeId
          )?.name
        },
        header: formatMessage(messages.transportMode),
      },
      {
        accessorFn(row) {
          return row.assetType?.assetCategory?.name
        },
        header: formatMessage(messages.assetsCategory),
      },
      {
        accessorFn(row) {
          return row.assetType?.name
        },
        header: formatMessage(messages.assetsTypes),
      },
      {
        accessorFn(row) {
          return row.assetClass?.name
        },
        header: formatMessage(messages.assetsClasses),
      },
      {
        accessorFn: (row) => {
          return row.active ? 'true' : 'false'
        },
        filterVariant: 'checkbox',
        header: formatMessage(messages.enabled),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: any) => {
          return <YesNoTypography isYes={cell.getValue() === 'true'} />
        },
      },
    ] as MRTColumnDef<Partial<IAssetManagement>>[]
  }, [transportModes])

  const handleRowSelectionChange = ({ row }: { row: MRTRow<Partial<IAssetManagement>> }) => {
    return {
      onClick: (e: { preventDefault: () => void; stopPropagation: () => void }) => {
        e.preventDefault()
        e.stopPropagation()
      },
      sx: {
        cursor: 'pointer',
        backgroundColor: Number(row.id) === 0 ? 'lightgrey' : 'initial',
      },
    }
  }

  const [createIsOpen, setCreateIsOpen] = useState(false)
  const [editIsOpen, setEditIsOpen] = useState(false)
  const [viewIsOpen, setViewIsOpen] = useState(false)
  const [viewRow, setViewRow] = useState<MRTRow<Partial<IAssetManagement>> | undefined>(undefined)
  const [editRow, setEditRow] = useState<MRTRow<Partial<IAssetManagement>> | undefined>(undefined)
  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const { data = [], isLoading: isAssetLoading } = useGetAssetsQuery({ tenantId })
  return (
    <Grid container spacing={2} pt={4} direction='column'>
      <Grid item>
        {viewIsOpen && (
          <SideModalWrapper
            open={viewIsOpen}
            onClose={() => {
              setViewRow(undefined)
              setViewIsOpen(false)
            }}
            headerLabel={'View Asset'}
            withBottomBox={false}
          >
            <ViewAsset assetId={viewRow?.original.id ?? -1} />
          </SideModalWrapper>
        )}
        {createIsOpen && (
          <SideModalWrapper
            open={createIsOpen}
            onClose={() => {
              setEditRow(undefined)
              setCreateIsOpen(false)
            }}
            headerLabel={'New Asset'}
            withBottomBox={false}
          >
            <CreateAsset />
          </SideModalWrapper>
        )}
        {editIsOpen && (
          <SideModalWrapper
            open={editIsOpen}
            onClose={() => {
              setEditRow(undefined)
              setEditIsOpen(false)
            }}
            headerLabel={'Edit Asset'}
            withBottomBox={false}
          >
            <EditAsset assetId={editRow?.original.id ?? -1} />
          </SideModalWrapper>
        )}
        <MRTDataGridv2
          columns={columns}
          data={data}
          initialState={{
            pagination: { pageSize: 100, pageIndex: 0 },
            sorting: [
              {
                id: 'identification',
                desc: false,
              },
            ],
          }}
          leftHeadingComponent={
            <Box sx={{ display: 'inline-flex' }}>
              <TableTitleTypography>{formatMessage(messages.title)}</TableTitleTypography>
            </Box>
          }
          rightHeadingComponent={
            <AddButton
              text={formatMessage(messages.createAssetBtn)}
              onClick={() => setCreateIsOpen(true)}
            />
          }
          getRowId={(row) => `${row.id}`}
          muiTableBodyRowProps={handleRowSelectionChange}
          state={{ isLoading: transportModesLoading || isAssetLoading }}
          enableEditing
          positionActionsColumn='last'
          renderRowActions={({ row }) => {
            return (
              <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                <Link
                  underline='none'
                  color='secondary'
                  onClick={() => {
                    setEditRow(row)
                    setEditIsOpen(true)
                  }}
                  sx={{
                    fontWeight: 700,
                    cursor: 'pointer',
                  }}
                >
                  {formatMessage(messages.editLnk)}
                </Link>
                <Typography color='secondary'>/</Typography>
                <Link
                  underline='none'
                  color='secondary'
                  onClick={() => {
                    setViewRow(row)
                    setViewIsOpen(true)
                  }}
                  sx={{
                    fontWeight: 700,
                    cursor: 'pointer',
                  }}
                >
                  {formatMessage(messages.viewLnk)}
                </Link>
              </Box>
            )
          }}
        />
      </Grid>
    </Grid>
  )
}
