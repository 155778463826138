import { Box, Button, createTheme, Grid, Tab, Tabs, ThemeProvider, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { useLoggedInUser } from '../../../app/hooks/useLoggedInUser'
import { useNotificationStack } from '../../../app/hooks/useNotificationStack'
import {
  useAcceptOrderByTenantIdMutation,
  useGetShipmentByIdQuery,
  useRecallOrderMutation,
  useRejectOrderByTenantIdMutation,
  useResetReviewOrderByTenantIdMutation,
  useReviewOrderByTenantIdMutation,
  useSubmitOrderMutation,
} from '../../../app/redux-fetch/apiShipment'
import CustomAlert from '../../../components/Alerts/CustomAlert'
import TooltipButton from '../../../components/Buttons/TooltipButton'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'
import ActionButton from '../../../components/Forms/ActionButton'
import rootEnum from '../../../components/Routes/rootEnum'
import SpinnerBlock from '../../../components/Spinner/SpinnerBlock'
import TabPanel from '../../../components/TabPanel/TabPanel'
import { OrderStatuses } from '../../../types/common'
import Note from '../../Notes/Note'
import TerminalInfo from '../../Shipments/components/TerminalInfo/TerminalInfo'
import Timeline from '../../Shipments/components/Timeline/Timeline'
import ViewShipmentTabHeader from '../../Shipments/components/ViewShipment/ViewShipmentTabHeader'
import TemperatureChart from '../../TemperatureChart/TemperatureChart'
import messages from './messages'
import ShipmentSummary from './ShipmentSummary'
import { mapToShipmentFormInput } from './utils'

const ViewShipment = (): ReactElement => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { id } = useParams()
  const shipmentId = Number(id)
  const { tenantId, loggedInUser, isTenantUser, isCustomerUser } = useLoggedInUser()
  const loggedInUserId = loggedInUser.id

  const [activeTab, setActiveTab] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isUnderReview, setIsUnderReview] = useState<boolean>(false)
  const [countdown, setCountdown] = useState<number>(0)
  const [underReviewUserId, setUnderReviewUserId] = useState<number | undefined>()
  const { enqueueSuccess, enqueueFailure } = useNotificationStack()

  const [submitOrder] = useSubmitOrderMutation()
  const [acceptOrder] = useAcceptOrderByTenantIdMutation()
  const [rejectOrder] = useRejectOrderByTenantIdMutation()
  const [recallOrder] = useRecallOrderMutation()
  const [reviewOrder] = useReviewOrderByTenantIdMutation()
  const [resetReviewOrder] = useResetReviewOrderByTenantIdMutation()

  const theme = useTheme()

  const customTheme = createTheme(theme, {
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: theme.palette.secondary.main,
              color: 'white',
              borderRadius: '5px',
            },
          },
        },
      },
    },
  })

  const {
    data: order,
    isFetching: shipmentSelectorFetching,
    isSuccess: shipmentLoaded,
    refetch,
  } = useGetShipmentByIdQuery({
    tenantId: tenantId,
    shipmentId: Number(id),
  })

  useEffect(() => {
    if (order) {
      if (order.underReviewUntil && order.underReviewByUserId) {
        const diffTime = Math.max(
          0,
          Math.floor((new Date(order.underReviewUntil).getTime() - Date.now()) / 1000)
        )
        if (diffTime > 0) {
          setCountdown(diffTime)
          setIsUnderReview(true)
          setUnderReviewUserId(order.underReviewByUserId)
        } else {
          setIsUnderReview(false)
          setUnderReviewUserId(undefined)
          setCountdown(0)
        }
      } else {
        setIsUnderReview(false)
        setUnderReviewUserId(undefined)
        setCountdown(0)
      }
    }
  }, [order])

  const isUnderReviewByCurrentUser = useCallback(() => {
    return isUnderReview && underReviewUserId === loggedInUserId
  }, [isUnderReview, underReviewUserId, loggedInUserId])

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1)
      }, 1000)
      return () => clearTimeout(timer)
    } else if (isUnderReview && underReviewUserId === loggedInUserId) {
      handleResetReview()
    }
  }, [countdown, isUnderReview, underReviewUserId, loggedInUserId])

  const handleLockForReview = useCallback(async () => {
    try {
      setIsSubmitting(true)
      await reviewOrder({ tenantId, id: shipmentId })
      setUnderReviewUserId(loggedInUserId)
      setIsUnderReview(true)
      setCountdown(10 * 60)
      enqueueSuccess(messages.shipmentUpdateSuccess)
      await refetch()
    } catch (e) {
      enqueueFailure(messages.shipmentUpdateError)
    } finally {
      setIsSubmitting(false)
    }
  }, [tenantId, order, shipmentId, loggedInUserId, refetch, formatMessage])

  const handleResetReview = useCallback(async () => {
    try {
      await resetReviewOrder({ tenantId, id: shipmentId })
      setIsUnderReview(false)
      setUnderReviewUserId(undefined)
      setCountdown(0)
      await refetch()
    } catch (e) {
      enqueueFailure(messages.shipmentUpdateError)
    }
  }, [tenantId, order, shipmentId, refetch, formatMessage])

  const handleBackToShipment = useCallback(async () => {
    if (underReviewUserId === loggedInUserId && isUnderReview) {
      await handleResetReview()
    }
    navigate(rootEnum.SHIPMENTS)
  }, [navigate, underReviewUserId, loggedInUserId, isUnderReview, handleResetReview])

  const handleChange = (_event: React.SyntheticEvent, tabId: number) => {
    setActiveTab(tabId)
  }

  const isUnderReviewOrDifferentUser = useCallback(() => {
    return isUnderReview && underReviewUserId !== loggedInUserId
  }, [isUnderReview, underReviewUserId, loggedInUserId])

  if (!shipmentLoaded) return <SpinnerBlock />

  const isSubmitted =
    order.status === OrderStatuses.Submitted || order.status === OrderStatuses.UnderReview
  const isAccepted = order.status === OrderStatuses.Accepted

  const handleAccept = async () => {
    try {
      if (!isSubmitted) {
        await submitOrder({ tenantId, id: order.id }).unwrap()
      }
      await acceptOrder({ tenantId, id: order.id }).unwrap()
      enqueueSuccess(messages.shipmentAccepted)
      navigate('/shipping/shipments')
    } catch (error) {
      enqueueFailure(messages.shipmentAcceptFailed)
    }
  }

  const handleSubmit = async () => {
    try {
      await submitOrder({ tenantId, id: order.id }).unwrap()
      enqueueSuccess(messages.shipmentSubmitted)
      navigate('/shipping/shipments')
    } catch (error) {
      enqueueFailure(messages.shipmentSubmitFailed)
    }
  }

  const handleReject = async () => {
    try {
      await rejectOrder({ tenantId, id: order.id }).unwrap()
      enqueueSuccess(messages.shipmentRejected)
      navigate('/shipping/shipments')
    } catch (error) {
      enqueueFailure(messages.shipmentRejectFailed)
    }
  }

  const handleRecall = async () => {
    try {
      await recallOrder({ tenantId, id: order.id }).unwrap()
      enqueueSuccess(messages.shipmentRecalled)
      navigate('/shipping/shipments')
    } catch (error) {
      enqueueFailure(messages.shipmentRecallFailed)
    }
  }

  const isConsignmentRequired = !order.bookings || order.bookings?.length === 0

  const isLoadRequired =
    !isConsignmentRequired &&
    order.bookings?.length > 0 &&
    !order.bookings.every(
      (booking: any) => booking?.loads && Array.isArray(booking.loads) && booking.loads.length > 0
    )

  const isLoadContentRequired =
    !isConsignmentRequired &&
    order.bookings?.length > 0 &&
    !order.bookings.every(
      (booking: any) =>
        booking?.loads &&
        Array.isArray(booking.loads) &&
        booking.loads.length > 0 &&
        booking.loads.some((pack: any) => {
          return (
            pack &&
            ((pack.value !== undefined && pack.quantity !== null) ||
              (Array.isArray(pack.contents) && pack.contents.length > 0))
          )
        })
    )

  const getTooltipMessage = () => {
    if (isConsignmentRequired) {
      return formatMessage(messages.noConsignment)
    }
    if (isLoadContentRequired && !isLoadRequired) {
      return formatMessage(messages.noLoadContent)
    }
    return formatMessage(messages.noLoad)
  }

  const isRejected = order.status === OrderStatuses.Rejected

  return (
    <Grid container spacing={1} pt={4}>
      <Grid container spacing={1} pt={2} pb={2} pl={'10px'}>
        <Grid item xs>
          <Box display='flex' justifyContent='flex-start'>
            <Button color='secondary' variant='contained' onClick={handleBackToShipment}>
              {formatMessage(messages.backToShipmentstBtn)}
            </Button>
          </Box>
        </Grid>
        <Grid item>
          <Grid container justifyContent='flex-end' spacing={1}>
            <Grid item>
              <ConditionalRender condition={isTenantUser && isSubmitted && !isAccepted}>
                <ActionButton
                  variant='contained'
                  color='secondary'
                  onClick={handleLockForReview}
                  actionLabel={
                    isUnderReviewByCurrentUser() && countdown > 0 ? (
                      <Button
                        size='small'
                        onClick={async () => {
                          await handleResetReview()
                        }}
                        sx={{ textTransform: 'none', p: 0, mb: '4px' }}
                      >
                        {formatMessage(messages.unlock)}
                      </Button>
                    ) : (
                      <></>
                    )
                  }
                  disabled={isUnderReview || isSubmitting}
                >
                  {countdown && countdown > 0
                    ? formatMessage(messages.countdownTitle, {
                      value: `[${dayjs().hour(0).minute(0).second(countdown).format('mm:ss')}]`,
                    })
                    : formatMessage(messages.lockForReview)}
                </ActionButton>
              </ConditionalRender>
            </Grid>
            <Grid item>
              <ConditionalRender condition={isTenantUser && !isAccepted && !isRejected}>
                <TooltipButton
                  variant='contained'
                  color='secondary'
                  onClick={handleAccept}
                  disabled={
                    isUnderReviewOrDifferentUser() ||
                    isSubmitting ||
                    isConsignmentRequired ||
                    isLoadRequired
                  }
                  showTooltip={isConsignmentRequired || isLoadRequired}
                  tooltipProps={{
                    placement: 'top',
                    title: getTooltipMessage(),
                    children: <></>,
                  }}
                >
                  {isSubmitted
                    ? formatMessage(messages.acceptShipment)
                    : formatMessage(messages.submitAndAccept)}
                </TooltipButton>
              </ConditionalRender>
            </Grid>
            <Grid item>
              <ConditionalRender condition={isTenantUser && isSubmitted && !isAccepted}>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleReject}
                  disabled={isUnderReviewOrDifferentUser() || isSubmitting}
                >
                  {formatMessage(messages.buttonReject)}
                </Button>
              </ConditionalRender>
            </Grid>
            <Grid item>
              <ConditionalRender condition={isCustomerUser && isSubmitted && !isAccepted}>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleRecall}
                  disabled={isUnderReview || isSubmitting}
                >
                  {formatMessage(messages.buttonRecall)}
                </Button>
              </ConditionalRender>
            </Grid>
            <Grid item>
              <ConditionalRender condition={!isSubmitted && !isAccepted && !isRejected}>
                <TooltipButton
                  variant='contained'
                  onClick={handleSubmit}
                  disabled={
                    isUnderReview || isSubmitting || isConsignmentRequired || isLoadRequired
                  }
                  showTooltip={isConsignmentRequired || isLoadRequired}
                  tooltipProps={{
                    placement: 'top',
                    title: getTooltipMessage(),
                    children: <></>,
                  }}
                >
                  {formatMessage(messages.submitShipment)}
                </TooltipButton>
              </ConditionalRender>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        {isConsignmentRequired && (
          <CustomAlert
            severity='error'
            title='Error'
            message={formatMessage(messages.noConsignment)}
          />
        )}
        {isLoadRequired && (
          <CustomAlert severity='error' title='Error' message={formatMessage(messages.noLoad)} />
        )}
        {isLoadContentRequired && !isLoadRequired && (
          <CustomAlert
            severity='error'
            title='Error'
            message={formatMessage(messages.noLoadContent)}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <ViewShipmentTabHeader />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ width: '100%', pt: '28px', pb: '28px' }}>
          <ThemeProvider theme={customTheme}>
            <Tabs
              sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
              value={activeTab}
              onChange={handleChange}
              centered
              variant='standard'
              scrollButtons={false}
              aria-label='Shipment View Tabs'
            >
              <Tab label='Details' />
              <Tab label='Timeline' />
              <Tab label='Notes' />
              <Tab label='Temperature' />
              <Tab label='Terminal Info' />
            </Tabs>
          </ThemeProvider>
        </Box>
        <TabPanel value={activeTab} index={0}>
          <Grid item xs={12}>
            <ShipmentSummary data={mapToShipmentFormInput(order)} />
          </Grid>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Grid item xs={12}>
            <Timeline />
          </Grid>
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <Grid item xs={12}>
            <Note />
          </Grid>
        </TabPanel>
        <TabPanel value={activeTab} index={3}>
          <Grid item xs={12}>
            <TemperatureChart data={[]} loadId={0} loadName={''} title={''} />
          </Grid>
        </TabPanel>
        <TabPanel value={activeTab} index={4}>
          <Grid item xs={12}>
            <TerminalInfo />
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  )
}

export default ViewShipment
