import { Box, TextField } from '@mui/material'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useLoggedInUser } from '../../../app/hooks/useLoggedInUser'
import { useGetIndustriesQuery, useGetUsersQuery } from '../../../app/redux-fetch/apiQuery'
import FormDropdown from '../../../components/FormDropDown/FormDropdown'
import RequiredAsterisk from '../../../components/RequiredAsterisk/RequiredAsterisk'
import {
  SectionLabelTypography,
  SectionTitleTypography,
} from '../../../components/Typographies/styles'
import { useCustomerFormContext } from './hooks/useCustomerFormContext'
import messages from './messages'

type CustomerDetailProps = {
  isDisabled?: boolean
  isNew?: boolean
}

const CustomerDetail = (props: CustomerDetailProps) => {
  const { isDisabled = false, isNew } = props
  const { formatMessage } = useIntl()
  const { data: industries } = useGetIndustriesQuery()
  const { values, setFieldValue, errors, touched } = useCustomerFormContext()
  const { tenantId } = useLoggedInUser()
  const { data: users = [] } = useGetUsersQuery({
    tenantId
  })
  const handleTextFieldChange = ({
    target,
  }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFieldValue(target.id, target.value)
  }
  const handleFormDropDownChange = (e: any, name: any, newValue: any) => {
    setFieldValue(name, newValue)
  }
  const userOptions = useMemo(() => {
    return users.map((user: any) => {
      return {
        id: user.id,
        name: `${user.firstName} ${user.lastName}`
      }
    }
    )
  }, [users])


  const {
    name,
    alias,
    accountNumber,
    registrationNumber,
    email,
    secondaryEmail,
    webSite,
    vatNumber,
    industryId,
    accountOwnerId,
  } = values

  return (
    <Box sx={{ pb: '16px' }}>
      <Box sx={{ mb: '16px' }}>
        <SectionTitleTypography>{formatMessage(messages.customerDetail)}</SectionTitleTypography>
      </Box>
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.customerName)}
          <RequiredAsterisk />
        </SectionLabelTypography>
        <TextField
          fullWidth
          id='name'
          value={name}
          defaultValue={name}
          disabled={isDisabled}
          onChange={handleTextFieldChange}
          error={touched.name && !!errors.name}
          helperText={touched.name && errors.name}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.accountOwner)}
          <RequiredAsterisk />
        </SectionLabelTypography>
        <FormDropdown
          id='accountOwnerId'
          items={userOptions}
          onChange={handleFormDropDownChange}
          value={accountOwnerId}
          disabled={isDisabled}
          defaultValue={accountOwnerId}
          error={touched.industryId && !!errors.industryId}
          errorText={touched.industryId && errors.industryId}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.customerAlias)}
        </SectionLabelTypography>
        <TextField
          fullWidth
          id='alias'
          value={alias}
          defaultValue={alias}
          disabled={isDisabled}
          onChange={handleTextFieldChange}
          error={touched.alias && !!errors.alias}
          helperText={touched.alias && errors.alias}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.accountNumber)}
        </SectionLabelTypography>
        <TextField
          fullWidth
          id='accountNumber'
          value={accountNumber}
          defaultValue={accountNumber}
          disabled={isDisabled}
          onChange={handleTextFieldChange}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.customerEmail)}
        </SectionLabelTypography>
        <TextField
          fullWidth
          id='email'
          value={email}
          defaultValue={email}
          disabled={isDisabled}
          onChange={handleTextFieldChange}
          error={touched.email && !!errors.email}
          helperText={touched.email && errors.email}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.secondaryEmail)}
        </SectionLabelTypography>
        <TextField
          fullWidth
          id='secondaryEmail'
          value={secondaryEmail}
          defaultValue={secondaryEmail}
          disabled={isDisabled}
          onChange={handleTextFieldChange}
          error={touched.secondaryEmail && !!errors.secondaryEmail}
          helperText={touched.secondaryEmail && errors.secondaryEmail}
        />
      </Box>
      <Box display='flex' columnGap={2} sx={{ mb: '16px' }}>
        <Box flex={1}>
          <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
            {formatMessage(messages.companyRegistrationNumber)}
          </SectionLabelTypography>
          <TextField
            fullWidth
            id='registrationNumber'
            value={registrationNumber}
            defaultValue={registrationNumber}
            disabled={isDisabled}
            onChange={handleTextFieldChange}
          />
        </Box>
        <Box flex={1}>
          <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
            {formatMessage(messages.VATNumber)}
          </SectionLabelTypography>
          <TextField
            fullWidth
            id='vatNumber'
            value={vatNumber}
            defaultValue={vatNumber}
            disabled={isDisabled}
            onChange={handleTextFieldChange}
          />
        </Box>
      </Box>
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.industry)}
        </SectionLabelTypography>
        <FormDropdown
          id='industryId'
          items={industries ?? []}
          onChange={handleFormDropDownChange}
          value={industryId}
          disabled={isDisabled}
          defaultValue={industryId}
          error={touched.industryId && !!errors.industryId}
          errorText={touched.industryId && errors.industryId}
        />
      </Box>
      <Box flex={1}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.webSite)}
        </SectionLabelTypography>
        <TextField
          fullWidth
          id='webSite'
          value={webSite}
          defaultValue={webSite}
          disabled={isDisabled}
          onChange={handleTextFieldChange}
        />
      </Box>
    </Box>
  )
}

export default CustomerDetail
