import CancelIcon from '@mui/icons-material/CancelOutlined'
import { Box, Link, Typography } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../../app/hooks'
import { useNotificationStack } from '../../../../../app/hooks/useNotificationStack'
import {
  useDeleteAssetTypeMutation,
  useUseGetAssetTypesByTenantIdQuery,
} from '../../../../../app/redux-fetch/apiAssets'
import { useGetTransportModeQuery } from '../../../../../app/redux-fetch/apiCoreQuery'
import AddButton from '../../../../../components/Buttons/AddButton'
import ConditionalRender from '../../../../../components/ConditionalRender/ConditionalRender'
import MRTDataGridv2 from '../../../../../components/MRTDataGrid/MRTDataGridv2'
import YesNoTypography from '../../../../../components/YesNoTypograpphy/YesNoTypography'
import { fetchLoggedInUserSelector } from '../../../../selectors'
import { ILoggedInUser } from '../../../../types'
import { messages } from '../../messages'
import { AssetType } from '../../types'
import AssetTypeForm from './AssetTypeForm'

const AssetTypeTable = () => {
  const [createOpen, setCreateOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)

  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

  const { data: assetTypes = [], isLoading: assetTypesLoading } =
    useUseGetAssetTypesByTenantIdQuery({ tenantId })
  const { data: transportModes = [], isLoading: transportModesLoading } = useGetTransportModeQuery()
  const [deleteAssetType] = useDeleteAssetTypeMutation()
  const [selectedAssetTypeId, setSelectedAssetTypeId] = useState(-1)
  const { enqueueSuccess, enqueueFailure } = useNotificationStack()

  const { formatMessage } = useIntl()

  const columns = useMemo<MRTColumnDef<AssetType>[]>(() => {
    return [
      {
        accessorKey: 'name',
        header: formatMessage(messages.assetTypeName),
        Cell: ({ cell }: any) => {
          return <Typography sx={{ fontWeight: 700 }}>{cell.getValue()}</Typography>
        },
      },
      {
        id: 'categoryName',
        accessorFn: (row) => {
          return row.assetCategory?.name ?? ''
        },
        header: formatMessage(messages.category),
      },
      {
        id: 'transportModeId',
        accessorFn: (row) => {
          return transportModes.find((tm) => tm.id === row.assetCategory?.transportModeId)?.name
        },
        Cell: ({ cell }: any) => {
          return <>{cell.getValue()}</>
        },
        header: formatMessage(messages.transportMode),
      },
      {
        accessorFn: (row) => {
          return row.isTemperatureControlled ? 'true' : 'false'
        },
        filterVariant: 'checkbox',
        header: formatMessage(messages.isTemperatureControlled),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: any) => {
          return <YesNoTypography isYes={cell.getValue() === 'true'} />
        },
      },
      {
        accessorFn: (row) => {
          return row.isMotorized ? 'true' : 'false'
        },
        filterVariant: 'checkbox',
        header: formatMessage(messages.motorized),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: any) => {
          return <YesNoTypography isYes={cell.getValue() === 'true'} />
        },
      },
      {
        accessorFn: (row) => {
          return row.requiresDriver ? 'true' : 'false'
        },
        filterVariant: 'checkbox',
        header: formatMessage(messages.requiresDriver),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: any) => {
          return <YesNoTypography isYes={cell.getValue() === 'true'} />
        },
      },
      {
        accessorFn: (row) => {
          return row.sealable ? 'true' : 'false'
        },
        filterVariant: 'checkbox',
        header: formatMessage(messages.sealable),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: any) => {
          return <YesNoTypography isYes={cell.getValue() === 'true'} />
        },
      },
      {
        accessorFn: (row) => {
          return row.carriesPayload ? 'true' : 'false'
        },
        filterVariant: 'checkbox',
        header: formatMessage(messages.carriesPayload),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: any) => {
          return <YesNoTypography isYes={cell.getValue() === 'true'} />
        },
      },
      {
        accessorFn: (row) => row.workforceTypes?.map(({ name }) => name).join(', '),
        header: formatMessage(messages.requiresWorkforce),
      },
      {
        accessorFn: (row) => {
          return row.enabled ? 'true' : 'false'
        },
        filterVariant: 'checkbox',
        header: formatMessage(messages.enabled),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: any) => {
          return <YesNoTypography isYes={cell.getValue() === 'true'} />
        },
      },
      {
        accessorFn: (row) => {
          return row.inUse ? 'true' : 'false'
        },
        filterVariant: 'checkbox',
        header: formatMessage(messages.inUse),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ cell }: any) => {
          return <YesNoTypography isYes={cell.getValue() === 'true'} />
        },
      },
    ]
  }, [assetTypes, transportModes])

  return (
    <>
      <ConditionalRender condition={createOpen}>
        <AssetTypeForm
          isOpen={createOpen}
          handleClose={() => {
            setCreateOpen((pv) => {
              return !pv
            })
          }}
          assetType={{
            enabled: true,
            tenantId,
          }}
        />
      </ConditionalRender>
      <ConditionalRender condition={editOpen}>
        <AssetTypeForm
          isOpen={editOpen}
          handleClose={() => {
            setEditOpen((pv) => {
              return !pv
            })
          }}
          assetType={assetTypes.find((at: any) => at.id === selectedAssetTypeId)}
        />
      </ConditionalRender>
      <MRTDataGridv2
        initialState={{
          pagination: { pageSize: 100, pageIndex: 0 },
          columnPinning: { left: ['name'] },
          sorting: [
            {
              id: 'transportModeId',
              desc: false,
            },
            {
              id: 'categoryName',
              desc: false,
            },
            {
              id: 'name',
              desc: false,
            },
          ],
        }}
        state={{ isLoading: assetTypesLoading || transportModesLoading }}
        rightHeadingComponent={
          <AddButton
            text={formatMessage(messages.newAssetType)}
            onClick={() => setCreateOpen(true)}
          />
        }
        columns={columns}
        data={assetTypes}
        getRowId={(row) => row.id as any}
        enableEditing
        enableRowActions
        enableColumnPinning={true}
        positionActionsColumn='last'
        globalFilterFn='contains'
        renderRowActions={({ row, table }: any) => {
          return (
            <>
              <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                <Link
                  underline='none'
                  color='secondary'
                  onClick={() => {
                    setSelectedAssetTypeId(row.id)
                    setEditOpen(true)
                  }}
                  sx={{
                    fontWeight: 700,
                    cursor: 'pointer',
                  }}
                >
                  {formatMessage(messages.editActionLbl)}
                </Link>
                <ConditionalRender condition={row.original.inUse == false}>
                  <CancelIcon
                    color='secondary'
                    sx={{
                      cursor: 'pointer',
                      pl: '4px',
                    }}
                    onClick={() => {
                      deleteAssetType({ tenantId, assetTypeId: row.id })
                        .then(() => {
                          enqueueSuccess(messages.assetTypeDeleted)
                        })
                        .catch(() => {
                          enqueueFailure(messages.assetTypeNotDeleted)
                        })
                    }}
                  />
                </ConditionalRender>
              </Box>
            </>
          )
        }}
      />
    </>
  )
}

export default AssetTypeTable
