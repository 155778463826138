import { Box, FormControl, MenuItem, TextField, TextFieldProps } from '@mui/material';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputHeader } from '../Headers/styles';
import RequiredAsterisk from '../RequiredAsterisk/RequiredAsterisk';

export interface Option {
  label: string;
  value: string | number;
  disabled?: boolean;
}

type FormSelectProps = TextFieldProps & {
  name: string;
  options: Option[];
  allOptions?: Option[];
  placeholder?: string;
  actionLabel?: React.ReactNode;
  allowClear?: boolean;
  disableLabel?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

const FormSelect: React.FC<FormSelectProps> = ({
  name,
  options,
  allOptions,
  label,
  placeholder = 'Please select',
  disableLabel = 'No Items',
  allowClear = false,
  actionLabel,
  onChange,
  ...props
}) => {
  const { control } = useFormContext();

  const combinedOptions = React.useMemo(() => {
    if (allowClear) {
      return [{ label: 'None', value: '', disabled: false }, ...options];
    }
    return options;
  }, [allowClear, options]);

  return (
    <Box mb={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <InputHeader gutterBottom>{label} {props.required ? (<RequiredAsterisk />) : null}</InputHeader>
        {actionLabel && actionLabel}
      </Box>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth variant="outlined" error={!!error}>
            <TextField
              {...field}
              {...props}
              placeholder={options.length === 0 ? disableLabel : placeholder}
              value={field.value ?? ''}
              onChange={(event) => {
                const value = event.target.value === '' ? null : event.target.value;
                if (onChange) {
                  onChange(event);
                }
                field.onChange(value);
              }}
              select
              disabled={props.disabled || options.length === 0}
              SelectProps={{
                displayEmpty: true,
                renderValue: (value: unknown) => {
                  if (!value) {
                    return <span style={{ color: '#aaa' }}>{options.length === 0 ? disableLabel : placeholder}</span>;
                  }
                  let selectedOption = combinedOptions.find(option => option.value == value);
                  if (!selectedOption && allOptions) {
                    selectedOption = allOptions.find(option => option.value == value);
                  }
                  return (selectedOption ? selectedOption.label : value) as React.ReactNode;
                },
              }}
              fullWidth
              helperText={error ? error.message : ''}
              error={!!error}
            >
              {combinedOptions.map(option => (
                <MenuItem key={option.value ?? -1} value={option.value} disabled={option.disabled}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default FormSelect;