import {
    AccordionDetails,
    Grid
} from '@mui/material'
import dayjs from 'dayjs'
import React, { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import StyledAccordion from '../../../../components/Accordions/StyledAccordion'
import StyledAccordionSummary from '../../../../components/Accordions/StyledAccordionSummary'
import InfoDisplayItem from '../../../../components/Typographies/InfoDisplayItem'
import { LabelItem } from '../../../../components/Typographies/LabelItem'
import { LocationType } from '../../../../types/common'
import { useLocationModal } from '../../hooks/useLocationModal'
import FlowSection from '../FlowSection'
import messages from '../messages'
import TransportIcon from '../TransportIcon'
import { StopType } from '../types'
import ConsignmentServiceTable from './ConsignmentServiceTable'

interface ServiceStopProps {
    consignmentIndex: number
    stopType: StopType
    customerId: number
    stopId?: number
}

const ServiceStop: React.FC<ServiceStopProps> = ({ consignmentIndex, stopType, stopId, customerId }) => {
    const { watch } = useFormContext()
    const [expanded, setExpanded] = useState(true)
    const { formatMessage } = useIntl()

    const handleToggleAccordion = () => {
        setExpanded((prev) => !prev)
    }

    const stopPrefix = useMemo(() => {
        if (stopType !== StopType.Waypoint) {
            return stopType
        }
        return `waypoints.${stopId}`

    }, [consignmentIndex])


    const {
        locationOptions,
    } = useLocationModal({
        onSave: () => {
            // Do something
        },
        customerId,
    })

    const locationId = watch(`bookings.${consignmentIndex}.${stopPrefix}.locationId`)
    const transportMode = watch(`bookings.${consignmentIndex}.${stopPrefix}.transitDetails.modeOfTransit`)

    const locationName = useMemo(() => {
        const location = locationOptions.find((loc: any) => loc.value === locationId)


        return location?.label || ''
    }, [locationId, locationOptions])

    const serviceTitle = useMemo(() => {
        switch (stopType) {
            case StopType.ShipFrom:
                return formatMessage(messages.shipFrom) || 'Ship From'
            case StopType.DeliveryTo:
                return formatMessage(messages.deliverTo) || 'Ship To'
            case StopType.Waypoint:
                return formatMessage(messages.waypoint) || 'Waypoint'
            default:
                return ''
        }
    }, [stopType])

    const getStopScheduledTypeName = useMemo(() => {
        switch (stopType) {
            case StopType.ShipFrom:
                return 'loadingDate'
            case StopType.DeliveryTo:
                return 'deliveryDate'
            case StopType.Waypoint:
                return 'loadingDate'
            default:
                return ''
        }
    }, [stopType])

    const scheduledDate = dayjs(watch(`bookings.${consignmentIndex}.${stopPrefix}.${getStopScheduledTypeName}`)).format('DD/MM/YYYY')

    return (
        <FlowSection hasBorder={stopType !== StopType.DeliveryTo} icon={transportMode !== undefined ? <TransportIcon id={transportMode} /> : undefined}>
            <StyledAccordion
                defaultExpanded
                sx={{ marginTop: '10px' }}
                onChange={handleToggleAccordion}
            >
                <StyledAccordionSummary
                    index={`service-stop-${consignmentIndex}`}
                    expanded={expanded}
                    title={serviceTitle}
                >
                    <Grid container>
                        <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <LabelItem>{serviceTitle}</LabelItem>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <InfoDisplayItem
                                label={formatMessage(messages.locationName) || 'Location'}
                                value={locationName}
                                isSmallScreen={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <InfoDisplayItem
                                label={formatMessage(messages.scheduledDate) || 'Scheduled Date'}
                                value={scheduledDate || '-'}
                                isSmallScreen={false}
                            />
                        </Grid>
                    </Grid>
                </StyledAccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ConsignmentServiceTable
                                consignmentIndex={consignmentIndex}
                                stopType={stopType}
                                stopPrefix={stopPrefix}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </StyledAccordion>
        </FlowSection>
    )
}

export default ServiceStop
