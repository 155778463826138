import { Box, CircularProgress } from '@mui/material';

interface LoadingIndicatorProps {
    size?: number | string;
    color?: 'primary' | 'secondary' | 'inherit';
}

const LoadingIndicator = ({ size = 40, color = 'secondary' }: LoadingIndicatorProps) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
            width="100%"
        >
            <CircularProgress size={size} color={color} />
        </Box>
    );
};

export default LoadingIndicator;
