import { Box } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import CustomAlert from '../../../../components/Alerts/CustomAlert';
import ConsignmentInfo from '../ConsignmentInfo';
import messages from '../messages';
import { StopType } from '../types';
import ServiceStop from './ServiceStop';

interface ConsignmentServiceProps {
    expanded?: boolean;
    consignmentIndex: number;
    onChange?: () => void;
}

const ConsignmentService: React.FC<ConsignmentServiceProps> = ({ expanded = true, onChange, consignmentIndex }) => {
    const { formatMessage } = useIntl();
    const { watch } = useFormContext();

    const customerId = watch('customerId');
    const booking = watch(`bookings.${consignmentIndex}`);

    return (
        <>
            <ConsignmentInfo consignmentIndex={consignmentIndex} />
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'right',
                }}>
                <CustomAlert
                    severity='warning'
                    message={formatMessage(messages.noChangesForService)}
                />
            </Box>

            <>

                <ServiceStop consignmentIndex={consignmentIndex} stopType={StopType.ShipFrom} customerId={customerId} />
                {booking.waypoints.map((waypoint: any, waypointIndex: number) => (
                    <ServiceStop key={waypointIndex} consignmentIndex={consignmentIndex} stopType={StopType.Waypoint} stopId={waypointIndex} customerId={customerId} />
                ))}
                <ServiceStop consignmentIndex={consignmentIndex} stopType={StopType.DeliveryTo} customerId={customerId} />
            </>
        </>
    );
};

export default ConsignmentService;