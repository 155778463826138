import { Box, Button, FormControlLabel, Grid, Switch } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useGetCountriesQuery } from '../../../app/redux-fetch/apiCoreQuery'
import {
  useGetCurrenciesByCustomerIdQuery,
} from '../../../app/redux-fetch/apiShipment'
import { convertToLabelValue } from '../../../app/utils'
import FormInput from '../../../components/Forms/FormInput'
import FormSelect from '../../../components/Forms/FormSelect'
import InfoDisplay from '../../../components/Forms/InfoDisplay'
import commonMessages from '../../../components/Messages/commonMessages'
import { useProductModal } from '../hooks/useProductModal'
import messages from './messages'

interface ProductFormProps {
  consignmentIndex: number
  packagingIndex: number
  productIndex: number
  removeProduct: (index: number) => void
}

const ProductForm: React.FC<ProductFormProps> = ({
  consignmentIndex,
  packagingIndex,
  productIndex,
  removeProduct,
}) => {
  const { control, setValue, watch } = useFormContext()
  const { formatMessage } = useIntl()

  const customerId = watch('customerId')

  const { openModal, ModalComponent, productOptions, products } = useProductModal({
    onSave: (id) => {
      setValue(
        `bookings.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.productDescription`,
        id
      )
    },
    customerId: customerId ?? 0,
  })

  const currencyId = watch(`bookings.${consignmentIndex}.currencyId`)

  const { data: currencies = [] } = useGetCurrenciesByCustomerIdQuery({
    customerId: customerId ?? 0,
    hideDisabled: true,
  })
  const { data: countries = [] } = useGetCountriesQuery()

  const useProduct = watch(
    `bookings.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.useProduct`,
    false
  )

  const productId = watch(
    `bookings.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.productId`
  )

  const quantity = watch(
    `bookings.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.quantity`,
    1
  )
  const unitPrice = watch(
    `bookings.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.unitPrice`,
    0
  )

  const total = useMemo(() => quantity * unitPrice, [quantity, unitPrice, productId])

  const getProduct = (productId: number) => {
    return products.find((product: any) => product.id === productId)
  }

  useEffect(() => {
    if (useProduct) {
      setValue(
        `bookings.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.countryOfOriginId`,
        getProduct(productId)?.countryOfOriginId
      )
      setValue(
        `bookings.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.unitPrice`,
        getProduct(productId)?.price
      )
      setValue(
        `bookings.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.productName`,
        getProduct(productId)?.name
      )
      setValue(
        `bookings.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.productDescription`,
        getProduct(productId)?.name
      )
    }
  }, [useProduct, productId, setValue])


  return (
    <>
      {ModalComponent}
      <Box
        sx={{
          border: '1px solid #ccc',
          padding: '20px 20px 20px 20px',
          marginTop: 2,
          borderRadius: 2,
        }}
      >
        <Grid container spacing={2}>
          {useProduct ? (
            <>
              <Grid item xs={12} md={3}>
                <FormSelect
                  name={`bookings.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.productId`}
                  label={formatMessage(messages.product)}
                  options={productOptions}
                  placeholder={formatMessage(commonMessages.select)}
                  actionLabel={
                    <Button
                      size='small'
                      onClick={() => {
                        removeProduct(productIndex)
                      }}
                      sx={{ textTransform: 'none', p: 0, mb: '6px', color: 'red' }}
                    >
                      {formatMessage(messages.removeProduct)}
                    </Button>
                  }
                  fullWidth
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12} md={3}>
              <FormInput
                name={`bookings.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.productDescription`}
                label={formatMessage(messages.productDescription)}
                placeholder={formatMessage(commonMessages.pleaseEnter)}
                actionLabel={
                  <Button
                    size='small'
                    onClick={() => {
                      removeProduct(productIndex)
                    }}
                    sx={{ textTransform: 'none', p: 0, mb: '6px', color: 'red' }}
                  >
                    {formatMessage(messages.removeProduct)}
                  </Button>
                }
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12} md={2}>
            <FormSelect
              name={`bookings.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.countryOfOriginId`}
              label={formatMessage(messages.countryOfOrigin)}
              options={convertToLabelValue(countries)}
              placeholder={formatMessage(commonMessages.select)}
              disabled={useProduct}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={1}>
            <FormInput
              name={`bookings.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.quantity`}
              label={formatMessage(messages.quantity)}
              type='number'
              numberFormatProps={{
                allowOnlyIntegers: true,
                minValue: 1,
              }}
              defaultValue={1}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <FormInput
              name={`bookings.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.unitPrice`}
              type='number'
              label={formatMessage(messages.unitPrice)}
              disabled={useProduct}
              numberFormatProps={{
                decimalScale: 2,
              }}
              unit={currencies.find((currency) => currency.id === currencyId)?.localisedSymbol}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <InfoDisplay
              label={formatMessage(messages.totalValue)}
              value={total}
              numberFormatProps={{
                decimalScale: 2,
                fixedDecimalScale: true,
              }}
              unit={currencies.find((currency) => currency.id === currencyId)?.localisedSymbol}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <Controller
                name={`bookings.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.useProduct`}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          setValue(
                            `bookings.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}`,
                            undefined
                          )
                          setValue(
                            `bookings.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.unitPrice`,
                            0
                          )
                          setValue(
                            `bookings.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.quantity`,
                            1
                          )
                          field.onChange(e)
                        }}
                      />
                    }
                    label={formatMessage(messages.selectProduct)}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ProductForm
