import AddIcon from '@mui/icons-material/Add'
import { AccordionDetails, Button, Grid } from '@mui/material'

import dayjs from 'dayjs'
import React, { useCallback, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useGetLocationsQuery } from '../../../app/redux-fetch/apiCoreQuery'
import {
  useGetCurrenciesByCustomerIdQuery,
} from '../../../app/redux-fetch/apiShipment'
import StyledAccordion from '../../../components/Accordions/StyledAccordion'
import StyledAccordionSummary from '../../../components/Accordions/StyledAccordionSummary'
import BoxWhiteContainer from '../../../components/Containers/BoxWhiteContainer'
import InfoDisplayItem from '../../../components/Typographies/InfoDisplayItem'
import { LabelItem } from '../../../components/Typographies/LabelItem'
import { removeKeys } from '../../utils'
import { useOrderFormContext } from '../context/OrderFormContext'
import PackagingItem from './PackagingItem'
import ConsignmentInfo from './ConsignmentInfo'
import messages from './messages'

interface PackagingFormProps {
  consignmentIndex: number
}

const PackagingForm: React.FC<PackagingFormProps> = ({ consignmentIndex }) => {
  const { control, watch } = useFormContext()
  const { append, remove } = useFieldArray({
    control,
    name: `bookings.${consignmentIndex}.packaging`,
  })

  const { formatMessage } = useIntl()

  const packaging = watch(`bookings.${consignmentIndex}.packaging`, [])

  const copyPackaging = (index: number) => {
    const packages = packaging[index]
    const load = removeKeys(packages, [
      'id',
      'loadDetailId',
      'physicalPropertiesId',
      'temperatureSettingId',
      'orderId',
      'bookingId',
      'consignmentId',
      'waypointId',
    ])
    append(load)
  }

  const [expanded, setExpanded] = useState(true)
  const { currentPage } = useOrderFormContext()

  const handleRemove = (index: number) => {
    remove(index)
  }

  const handleToggleAccordion = () => {
    setExpanded((prev) => !prev)
  }

  const renderPackagingItem = () => {
    return (
      <>
        <ConsignmentInfo consignmentIndex={consignmentIndex} />
        <StyledAccordion expanded={expanded} onChange={handleToggleAccordion}>
          <StyledAccordionSummary
            index={`packaging-content-${consignmentIndex}`}
            title={formatMessage(messages.loads)}
          />
          <AccordionDetails>
            {packaging.map((item: any, index: any) => (
              <PackagingItem
                key={item.id}
                consignmentIndex={consignmentIndex}
                packagingIndex={index}
                itemId={item.id}
                remove={handleRemove}
                copy={copyPackaging}
              />
            ))}
            {currentPage == 2 && (
              <Button
                variant='outlined'
                startIcon={<AddIcon />}
                onClick={() =>
                  append({
                    numberOfPackages: 1,
                    temperatureControlled: false,
                    useDeclaredValue: true,
                  })
                }
                sx={{ marginTop: 2 }}
              >
                {formatMessage(messages.addLoad)}
              </Button>
            )}
          </AccordionDetails>
        </StyledAccordion>
      </>
    )
  }
  return <>{renderPackagingItem()}</>
}

export default PackagingForm
