import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useGetAllLoadTypesQuery } from '../../app/redux-fetch/apiShipment';
import MultiSelectAutocomplete, { Option } from '../../components/FormFields/MultiSelectAutocomplete';
import { messages } from './messages';

const LoadTypesMultiSelect = () => {
    const { formatMessage } = useIntl();
    const { setValue, getValues } = useFormContext();

    const { data: apiLoadTypes = [] } = useGetAllLoadTypesQuery();

    const loadTypeOptions: Option[] = apiLoadTypes.map((lt: any) => ({
        id: lt.id,
        value: lt.name,
        label: lt.name
    }));

    const handleOptionChange = (selectedOptions: Option[]) => {

        const selectedLoadTypes = getValues('loadTypes')
        const id = getValues('id');
        const loadTypes = selectedOptions.map(option => ({
            name: option.label ? option.label : option.name ?? '',
            ...(option.id ? { id: option.id } : {}),
        }));

        const processedLoadTypes = loadTypes.map(loadType => {
            const existingLoadType = selectedLoadTypes?.find(
                (item: any) => item.name === loadType.name ||
                    (item.id && item.id === loadType.id) ||
                    (item.loadTypeId && item.loadTypeId === loadType.id)
            );

            if (existingLoadType) {
                return {
                    ...existingLoadType,
                    ...loadType,
                    id: existingLoadType.id,
                    loadTypeId: existingLoadType.loadTypeId || loadType.id
                };
            }
            else {
                return { name: loadType.name, loadTypeId: loadType.id, ...(id && { serviceId: id }) };
            }
        });

        setValue('loadTypes', processedLoadTypes);
    };



    return (
        <MultiSelectAutocomplete
            name="loadTypes"
            label={formatMessage(messages.loadTypes)}
            options={loadTypeOptions}
            placeholder={formatMessage(messages.selectOrAddLoadType)}
            onOptionChange={handleOptionChange}
        />
    );
};

export default LoadTypesMultiSelect;