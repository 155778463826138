import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  currencyTableTitle: {
    id: 'currency.currencyTableTitle',
    description: 'Currency Table Title',
    defaultMessage: 'Currencies',
  },
  currencyName: {
    id: 'currency.currencyName',
    description: 'Currency Name',
    defaultMessage: 'Currency Name',
  },
  iso3code: {
    id: 'currency.iso3code',
    description: 'ISO3 Code',
    defaultMessage: 'ISO3 Code',
  },
  enabled: {
    id: 'currency.enabled',
    description: 'Enabled',
    defaultMessage: 'Enabled',
  },
  localisedSymbol: {
    id: 'currency.localisedSymbol',
    description: 'Localised Symbol',
    defaultMessage: 'Localised Symbol',
  },
  symbol: {
    id: 'currency.symbol',
    description: 'Symbol',
    defaultMessage: 'Symbol',
  },
  newCurrencyBtn: {
    id: 'currency.newCurrencyBtn',
    description: 'New Currency Button',
    defaultMessage: 'Add',
  },
  viewBtn: {
    id: 'currency.viewBtn',
    description: 'View Button',
    defaultMessage: 'View',
  },
  editBtn: {
    id: 'currency.editBtn',
    description: 'Edit Button',
    defaultMessage: 'Edit',
  },
  inUse: {
    id: 'currency.inUse',
    description: 'In Use',
    defaultMessage: 'In Use',
  },
  enabledDisabled: {
    id: 'currency.enabledDisabled',
    description: 'Enable / Disable currency',
    defaultMessage: 'Enable / Disable',
  },
  createEditCurrency: {
    id: 'currency.createEditCurrency',
    description: 'Create / Edit Currency',
    defaultMessage: 'Create / Edit Currency',
  },
  cancelBtn: {
    id: 'currency.cancelBtn',
    description: 'Cancel Button',
    defaultMessage: 'Cancel',
  },
  currencyDeleted: {
    id: 'currency.currencyDeleted',
    description: 'Currency Deleted',
    defaultMessage: 'Currency Deleted',
  },
  currencyCreated: {
    id: 'currency.currencyCreated',
    description: 'Currency Created',
    defaultMessage: 'Currency Created',
  },
  currencyUpdated: {
    id: 'currency.currencyUpdated',
    description: 'Currency Updated',
    defaultMessage: 'Currency Updated',
  },
  currencyNotUpdated: {
    id: 'currency.currencyNotUpdated',
    description: 'Currency Not Updated',
    defaultMessage: 'Currency Not Updated',
  },
  currencyNotAdded: {
    id: 'currency.currencyNotAdded',
    description: 'Currency Not Added',
    defaultMessage: 'Currency Not Added',
  },
  currencyNotDeleted: {
    id: 'currency.currencyNotDeleted',
    description: 'Currency Not Deleted',
    defaultMessage: 'Currency Not Deleted',
  },
  customer: {
    id: 'currency.customer',
    description: 'Customer',
    defaultMessage: 'Customer',
  },
  clearSelection: {
    id: 'currency.clearSelection',
    description: 'Clear Selection',
    defaultMessage: 'Clear Selection',
  },
  activeCurrencyRequired: {
    id: 'currency.activeCurrencyRequired',
    description: 'Active Currency Required',
    defaultMessage: 'At least 1 currency must be active',
  },
})
