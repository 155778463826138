import { useAppSelector } from '../../../app/hooks'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import CustomerForm from './CustomerForm'

const CreateCustomer = () => {

    const { tenantId, tenants } =
        useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

    const currencyId = tenants?.find((t) => t.id === tenantId)?.currencyId

    return (
        <CustomerForm customer={{
            id: 0,
            documents: [],
            contacts: [],
            defaultCurrencyId: currencyId,
            disabled: false,
            address: { address1: '', address2: '', address3: '', city: '', state: '', postalCode: '' },
        }} isDisabled={false} />
    )
}

export default CreateCustomer