import { Box, Button } from '@mui/material';
import { cloneDeep } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { useAppSelector } from '../../../app/hooks';
import { useGetLocationsQuery } from '../../../app/redux-fetch/apiCoreQuery';
import { TableTitleTypography } from '../../../components/Typographies/TableTitleTypography';
import { IJourneyRoute } from '../../Transport/types';
import { fetchLoggedInUserSelector } from '../../selectors';
import { ILoggedInUser } from '../../types';
import { useJourneyFormContext } from '../hooks/useJourneyFormContext';
import useJourneyStepperContext from '../hooks/useJourneyStepperContext';
import { messages } from '../messages';
import Leg from './Leg';

const Legs = () => {
    const { formatMessage } = useIntl()
    const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)


    const { data: locations = [] } = useGetLocationsQuery({
        tenantId: tenantId ?? -1,
        includeCustomerLocations: false,
    })


    const { values, setFieldValue } = useJourneyFormContext()

    const { isMultiModalJourney, originId, destinationId, mode } = useJourneyStepperContext()

    useEffect(() => {
        if (values.legs.length === 0 && locations.length > 0) {
            handleAddTransportLeg()
        }
    }, [locations])

    const handleAddTransportLeg = () => {
        const currentLegs = values.legs
        const selectedLoads = values.loads
        const newRoutes: IJourneyRoute[] = []
        const routeId = uuidv4()

        const isMixed =
            selectedLoads ?
                selectedLoads.some((sl) => sl.isDefined) && selectedLoads.some((sl) => !sl.isDefined) : false

        if (isMixed) {
            enqueueSnackbar(formatMessage(messages.cannotCombineLoadsAndAssets), {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            })
            return
        }

        if (selectedLoads && selectedLoads.length > 0 && currentLegs.length === 0) {
            selectedLoads.forEach((x) => {
                if (x.pickupLocationId && x.consigneeId) {
                    let newRoute = newRoutes.find(
                        (nr) =>
                            nr.destinations.some((l) => l.locationId === x.pickupLocationId) &&
                            nr.destinations.some((l) => l.locationId === x.consigneeId)
                    )
                    if (!newRoute) {
                        const currStartLocation = locations.find((loc) => loc.id === x.pickupLocationId)
                        const currEndLocation = locations.find((loc) => loc.id === x.consigneeId)
                        newRoute = {
                            id: routeId,
                            loads: [
                                {
                                    id: x.id,
                                    pickupRouteLocationId: x.pickupLocationId,
                                    dropoffRouteLocationId: x.consigneeId,
                                    name: x.description,
                                    pickupLocationId: x.pickupLocationId,
                                    consigneeId: x.consigneeId,
                                    isDefined: x.isDefined,
                                    pickupNotifyPartyId: currStartLocation?.contacts?.find(
                                        (c) => c.id === x.pickupNotifyPartyId
                                    )?.id,
                                    deliveryNotifyPartyId: currEndLocation?.contacts?.find(
                                        (c) => c.id === x.deliveryNotifyPartyId
                                    )?.id,
                                },
                            ],
                            destinations: [
                                {
                                    name: currStartLocation?.name ?? '',
                                    sequence: 1,
                                    contactId: x.pickupNotifyPartyId,
                                    locationId: currStartLocation?.id ?? -1,
                                    scheduledDate: new Date(),
                                },
                                {
                                    name: currEndLocation?.name ?? '',
                                    sequence: 2,
                                    contactId: x.dropOffNotifyPartyId,
                                    locationId: currEndLocation?.id ?? -1,
                                    scheduledDate: new Date(),
                                },
                            ],
                        }
                        newRoutes.push(newRoute)
                    } else {
                        newRoute.loads = [
                            ...newRoute.loads,
                            {
                                id: x.id,
                                pickupRouteLocationId: x.pickupLocationId,
                                dropoffRouteLocationId: x.consigneeId,
                                name: x.description,
                                pickupLocationId: x.pickupLocationId,
                                consigneeId: x.consigneeId,
                                isDefined: x.isDefined,
                            },
                        ]
                    }
                }
            })
        }

        const newLegLeg = []

        if (isMultiModalJourney) {
            const startLocation = locations.find((loc) => loc.id === originId)
            const endLocation = locations.find((loc) => loc.id === destinationId)
            const intermediateRoute: IJourneyRoute = {
                id: uuidv4(),
                loads: selectedLoads.map((x) => {
                    return {
                        id: x.id,
                        pickupRouteLocationId: x.pickupLocationId,
                        dropoffRouteLocationId: x.consigneeId,
                        name: x.description,
                        pickupLocationId: x.pickupLocationId,
                        consigneeId: x.consigneeId,
                        isDefined: x.isDefined,
                    }
                }),
                destinations: [
                    {
                        locationId: startLocation?.id ?? -1,
                        sequence: 1,
                        name: startLocation?.name ?? '',
                        scheduledDate: new Date(),
                    },
                    {
                        locationId: endLocation?.id ?? -1,
                        sequence: 2,
                        name: endLocation?.name ?? '',
                        scheduledDate: new Date(),
                    },
                ],
            }
            const startLeg = {
                routes: cloneDeep(newRoutes),
            }
            const endLeg = {
                routes: cloneDeep(newRoutes),
            }
            const intermediateLeg = {
                routes: [intermediateRoute],
            }
            startLeg.routes.forEach((r) => {
                r.loads.forEach((l) => {
                    l.dropoffRouteLocationId = startLocation?.id ?? -1
                })
                r.destinations[1].locationId = startLocation?.id ?? -1
                r.destinations[1].name = startLocation?.name ?? ''
            })
            intermediateLeg.routes.forEach((r) => {
                r.loads.forEach((l) => {
                    l.dropoffRouteLocationId = endLocation?.id ?? -1
                    l.pickupRouteLocationId = startLocation?.id ?? -1
                })
                r.destinations[0].locationId = startLocation?.id ?? -1
                r.destinations[0].name = startLocation?.name ?? ''
                r.destinations[1].locationId = endLocation?.id ?? -1
                r.destinations[1].name = endLocation?.name ?? ''
            })
            endLeg.routes.forEach((r) => {
                r.loads.forEach((l) => {
                    l.pickupRouteLocationId = endLocation?.id ?? -1
                })
                r.destinations[0].locationId = endLocation?.id ?? -1
                r.destinations[0].name = endLocation?.name ?? ''
            })
            newLegLeg.push(startLeg)
            newLegLeg.push(intermediateLeg)
            newLegLeg.push(endLeg)
        } else {
            newLegLeg.push({
                routes: newRoutes,
            })
        }
        if (selectedLoads && selectedLoads.length !== 0 || confirm(formatMessage(messages.noSelectedLoadsWarning))) {
            setFieldValue('legs', [...currentLegs, ...newLegLeg])
        }
    }


    return (
        <Box sx={{
            display: 'flex',
            p: '16px',
            flexDirection: 'row',
            flexWrap: 'wrap',
        }}>
            <Box sx={{
                justifyContent: 'space-between',
                width: '100%',
                display: 'flex',
            }}>
                <TableTitleTypography>{formatMessage(messages.legsName)}</TableTitleTypography>
                <Box>
                    <Button color='secondary' variant='contained' onClick={handleAddTransportLeg} disabled={mode === 'view'} >
                        {formatMessage(messages.addLeg)}
                    </Button>
                </Box>
            </Box>
            {values.legs.map((leg, index) => {
                return (
                    <Box key={index} sx={{
                        width: '100%',
                    }}>
                        <Leg leg={leg} legIdx={index} />
                    </Box>
                )
            })}
        </Box>
    )
}

export default Legs