import { ListItemButton, styled } from '@mui/material'
import { CustomListItemProps } from './types'

export const StyledListItemButton = styled(ListItemButton)<CustomListItemProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 8px 14px 8px;
  width: 97px;
  height: 80px;
  background-color: ${(props) =>
    props.isSelected ? props.theme.palette.secondary.main : 'transparent'};
  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.main || 'inherit'};
  }
  color: ${(props) => props.theme.palette.primary.contrastText || 'inherit'};
`

export const StyledSubCategoryListItemButton = styled(ListItemButton)<CustomListItemProps>`
  background-color: ${(props) => (props.isSelected ? props.theme.palette.primary.main : 'inherit')};
  color: ${(props) =>
    props.isSelected
      ? props.theme.palette.primary.contrastText
      : props.theme.palette.secondary.main};
  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.main || 'inherit'};
    color: ${(props) => props.theme.palette.primary.contrastText || 'inherit'};
  }
  border-radius: 4px;

  .MuiTypography-root {
    font-weight: ${(props) => (props.isSelected ? 700 : 700)};
  }
`
