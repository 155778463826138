import { Autocomplete, Box, Chip, TextField, TextFieldProps, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export interface Option {
    id?: number | string;
    value: string | number;
    name?: string;
    label: string;
}

interface MultiSelectAutocompleteProps extends Omit<TextFieldProps, 'onChange' | 'value'> {
    name: string;
    label: string;
    options: Option[];
    getOptionLabel?: (option: Option) => string;
    freeSolo?: boolean;
    placeholder?: string;
    required?: boolean;
    helperText?: string;
    onOptionChange?: (options: Option[]) => void;
}

const MultiSelectAutocomplete = ({
    name,
    label,
    options,
    getOptionLabel = (option) => {
        return option.label || option.name || String(option.value)
    },
    freeSolo = false,
    placeholder = '',
    required = false,
    helperText,
    onOptionChange,
    ...textFieldProps
}: MultiSelectAutocompleteProps) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => (
                <Box>
                    <Typography variant="subtitle1" sx={{ mb: '4px' }}>
                        {label} {required && <span style={{ color: 'red' }}>*</span>}
                    </Typography>
                    <Autocomplete
                        {...field}
                        multiple
                        freeSolo={freeSolo}
                        id={`multi-select-${name}`}
                        options={options}
                        isOptionEqualToValue={(option, value) => {
                            if (option.id && value.id) {
                                return option.id === value.id;
                            }
                            if (option.value && value.value) {
                                return option.value === value.value;
                            }
                            if (typeof option === 'string' && typeof value === 'string') {
                                return option === value;
                            }
                            return getOptionLabel(option) === getOptionLabel(value);
                        }}
                        getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                                return option;
                            }
                            return getOptionLabel(option);
                        }}
                        value={field.value || []}
                        onChange={(_, newValue) => {
                            const processedValue = freeSolo

                                ? newValue.map(item =>
                                    typeof item === 'string'
                                        ? { label: item, value: item }
                                        : item
                                )
                                : newValue;

                            if (onOptionChange) {
                                onOptionChange(processedValue as Option[]);
                            } else {
                                field.onChange(processedValue);
                            }
                        }}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                // eslint-disable-next-line react/jsx-key
                                <Chip
                                    variant="outlined"
                                    label={typeof option === 'string' ? option : getOptionLabel(option)}
                                    size="small"
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                {...textFieldProps}
                                placeholder={placeholder}
                                error={!!fieldState.error}
                                helperText={fieldState.error ? fieldState.error.message : helperText}
                                fullWidth
                            />
                        )}
                    />
                </Box>
            )}
        />
    );
};

export default MultiSelectAutocomplete;
