import CancelIcon from '@mui/icons-material/CancelOutlined';
import { Box, Button, Link } from '@mui/material';
import {
    MRT_ColumnDef as MRTColumnDef
} from 'material-react-table';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLoggedInUser } from '../../app/hooks/useLoggedInUser';
import { useNotificationStack } from '../../app/hooks/useNotificationStack';
import { useGetCustomersQuery } from '../../app/redux-fetch/apiQuery';
import { useDeleteCurrencyByIdMutation, useGetCurrenciesByCustomerIdQuery, useGetCurrenciesByTenantIdQuery } from '../../app/redux-fetch/apiShipment';
import { IDropDownItem } from '../../app/types';
import AddButton from '../../components/Buttons/AddButton';
import ConditionalRender from '../../components/ConditionalRender/ConditionalRender';
import FormDropdown from '../../components/FormDropDown/FormDropdown';
import MRTDataGridv2 from '../../components/MRTDataGrid/MRTDataGridv2';
import { TableTitleTypography } from '../../components/Typographies/TableTitleTypography';
import YesNoTypography from '../../components/YesNoTypograpphy/YesNoTypography';
import CurrencyForm from './CurrencyForm';
import { messages } from './messages';
import { ICurrency } from './types';

const CurrencyTable = () => {

    const { formatMessage } = useIntl()

    const { tenantId, customerId, isTenantUser } = useLoggedInUser()

    const [selectedCurrencyId, setSelectedCurrencyId] = useState(-1)
    const [createOpen, setCreateOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [deleteCurrency] = useDeleteCurrencyByIdMutation()

    const [customer, setCustomerId] = useState(customerId)

    const { enqueueSuccess, enqueueFailure } = useNotificationStack()

    const getCurrenciesQuery = useCallback(() => {
        if (customer) {
            return useGetCurrenciesByCustomerIdQuery({ customerId: customer, hideDisabled: false });
        }

        return useGetCurrenciesByTenantIdQuery({
            tenantId: +tenantId,
            hideDisabled: false
        });


    }, [customer, tenantId]);

    const { data: currencies = [], isFetching: currenciesLoading, isSuccess: currenciesLoaded } = getCurrenciesQuery();

    const { data: customers = [] } = useGetCustomersQuery(tenantId ?? -1)


    const getCurrency = useCallback(() => {
        if (customer) {
            return currencies
        }
        else {
            return currencies.filter((currency) => !currency.customerId)
        }
    }, [customer, currencies])

    const columns: MRTColumnDef<ICurrency>[] = [
        {
            accessorKey: 'name',
            header: formatMessage(messages.currencyName),
        },
        {
            accessorKey: 'iso3',
            header: formatMessage(messages.iso3code)
        },
        {
            accessorKey: 'localisedSymbol',
            header: formatMessage(messages.localisedSymbol),

        },
        {
            accessorFn: (row) => {
                return row.enabled ? 'true' : 'false'
            },
            filterVariant: 'checkbox',
            header: formatMessage(messages.enabled),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ cell }: any) => {
                return <YesNoTypography isYes={cell.getValue() === 'true'} />
            },
        },
        {
            accessorFn: (row) => {
                return row.inUse ? 'true' : 'false'
            },
            filterVariant: 'checkbox',
            header: formatMessage(messages.inUse),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ cell }: any) => {
                return <YesNoTypography isYes={cell.getValue() === 'true'} />
            }
        }
    ]
    return (
        <>
            <ConditionalRender condition={createOpen} >
                <CurrencyForm
                    customerId={customer}
                    currency={{
                        tenantId: +tenantId, customerId: customerId, id: 0, name: '', currency: '', iso3: '', symbol: '', localisedSymbol: '', enabled: true, inUse: true
                    }}
                    handleClose={() => setCreateOpen(false)}
                    isOpen={createOpen}
                    enabledCurrencies={2}
                />
            </ConditionalRender>
            <ConditionalRender condition={editOpen} >
                <CurrencyForm
                    customerId={customer}
                    currency={currencies.find(c => c.id === selectedCurrencyId) ?? {
                        tenantId: +tenantId, customerId: customerId, id: 0, name: '', currency: '', iso3: '', symbol: '', localisedSymbol: '', enabled: true, inUse: true
                    }} handleClose={
                        () => setEditOpen(false)
                    }
                    isOpen={editOpen}
                    enabledCurrencies={currencies.filter(c => c.enabled).length}
                />
            </ConditionalRender>
            <Box>
                <TableTitleTypography>{formatMessage(messages.currencyTableTitle)}</TableTitleTypography>
            </Box>
            <MRTDataGridv2
                initialState={{
                    pagination: { pageSize: 100, pageIndex: 0 },
                    density: 'comfortable',
                    sorting: [
                        {
                            id: 'name',
                            desc: false,
                        },
                    ],
                }}
                leftHeadingComponent={
                    <ConditionalRender condition={isTenantUser}>
                        <Box>
                            <Box display="flex" justifyContent="flex-end" alignItems="center">
                                <Button
                                    size='small'
                                    onClick={() => {
                                        setCustomerId(undefined)
                                    }}
                                    sx={{ textTransform: 'none', p: 0 }}
                                >
                                    {formatMessage(messages.clearSelection)}
                                </Button>
                            </Box>
                            <FormDropdown
                                id='customerId'
                                items={customers as IDropDownItem[]}
                                onChange={(e: any, name: any, newValue: any) => {
                                    setCustomerId && setCustomerId(newValue)
                                }}
                                sx={{ width: '400px', mt: '12px' }}
                                value={customer}
                            />
                        </Box>
                    </ConditionalRender>
                }
                rightHeadingComponent={
                    <AddButton
                        text={formatMessage(messages.newCurrencyBtn)}
                        onClick={() => setCreateOpen(true)}
                    />
                }
                state={{
                    isLoading: currenciesLoading || !currenciesLoaded,
                }}
                columns={columns}
                data={getCurrency() || []}
                getRowId={(row) => row.id as any}
                enableEditing
                enableRowActions
                positionActionsColumn='last'
                renderRowActions={({ row, table }: any) => {
                    return (
                        <>
                            <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                                <Link
                                    underline='none'
                                    color='secondary'
                                    onClick={() => {
                                        setSelectedCurrencyId(row.id)
                                        setEditOpen(true)
                                    }}
                                    sx={{
                                        fontWeight: 700,
                                        cursor: 'pointer',
                                    }}
                                >
                                    {formatMessage(messages.editBtn)}
                                </Link>
                                <ConditionalRender condition={row.original.inUse == false}>
                                    <CancelIcon
                                        color='secondary'
                                        sx={{
                                            cursor: 'pointer',
                                            pl: '4px',
                                        }}
                                        onClick={() => {
                                            deleteCurrency({ currencyId: row.id })
                                                .then(() => {
                                                    enqueueSuccess(messages.currencyDeleted)
                                                })
                                                .catch(() => {
                                                    enqueueFailure(messages.currencyNotDeleted)
                                                })
                                        }}
                                    />
                                </ConditionalRender>
                            </Box>
                        </>
                    )
                }}
            />
        </>
    )
}

export default CurrencyTable;


