import { Box } from '@mui/material'
import { useIntl } from 'react-intl'
import { useLoggedInUser } from '../../../app/hooks/useLoggedInUser'
import TabWrapper, { TabItem } from '../../../components/TabWrapper/TabWrapper'
import { LocationType } from '../../../types/common'
import TrackingNumber from '../../CommonSettings/TrackingNumber/TrackingNumber'
import LocationTableWrapper from '../../Locations/LocationTableWrapper'
import { messages } from './messages'

const ShippingTabs = () => {
  const { formatMessage } = useIntl()
  const { isTenantUser } = useLoggedInUser()

  const tabs: TabItem[] = [
    {
      label: formatMessage(messages.shipper),
      content: (
        <LocationTableWrapper
          locationTypeId={LocationType.Shipper}
          title={formatMessage(messages.shipper)} />
      )
    },
    ...(isTenantUser ? [{
      label: formatMessage(messages.trackingNumber),
      content: (
        <Box sx={{ width: '100%' }}>
          <TrackingNumber />
        </Box>
      )
    }] : [])
  ]

  return <TabWrapper tabs={tabs} variant="standard" />
}

export default ShippingTabs
