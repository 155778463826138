import * as yup from 'yup'
import messages from '../messages'

const getAddressSchema = (formatMessage: any) => {
  const addressSchema = yup.object({
    address1: yup.string().required(formatMessage(messages.address1isRequired)),
    address2: yup.string(),
    city: yup.string().required(formatMessage(messages.cityIsRequired)),
    countryId: yup.number().required(formatMessage(messages.countryIsRequired)),
  })
  return addressSchema
}

export const getCustomerValidationSchema = (formatMessage: any) =>
  yup.object({
    id: yup.number().notRequired(),
    name: yup.string().required(formatMessage(messages.customerNameRequired)),
    address: getAddressSchema(formatMessage).required(formatMessage(messages.addressIsRequired)),
    defaultCurrencyId: yup.number().required(formatMessage(messages.defaultCurrencyIsRequired)),
    email: yup.string().email().notRequired(),
    secondaryEmail: yup.string().email().notRequired(),
    accountOwnerId: yup
      .number()
      .notRequired()
      .when('id', {
        is: (id: any) => id === 0,
        then: yup.number().required(formatMessage(messages.accountOwnerIsRequired)),
      }),
  })
