import * as yup from 'yup'
import { LocationType } from '../../types/common'
import { getContactsValidationSchema } from '../Contacts/contactValidation'
import messages from './messages'

export const getLocationValidationSchema = (formatMessage: any) => {
  return yup
    .object({
      name: yup.string().required(formatMessage(messages.nameValidation)),
      customerId: yup.string().when('locationTypeId', {
        is: LocationType.Shipper,
        then: (schema) => schema.required(formatMessage(messages.customerValidation)),
        otherwise: (schema) => schema.nullable(),
      }),
      address: yup
        .object({
          countryId: yup.string().required(formatMessage(messages.countryValidation)),
          address1: yup.string().required(formatMessage(messages.address1Validation)),
          city: yup.string().required(formatMessage(messages.cityValidation)),
          state: yup.string().required(formatMessage(messages.stateValidation)),
          postalCode: yup.string().required(formatMessage(messages.postCodeValidation)),
        })
        .nullable(),
      lat: yup.string().when('locationTypeId', {
        is: (locationTypeId?: number) => locationTypeId !== LocationType.Shipper,
        then: (schema) => schema.min(-90).max(90).required(formatMessage(messages.latValidation)),
        otherwise: (schema) => schema.nullable(),
      }),
      lng: yup.string().when('locationTypeId', {
        is: (locationTypeId?: number) => locationTypeId !== LocationType.Shipper,
        then: (schema) => schema.min(-180).max(180).required(formatMessage(messages.lngValidation)),
        otherwise: (schema) => schema.nullable(),
      }),
    })
    .concat(getContactsValidationSchema(formatMessage))
}
