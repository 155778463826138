/* eslint-disable */
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  addLocation: {
    id: 'locations.addLocation',
    description: 'Create new location',
    defaultMessage: 'Create new location',
  },
  editLocation: {
    id: 'locations.editLocation',
    description: 'Edit location',
    defaultMessage: 'Edit location',
  },
  mainInfo: {
    id: 'locations.mainInfo',
    description: 'Main Information Label',
    defaultMessage: 'Main Information',
  },
  addressDetails: {
    id: 'locations.addressDetails',
    description: 'Address details label',
    defaultMessage: 'Address Details',
  },
  defaultValues: {
    id: 'locations.defaultValues',
    description: 'Default Values Label',
    defaultMessage: 'Default Values',
  },
  location: {
    id: 'locations.location',
    description: 'Location',
    defaultMessage: 'Location',
  },
  backToLocationstBtn: {
    id: 'locations.backToLocationstBtn',
    description: 'Back To Locations',
    defaultMessage: 'Back To Locations',
  },
  sendCoordinates: {
    id: 'locations.sendCoordinates',
    description: 'Send Coordinates',
    defaultMessage: 'Send Coordinates',
  },
  deletePolygon: {
    id: 'locations.deletePolygon',
    description: 'Delete Selected Zone',
    defaultMessage: 'Delete Selected Zone',
  },
  deleteMarker: {
    id: 'locations.deleteMarker',
    description: 'Delete Marker',
    defaultMessage: 'Delete Marker',
  },
  transportMode: {
    id: 'locations.transportMode',
    description: 'Default Transport Mode',
    defaultMessage: 'Default Transport Mode',
  },
  country: {
    id: 'locations.country',
    description: 'Country',
    defaultMessage: 'Country',
  },
  name: {
    id: 'locations.name',
    description: 'Name',
    defaultMessage: 'Name',
  },
  parentLocation: {
    id: 'locations.parentLocation',
    description: 'Parent Location',
    defaultMessage: 'Parent Location',
  },
  customerLabel: {
    id: 'locations.customerLabel',
    description: 'Customer Label',
    defaultMessage: 'Customer',
  },
  customerOptionalLabel: {
    id: 'locations.customerOptionalLabel',
    description: 'Customer Optional Label',
    defaultMessage: 'Select if this is a customer location',
  },
  locationType: {
    id: 'locations.locationType',
    description: 'Location Type',
    defaultMessage: 'Location Type',
  },
  address: {
    id: 'locations.address',
    description: 'Address ',
    defaultMessage: 'Address ',
  },
  address1: {
    id: 'locations.address1',
    description: 'Address 1',
    defaultMessage: 'Address 1',
  },
  address2: {
    id: 'locations.address2',
    description: 'Address 2',
    defaultMessage: 'Address 2',
  },
  address3: {
    id: 'locations.address3',
    description: 'Address 3',
    defaultMessage: 'Address 3',
  },
  city: {
    id: 'locations.city',
    description: 'City',
    defaultMessage: 'City',
  },
  state: {
    id: 'locations.state',
    description: 'State',
    defaultMessage: 'State',
  },
  postCode: {
    id: 'locations.postCode',
    description: 'Postal Code',
    defaultMessage: 'Postal Code',
  },
  lat: {
    id: 'locations.lat',
    description: 'Latitude',
    defaultMessage: 'Latitude',
  },
  lng: {
    id: 'locations.lng',
    description: 'Longitude',
    defaultMessage: 'Longitude',
  },
  create: {
    id: 'locations.create',
    description: 'Create button',
    defaultMessage: 'Create',
  },
  update: {
    id: 'locations.update',
    description: 'Update',
    defaultMessage: 'Update',
  },
  cancel: {
    id: 'locations.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel',
  },
  delete: {
    id: 'locations.delete',
    description: 'Delete button label',
    defaultMessage: 'Delete',
  },
  countryValidation: {
    id: 'locations.countryValidation',
    description: 'Country is required',
    defaultMessage: 'Country is required',
  },
  nameValidation: {
    id: 'locations.nameValidation',
    description: 'Name is required',
    defaultMessage: 'Name is required',
  },
  address1Validation: {
    id: 'locations.address1Validation',
    description: 'Address is required',
    defaultMessage: 'Address is required',
  },
  cityValidation: {
    id: 'locations.cityValidation',
    description: 'City is required',
    defaultMessage: 'City is required',
  },
  stateValidation: {
    id: 'locations.stateValidation',
    description: 'State is required',
    defaultMessage: 'State is required',
  },
  postCodeValidation: {
    id: 'locations.postCodeValidation',
    description: 'Postal Code is required',
    defaultMessage: 'Postal Code is required',
  },
  createLocationTitle: {
    id: 'locations.createLocationTitle',
    description: 'Create Location Modal title',
    defaultMessage: 'Create {locationType}',
  },
  viewButtonLbl: {
    id: 'locations.viewButtonLbl',
    description: 'View link label',
    defaultMessage: 'View',
  },
  editButtonLbl: {
    id: 'locations.editButtonLbl',
    description: 'Edit link label',
    defaultMessage: 'Edit',
  },
  customerSelectable: {
    id: 'locations.customerSelectable',
    description: 'Customer Selectable',
    defaultMessage: 'Customer Selectable',
  },
  clearSelection: {
    id: 'locations.clearSelection',
    description: 'Clear Selection',
    defaultMessage: 'Clear Selection',
  },
  standardLocations: {
    id: 'locations.tabs.standardLocations',
    description: 'Standard Locations',
    defaultMessage: 'Standard Locations',
  },
  newStandardLocation: {
    id: 'locations.tabs.newStandardLocation',
    description: 'New Standard Location',
    defaultMessage: 'New Standard Location',
  },
  airports: {
    id: 'locations.tabs.airports',
    description: 'Airports',
    defaultMessage: 'Airports',
  },
  airport: {
    id: 'locations.tabs.airport',
    description: 'Airport',
    defaultMessage: 'Airport',
  },
  railwayStations: {
    id: 'locations.tabs.railwayStations',
    description: 'Railway Stations',
    defaultMessage: 'Railway Stations',
  },
  railwayStation: {
    id: 'locations.tabs.railwayStation',
    description: 'Railway Station',
    defaultMessage: 'Railway Station',
  },
  fuelStations: {
    id: 'locations.tabs.fuelStations',
    description: 'Fuel Stations',
    defaultMessage: 'Fuel Stations',
  },
  fuelStation: {
    id: 'locations.tabs.newFuelStation',
    description: 'Fuel Station',
    defaultMessage: 'Fuel Station',
  },
  terminals: {
    id: 'locations.tabs.terminals',
    description: 'Terminals',
    defaultMessage: 'Terminals',
  },
  terminal: {
    id: 'locations.tabs.terminal',
    description: 'Terminal',
    defaultMessage: 'Terminal',
  },
  defaultTransportModeValidation: {
    id: 'locations.defaultTransportModeValidation',
    description: 'Default Transport Mode is required',
    defaultMessage: 'Transport Mode is required',
  },
  latValidation: {
    id: 'locations.latValidation',
    description: 'Latitude is required',
    defaultMessage: 'Latitude is required',
  },
  lngValidation: {
    id: 'locations.lngValidation',
    description: 'Longitude is required',
    defaultMessage: 'Longitude is required',
  },
  seaports: {
    id: 'locations.tabs.seaports',
    description: 'Seaports',
    defaultMessage: 'Seaports',
  },
  seaport: {
    id: 'locations.tabs.seaport',
    description: 'Seaport',
    defaultMessage: 'Seaport',
  },
  shipper: {
    id: 'locations.tabs.shipper',
    description: 'Shipper',
    defaultMessage: 'Shipper',
  },
  customerValidation: {
    id: 'locations.customerValidation',
    description: 'Customer is required',
    defaultMessage: 'Customer is required',
  },
  systemDefined: {
    id: 'locations.systemDefined',
    description: 'System Defined',
    defaultMessage: 'System Defined',
  },
  createNewShipper: {
    id: 'locations.createNewShipper',
    description: 'Create New Shipper button',
    defaultMessage: 'New Shipper',
  },
  createNewFuelStation: {
    id: 'locations.createNewFuelStation',
    description: 'Create New Fuel Station button',
    defaultMessage: 'New Fuel Station',
  },
  createNewTerminal: {
    id: 'locations.createNewTerminal',
    description: 'Create New Terminal',
    defaultMessage: 'New Terminal',
  },
  createNewAirport: {
    id: 'locations.createNewAirport',
    description: 'Create New Airport',
    defaultMessage: 'New Airport',
  },
  createNewSeaport: {
    id: 'locations.createNewSeaport',
    description: 'Create New Seaport location button',
    defaultMessage: 'New Seaport',
  },
  standard: {
    id: 'locations.standard',
    description: 'Standard location button',
    defaultMessage: 'Standard Location',
  },
  newLocationTitle: {
    id: 'locations.newLocationTitle',
    description: 'New Location',
    defaultMessage: 'New {locationType}',
  },
  editLocationTitle: {
    id: 'locations.editLocationTitle',
    description: 'Edit Location',
    defaultMessage: 'Edit {locationType}',
  },
  viewLocationTitle: {
    id: 'locations.viewLocationTitle',
    description: 'View Location',
    defaultMessage: 'View {locationType}',
  },
})

export default messages
