import { Box, Button, FormControlLabel, Switch } from '@mui/material'
import { FormikErrors, setNestedObjectValues } from 'formik'
import { useIntl } from 'react-intl'
import { useFormContext } from '../../app/hooks/useFormContext'
import { useSideModalContext } from '../../features/AssetsManagement/modals/SideModalContext'
import messages from './messages'

type SubmitButtonProps = {
  disabled?: boolean
  create: boolean
  delete?: boolean
  isEnabled?: boolean
}

const SubmitButtons = ({
  delete: isDelete,
  disabled = false,
  create,
  isEnabled,
}: SubmitButtonProps) => {
  const { formatMessage } = useIntl()

  const { onClose, onDelete } = useSideModalContext()
  const { submitForm, validateForm, setTouched, setFieldValue, values, isSubmitting } = useFormContext();

  const handleSubmit = () => {
    validateForm().then((errors: FormikErrors<any>) => {
      submitForm().finally(() => {
        setTouched(setNestedObjectValues(errors, true))
      })
    })
  }

  return !disabled ? (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', zIndex: 9999 }}>
      {isDelete && (
        <Button color='secondary' variant='contained' onClick={onDelete} disabled={isSubmitting}>
          {formatMessage(messages.deleteBtn)}
        </Button>
      )}
      <Button color='secondary' variant='contained' onClick={handleSubmit} disabled={isSubmitting}>
        {create ? `${formatMessage(messages.createBtn)}` : `${formatMessage(messages.updateBtn)}`}
      </Button>
      <Button color='secondary' variant='outlined' onClick={onClose} disabled={isSubmitting}>
        {formatMessage(messages.cancelBtn)}
      </Button>
      {isEnabled && (<FormControlLabel
        sx={{ justifyContent: 'start', minHeight: '56px' }}
        value={values.active}
        labelPlacement='start'
        control={
          <Switch
            color='secondary'
            checked={!!values.active || !values.disabled || !!values.enabled}
            onChange={({ target }) => {
              if (values.disabled !== undefined) {
                setFieldValue('disabled', !target.checked)
              }
              if (values.active !== undefined) {
                setFieldValue('active', target.checked)
              }
              if (values.enabled !== undefined) {
                setFieldValue('enabled', target.checked)
              }
            }}
            disabled={disabled}
          />
        }
        label={`${formatMessage(messages.enabled)}`}
        disabled={disabled}
      />)}
    </Box>
  ) : (
    <></>
  )
}

export default SubmitButtons
