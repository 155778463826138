import { Box, FormControlLabel, Grid, Radio, RadioGroup, Switch } from '@mui/material'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useGetAllTransportModesQuery } from '../../../app/redux-fetch/apiCoreQuery'
import { useGetCarriersQuery } from '../../../app/redux-fetch/apiShipment'
import { convertToLabelValue } from '../../../app/utils'
import FormInput from '../../../components/Forms/FormInput'
import FormSelect from '../../../components/Forms/FormSelect'
import { InputHeader } from '../../../components/Headers/styles'
import messages from './messages'
import { IShipmentFormInput } from './schema'

interface TransitDetailsFormProps {
  namePrefix: string
}

const TransitDetailsForm: React.FC<TransitDetailsFormProps> = ({ namePrefix }) => {

  const { control, setValue, watch } = useFormContext()
  const { formatMessage } = useIntl()

  const carrierOption = useWatch({
    name: `${namePrefix}.carrierOption`
  })

  const tenantId = watch('tenantId')
  const transportModeId = watch(`${namePrefix}.modeOfTransit`)

  const { data: carries = [] } = useGetCarriersQuery({ tenantId }, { skip: !tenantId })

  const carrierOptions = useMemo(() => {
    return convertToLabelValue(transportModeId || transportModeId !== 0 ?
      carries.filter(carrier => carrier.transportModeId === transportModeId) :
      carries)
  }, [carries, transportModeId])

  const { data: transportModes = [] } = useGetAllTransportModesQuery()

  const transportModeOptions = useMemo(() => {
    const result = transportModes.map(mode => mode.id === 0 ? { ...mode, id: -1 } : mode)
    return convertToLabelValue(result)
  }, [transportModes])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormSelect
            name={`${namePrefix}.modeOfTransit`}
            label={formatMessage(messages.modeOfTransit)}
            options={transportModeOptions}
            onChange={() => {
              setValue(`${namePrefix}.carrierId`, undefined)
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={8} container justifyContent='center' alignItems='center'>
          <Box mb={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <InputHeader gutterBottom>{formatMessage(messages.carrierPreference)}</InputHeader>
            </Box>
            <Box>
              <Controller
                name={`${namePrefix}.carrierOption` as keyof IShipmentFormInput}
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    onChange={(e) => {
                      field.onChange(e)
                      const value = e.target.value

                      if (value === 'noPreference') {
                        setValue(`${namePrefix}.carrierId`, undefined)
                        setValue(`${namePrefix}.transitIdentifier`, '')
                      } else if (value === 'preferredCarrier') {
                        setValue(`${namePrefix}.transitIdentifier`, '')
                      }
                    }}
                  >
                    <FormControlLabel
                      value='noPreference'
                      control={<Radio />}
                      label={formatMessage(messages.noPreference)}
                    />
                    <FormControlLabel
                      value='preferredCarrier'
                      control={<Radio />}
                      label={formatMessage(messages.preferredCarrier)}
                    />
                    <FormControlLabel
                      value='preBookedTransit'
                      control={<Radio />}
                      label={formatMessage(messages.preBookedTransit)}
                    />
                  </RadioGroup>
                )}
              />
            </Box>
          </Box>
        </Grid>
        {(carrierOption === 'preferredCarrier' || carrierOption === 'preBookedTransit') && (
          <Grid item container xs={12} md={12} spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={12} md={8}>
              <FormSelect
                name={`${namePrefix}.carrierId`}
                options={carrierOptions}
                label={formatMessage(messages.carrier)}
                placeholder={formatMessage(messages.enterCarrier)}
                fullWidth
              />
            </Grid>
            {carrierOption === 'preBookedTransit' && (
              <Grid item xs={12} md={4}>
                <FormInput
                  name={`${namePrefix}.transitIdentifier`}
                  label={formatMessage(messages.transitIdentifier)}
                  placeholder={formatMessage(messages.enterTransitIdentifier)}
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
        )}
        <Grid item xs={12} md={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              height: '100%',
            }}
          >
            <Controller
              name={`${namePrefix}.crossBorder` as keyof IShipmentFormInput}
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Switch {...field} checked={Boolean(field.value)} />}
                  label={formatMessage(messages.crossBorder)}
                  sx={{ marginTop: 2 }}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default TransitDetailsForm
