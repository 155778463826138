import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Fab,
  Grid,
  Typography,
} from '@mui/material'
import { useRef, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { useGetLocationsQuery } from '../../../app/redux-fetch/apiCoreQuery'
import FormDropdown from '../../../components/FormDropDown/FormDropdown'
import { RouteAccordion } from '../../Transport/components/Common/style'
import { IJourneyRoute } from '../../Transport/types'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import { useJourneyFormContext } from '../hooks/useJourneyFormContext'
import useJourneyStepperContext from '../hooks/useJourneyStepperContext'
import RouteLocation from './RouteLocation'

export interface IRouteProps {
  route: IJourneyRoute
  rIdx: number
  legIdx: number
}

const Route = ({ route, rIdx, legIdx }: IRouteProps) => {
  const [useAddLoad, setUseAddLoad] = useState(true)
  const [isExpanded, setIsExpanded] = useState(false)
  const loadRefs = useRef<React.RefObject<HTMLDivElement>[]>([])

  const { values, setFieldValue, handleBlur, isSubmitting } = useJourneyFormContext()

  const { mode } = useJourneyStepperContext()

  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

  const { data: locations = [] } = useGetLocationsQuery({
    tenantId: tenantId ?? -1,
    includeCustomerLocations: false,
  })

  const getLoadsForAdd = () => {
    const usedLoadIds: number[] = []
    values.legs[legIdx].routes.forEach((r) => {
      r.loads.forEach((rl) => {
        usedLoadIds.push(rl.id)
      })
    })
    return (
      values.loads
        ?.filter((ad) => !usedLoadIds.some((ulId) => ulId === ad.id))

        .map((l) => {
          return {
            id: l.id,
            name: l.description ?? '',
          }
        }) ?? []
    )
  }
  const getRouteTitle = () => {
    if (route.destinations?.length === 1) {
      const startLocation = route.destinations[0].name
      return `Route #${rIdx + 1} - ${startLocation}`
    }
    if (route.destinations?.length === 2) {
      const startLocation = route.destinations[0].name
      const endLocation = route.destinations[1].name
      return `Route #${rIdx + 1} - ${startLocation} > ${endLocation}`
    }
    if (route.destinations?.length > 2) {
      const startLocation = route.destinations[0].name
      const endLocation = route.destinations[route.destinations.length - 1].name
      return `Route #${rIdx + 1} - ${startLocation} > ${endLocation} (${route.destinations.length - 2
        } stops)`
    }
    return `Route #${rIdx + 1}`
  }

  const getLocations = () => {
    const usedLocationIds = route.destinations?.map((rl) => rl.locationId)
    return locations.filter((loc) => !usedLocationIds?.some((uloc) => uloc === loc.id))
  }

  const handleAddLocations = (rIdx: number) => {
    const sequence = values.legs[legIdx].routes[rIdx].destinations
      ? values.legs[legIdx].routes[rIdx].destinations.length + 1
      : 1
    const currLocations = getLocations()
    const newLocation = {
      name: currLocations[0].name,
      loadIds: [],
      sequence: sequence,
    }
    setFieldValue(`legs.${legIdx}.routes.${rIdx}.destinations`, [
      ...(values.legs[legIdx].routes[rIdx].destinations ?? []),
      newLocation,
    ])
  }

  const handleAddLoad = (e: any, name: string, newValue: any) => {
    e.preventDefault()
    e.stopPropagation()
    const load = values.loads.find((l) => l.id === newValue)
    setFieldValue(`legs.${legIdx}.routes.${rIdx}.loads`, [
      ...route.loads,
      {
        id: load?.id,
        name: load?.description,
        pickupLocationId: load?.pickupLocationId,
        consigneeId: load?.consigneeId,
        isDefined: load?.isDefined,
      },
    ])
    setUseAddLoad(true)
  }

  const handleRemoveRoute = (routeIdx: number) => {
    const routes = values.legs[legIdx].routes
    const loadIdx: number[] = []

    values.loads?.forEach((l, lIdx) => {
      if (l.routeId === values.legs[legIdx].routes[routeIdx].id) {
        loadIdx.push(lIdx)
      }
    })
    loadIdx.forEach((lIdx) => {
      const load = values.loads[lIdx]
      load.routeId = undefined
      setFieldValue(`loads.${lIdx}`, load)
    })
    routes.splice(routeIdx, 1)
    setFieldValue(`legs.${legIdx}.routes`, routes)
  }
  const getAccordionDetails = () => {
    if (!isExpanded) {
      return <></>
    } else {
      if (useAddLoad) {
        return (
          <Button
            color='secondary'
            disabled={isSubmitting || mode === 'view'}
            variant='contained'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setUseAddLoad(false)
            }}
          >
            Add load
          </Button>
        )
      } else {
        return (
          <FormDropdown
            sx={{ backgroundColor: 'white', width: '200px' }}
            id={`routes.${rIdx}`}
            label={'Add load'}
            disabled={mode === 'view'}
            onChange={handleAddLoad}
            handleOnBlur={(e: any) => {
              handleBlur(e)
              setUseAddLoad(true)
            }}
            value={-1}
            noOptionsText={'No unallocated loads'}
            items={getLoadsForAdd()}
            size='small'
          />
        )
      }
    }
  }
  return (
    <>
      <RouteAccordion
        sx={{
          backgroundColor: 'rgba(0,160,228,0.1)',
          '& .Mui-expanded': {
            marginBottom: '0px',
          },
        }}
        expanded={isExpanded}
        onChange={(e: any, expanded: boolean) => {
          setIsExpanded(expanded)
        }}
      >
        <AccordionSummary>
          <Grid container>
            <Grid item xs={6}>
              <Box display='flex' justifyContent='flex-start' alignItems={'center'}>
                <Typography sx={{ pr: '16px' }}>{getRouteTitle()}</Typography>
                {getAccordionDetails()}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display='flex' justifyContent='flex-end'>
                <Fab
                  aria-label='remove'
                  color='info'
                  disabled={mode === 'view'}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    handleRemoveRoute(rIdx)
                  }}
                  size='small'
                  sx={{ margin: '0 2px' }}
                >
                  <DeleteIcon />
                </Fab>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Container
            disableGutters={true}
            maxWidth={false}
            sx={{
              display: 'flex',
              overflow: 'auto',
              columnGap: '128px',
              padding: '16px 0px 16px 0px',
            }}
          >
            {values.legs[legIdx].routes[rIdx].destinations?.map((l, lIdx) => {
              return (
                <>
                  <RouteLocation
                    key={`{RouteLocation${rIdx}${lIdx}`}
                    location={l}
                    locationIdx={lIdx}
                    route={route}
                    routeIdx={rIdx}
                    legIdx={legIdx}
                    loadRefs={loadRefs}
                  />
                  <Box
                    key={`pbox${lIdx}`}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginRight: '-124px',
                      marginLeft: '-124px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      maxHeight: '400px',
                    }}
                  >
                    <Box key={`Box${rIdx}${lIdx}`} sx={{ width: '30px', height: '20px' }}>
                      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 350 100'>
                        <defs>
                          <marker
                            id='arrowhead'
                            markerWidth='10'
                            markerHeight='7'
                            refX='0'
                            refY='3.5'
                            orient='auto'
                          >
                            <polygon points='0 0, 10 3.5, 0 7' />
                          </marker>
                        </defs>
                        <line
                          x1='0'
                          y1='50'
                          x2='200'
                          y2='50'
                          color='rgb(33, 58, 124)'
                          stroke='#000'
                          strokeWidth='16'
                          markerEnd='url(#arrowhead)'
                        />
                      </svg>
                    </Box>
                  </Box>
                </>
              )
            })}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                minWidth: `${route.destinations?.length === 0 ? '32px' : '32px'}`,
                alignItems: 'center',
                justifyContent: 'center',
                maxHeight: `${route.destinations?.length === 0 ? '64px' : '400px'}`,
              }}
            >
              <Box sx={{ width: '32px', height: '32px' }}>
                <Fab
                  aria-label='add'
                  color='secondary'
                  disabled={isSubmitting || mode === 'view'}
                  onClick={() => {
                    handleAddLocations(rIdx)
                  }}
                  size='small'
                  sx={{ margin: '0 2px' }}
                >
                  <AddIcon />
                </Fab>
              </Box>
            </Box>
          </Container>
        </AccordionDetails>
      </RouteAccordion>
    </>
  )
}

export default Route
