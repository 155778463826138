import { Box, FormControlLabel, Switch, TextField } from '@mui/material'
import { useIntl } from 'react-intl'
import { useGetTransportModeQuery } from '../../../../../app/redux-fetch/apiCoreQuery'
import { IDropDownItem } from '../../../../../app/types'
import FormDropdown from '../../../../../components/FormDropDown/FormDropdown'
import RequiredAsterisk from '../../../../../components/RequiredAsterisk/RequiredAsterisk'
import {
  SectionLabelTypography,
  SectionTitleTypography,
} from '../../../../../components/Typographies/styles'
import { messages } from '../../messages'
import { useAssetConfigurationForm } from './hooks/useAssetConfigurationForm'

type MainInfoProps = {
  isDisabled: boolean
}

const MainInfo = ({ isDisabled }: MainInfoProps) => {
  const { formatMessage } = useIntl()

  const { data: transportModes = [] } = useGetTransportModeQuery()

  const { values, setFieldValue, errors, touched } = useAssetConfigurationForm()
  const { name, transportModeId } = values

  const handleTextFieldChange = ({
    target,
  }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFieldValue(target.id, target.value)
  }

  const handleFormDropDownChange = (e: any, name: any, newValue: any) => {
    setFieldValue(name, newValue)
    values.allocations &&
      setFieldValue(
        'allocations',
        values.allocations.map((allocation) => ({
          ...allocation,
          assetTypeId: '',
          assetClassId: '',
          assetCategoryId: '',
        }))
      )
  }

  return (
    <Box sx={{ width: '50%' }}>
      <Box sx={{ mb: '16px' }}>
        <SectionTitleTypography>{formatMessage(messages.mainInfoLbl)}</SectionTitleTypography>
      </Box>
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.configurationName)}
          <RequiredAsterisk />
        </SectionLabelTypography>
        <TextField
          fullWidth
          id='name'
          value={name}
          defaultValue={name}
          disabled={isDisabled}
          onChange={handleTextFieldChange}
          error={touched.name && !!errors.name}
          helperText={touched.name && errors.name}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.transportMode)}
          <RequiredAsterisk />
        </SectionLabelTypography>
        <FormDropdown
          id='transportModeId'
          items={transportModes as IDropDownItem[]}
          onChange={handleFormDropDownChange}
          value={transportModeId}
          disabled={isDisabled}
          defaultValue={transportModeId}
          error={touched.transportModeId && !!errors.transportModeId}
          errorText={touched.transportModeId && errors.transportModeId}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <FormControlLabel
          sx={{ justifyContent: 'start', minHeight: '56px' }}
          value={values.customerSelectable}
          labelPlacement='start'
          control={
            <Switch
              color='secondary'
              checked={!!values.customerSelectable}
              onChange={({ target }) => {
                setFieldValue('customerSelectable', target.checked)
              }}
              disabled={isDisabled}
            />
          }
          label={`${formatMessage(messages.customerSelectable)}`}
          disabled={isDisabled}
        />
      </Box>
    </Box>
  )
}

export default MainInfo
