import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  trackingNumber: {
    id: 'shipment.trackingNumber',
    description: 'Tracking Number',
    defaultMessage: 'Tracking Number',
  },
  shipper: {
    id: 'shipment.shipper',
    description: 'Shipper',
    defaultMessage: 'Shipper',
  },
})
