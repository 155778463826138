import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Divider, FormControl, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useGetAllLoadTypesQuery, useGetServicesQuery } from '../../../../app/redux-fetch/apiShipment';
import MRTDataGridv2 from '../../../../components/MRTDataGrid/MRTDataGridv2';
import { TableSubTitleTypography } from '../../../../components/Typographies/TableTitleTypography';
import { MRTColumnDef } from '../../../../types/common';
import messages from '../messages';
import { ServiceWaypoint, StopType } from '../types';
import { mapServiceForDisplay } from '../utils';

export interface ConsignmentServiceTableProps {
    consignmentIndex: number;
    stopType: StopType;
    stopPrefix: string;
}

const ConsignmentServiceTable = ({ consignmentIndex, stopType, stopPrefix }: ConsignmentServiceTableProps) => {
    const { formatMessage } = useIntl();
    const { watch, setValue, getValues } = useFormContext();

    const tenantId = watch('tenantId');

    const {
        data: services = [],
    } = useGetServicesQuery({ tenantId })

    const { data: packagingTypes = [] } = useGetAllLoadTypesQuery()

    const loads = watch(`bookings.${consignmentIndex}.packaging`);
    const loadTypeId = watch(`bookings.${consignmentIndex}.loadTypeId`);

    const getContainerType = useCallback((templateId: number) => {
        return packagingTypes.find((x: any) => x.id === templateId)?.name ?? 'N/A';
    }, [packagingTypes]);




    const stopId = watch(`bookings.${consignmentIndex}.${stopPrefix}.id`);


    const getAvailableServices = (loadTypeId: number | string, loadId: number) => {

        const stopServices = getValues(`bookings.${consignmentIndex}.${stopPrefix}.services`) ?? [];

        return services.filter((service: any) =>
            service.enabled &&
            service.customerSelectable &&
            service.loadTypes.some((lt: any) => lt.loadTypeId === Number(loadTypeId)) &&
            !stopServices.some((ss: any) =>
                ss.serviceId === service.id &&
                Array.isArray(ss.loads) &&
                ss.loads.some((sl: any) => sl.loadId === loadId)
            )
        );
    };

    const loadData = useMemo(() => {
        return loads.map((load: any, loadIndex: number) => ({
            ...load,
            loadIndex,
            bookingIndex: consignmentIndex,
            loadTypeId,
            loadTypeName: packagingTypes.find((lt: any) => lt.id === loadTypeId)?.name,
            dimensions: `${load.length} x ${load.width} x ${load.height}`,
            weight: load.weight,
        }));
    }, [loads, loadTypeId, packagingTypes]);


    const handleAddService = (loadId: number, serviceId: number) => {
        if (!serviceId) return;

        const stopServices = getValues(`bookings.${consignmentIndex}.${stopPrefix}.services`) ?? [];

        const newService = {
            waypointId: stopId,
            serviceId,
            loads: [{
                loadId
            }]
        };

        setValue(`bookings.${consignmentIndex}.${stopPrefix}.services`, [...stopServices, newService], {
            shouldDirty: true,
        });
    };

    const handleDeleteService = (serviceIndex: number) => {
        const stopServices = getValues(`bookings.${consignmentIndex}.${stopPrefix}.services`) ?? [];
        stopServices.splice(serviceIndex, 1);
        setValue(`bookings.${consignmentIndex}.${stopPrefix}.services`, stopServices, {
            shouldDirty: true,
        });
    };

    const columns = useMemo<MRTColumnDef<any>[]>(() => [
        {
            accessorKey: 'reference',
            header: 'Load',
            size: 150,
            Cell: ({ row }: any) => (
                <Typography variant="body2">
                    Load #{row.original.loadIndex + 1}
                </Typography>
            )
        },
        {
            accessorKey: 'loadTypeId',
            header: 'Type',
            size: 120,
            Cell: ({ row }: any) => (
                <Typography variant="body2">
                    {getContainerType(loadTypeId)}
                </Typography>
            )
        },
        {
            accessorKey: 'quantity',
            header: 'Quantity',
            Cell: ({ row }: any) => (
                <Typography>
                    {row.original.numberOfPackages}
                </Typography>
            )
        },
        {
            accessorKey: 'serviceSelection',
            header: 'Services',
            size: 200,
            Cell: ({ row }: { row: any }) => {
                const { id: loadId, loadTypeId } = row.original;
                const availableServices = getAvailableServices(loadTypeId, loadId);

                return (
                    <Box>
                        <FormControl fullWidth size="small">
                            <Select
                                displayEmpty
                                disabled={availableServices.length === 0}
                                onChange={(e) => {
                                    const serviceId = e.target.value;
                                    if (serviceId) {
                                        handleAddService(loadId, +serviceId);
                                    }
                                }}
                                MenuProps={{
                                    PaperProps: { sx: { maxHeight: 200 } }
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    }
                                }}
                                renderValue={(selected) => {
                                    return <Typography color="text.secondary">
                                        {availableServices.length === 0 ? 'No services available' : 'Select a service'}
                                    </Typography>
                                }}
                            >
                                {availableServices.map((service: any) => (
                                    <MenuItem key={service.id} value={service.id.toString()}>
                                        {service.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                );
            },
        },
        {
            accessorKey: 'selectedServices',
            header: 'Selected Services',
            size: 250,
            Cell: ({ row }: { row: any }) => {
                const stopServices = getValues(`bookings.${consignmentIndex}.${stopPrefix}.services`) ?? [];

                if (stopServices.length === 0) return <Typography variant="caption" color="text.secondary">No services selected</Typography>;

                const loadServices = stopServices
                    .filter((service: ServiceWaypoint) =>
                        Array.isArray(service.loads) &&
                        service.loads.some((load) => load.loadId === row.original.id)
                    )
                    .map((service: ServiceWaypoint) => mapServiceForDisplay(service, services))
                    .filter(Boolean);

                if (loadServices.length === 0) return <Typography variant="caption" color="text.secondary">No services selected</Typography>;

                return (
                    <Stack spacing={1} divider={<Divider />}>
                        {loadServices.map((service: any, idx: number) => {
                            return (
                                <Box key={idx} sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography variant="body2">
                                                {service.serviceName}
                                            </Typography>
                                        </Box>
                                        <Typography variant="caption" color="text.secondary">
                                            {service.serviceDescription} - {service.serviceCode}
                                        </Typography>
                                    </Box>
                                    <IconButton
                                        onClick={() => {
                                            const serviceIndex = stopServices.findIndex(
                                                (s: ServiceWaypoint) => s.serviceId === service.serviceId &&
                                                    Array.isArray(s.loads) &&
                                                    (s.loads.some(l => l.loadId === row.original.loadIndex)
                                                        || s.loads.some(l => l.loadId === row.original.id))
                                            );

                                            if (serviceIndex !== -1) {
                                                handleDeleteService(serviceIndex);
                                            }
                                        }}
                                        size="small"
                                        color="error"
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            );
                        })}
                    </Stack>
                );
            },
        },
    ], [services, getContainerType, watch, getValues, setValue]);

    return (
        <Box sx={{ mt: 2 }}>
            <MRTDataGridv2
                leftHeadingComponent={
                    <Box sx={{ display: 'inline-flex' }}>
                        <TableSubTitleTypography>{formatMessage(messages.services)}</TableSubTitleTypography>
                    </Box>
                }
                state={{ isLoading: false }}
                columns={columns}
                data={loadData ?? []}
                getRowId={(row) => row.id as any}
                enableColumnActions={false}
                enableColumnFilters={true}
                enablePagination={false}
                enableSorting={true}
                enableBottomToolbar={false}
                enableTopToolbar={true}
            />
        </Box>
    );
};

export default ConsignmentServiceTable;