import DeleteIcon from '@mui/icons-material/Delete';
import CopyIcon from '@mui/icons-material/FileCopy';

import {
  AccordionDetails,
  Box,
  IconButton
} from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import StyledAccordion from '../../../components/Accordions/StyledAccordion';
import StyledAccordionSummary from '../../../components/Accordions/StyledAccordionSummary';
import CustomAlert from '../../../components/Alerts/CustomAlert';
import { AccordionHeader } from '../../../components/Headers/styles';
import { useOrderFormContext } from '../context/OrderFormContext';
import ConsignmentService from './ConsignmentService/ConsignmentService';
import PackagingForm from './PackagingForm';
import ShipFromDeliverToContainer from './ShipFromDeliverToContainer';
import messages from './messages';

interface ConsignmentFormProps {
  index: number
  removeConsignment: (index: number) => void
  copyConsignment: (index: number) => void
}

const ConsignmentForm: React.FC<ConsignmentFormProps> = ({
  index,
  removeConsignment,
  copyConsignment,
}) => {

  const [expanded, setExpanded] = useState(true);
  const { currentPage } = useOrderFormContext();
  const { formatMessage } = useIntl();

  const handleToggleAccordion = () => {
    setExpanded((prev) => !prev);
  };
  const { watch } = useFormContext();
  const bookings = watch('bookings');

  const hasAnyServices = useCallback((consignmentIndex: any) => {
    const consignment = bookings[consignmentIndex];
    if (consignment?.shipFrom?.services?.length > 0) {
      return true;
    }

    if (consignment?.waypoints?.length > 0) {
      for (const waypoint of consignment.waypoints) {
        if (waypoint.services?.length > 0) {
          return true;
        }
      }
    }

    if (consignment?.deliverTo?.services?.length > 0) {
      return true;
    }

    return false;
  }, [bookings]);

  const renderServiceWarning = useMemo(() => {
    const hasServices = hasAnyServices(index);
    if (hasServices && [1, 2, 3].includes(currentPage)) {
      return (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'right',
          }}>
          <CustomAlert
            severity='warning'
            message={formatMessage(messages.serviceChangeWarning)}
          />
        </Box>
      );
    }
    return null;
  }, [hasAnyServices, formatMessage, index, currentPage, bookings]);

  const renderShipFromDeliverToContainer = () => {
    return currentPage === 1 ? (<>
      <ShipFromDeliverToContainer consignmentIndex={index} />
    </>) : null
  }

  const renderPackagingForm = () => {
    return currentPage === 2 || currentPage === 3 ? (
      <>
        <PackagingForm consignmentIndex={index} />
      </>
    ) : null
  }

  const renderConsignmentServices = () => {
    return currentPage === 4 ? (
      <ConsignmentService expanded={true} consignmentIndex={index} />
    ) : null
  }

  return (
    <>
      <StyledAccordion sx={theme => ({ backgroundColor: theme.palette?.accordion?.parent?.bg })}
        expanded={expanded}
        onChange={handleToggleAccordion}>
        <StyledAccordionSummary
          index={`consignment-content-${index}`} expanded={expanded}
        >
          <AccordionHeader sx={{ flexGrow: 1 }}>
            {formatMessage(messages.consignmentItem, { idx: index + 1 })}
          </AccordionHeader>
          <IconButton onClick={() => copyConsignment(index)} color="primary">
            <CopyIcon />
          </IconButton>
          <IconButton onClick={() => removeConsignment(index)} color="error">
            <DeleteIcon />
          </IconButton>
        </StyledAccordionSummary>
        <AccordionDetails>
          {renderServiceWarning}
          {renderShipFromDeliverToContainer()}
          {renderPackagingForm()}
          {renderConsignmentServices()}
        </AccordionDetails>
      </StyledAccordion>
    </>
  )
}

export default ConsignmentForm
