import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useIntl } from 'react-intl';
import { useLoggedInUser } from '../../app/hooks/useLoggedInUser';
import { useGetAllLoadTypesQuery, useGetServiceByIdQuery } from '../../app/redux-fetch/apiShipment';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import { SectionLabelTypography } from '../../components/Typographies/styles';
import { messages } from './messages';
import ServiceForm from './ServiceForm';

interface EditServiceFormProps {
    serviceId: number;
    isOpen: boolean;
    handleClose: () => void;
}

const EditServiceForm = ({ serviceId, isOpen, handleClose }: EditServiceFormProps) => {
    const { formatMessage } = useIntl();
    const { tenantId } = useLoggedInUser();

    const { data: service, isFetching } = useGetServiceByIdQuery(
        { tenantId, id: serviceId },
        { skip: !isOpen || serviceId <= 0 }
    );

    const { data: apiLoadTypes = [], isFetching: isLoadT } = useGetAllLoadTypesQuery();

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>
                <SectionLabelTypography>
                    {formatMessage(messages.createEditService)}
                </SectionLabelTypography>
            </DialogTitle>
            <DialogContent sx={{ minWidth: '500px' }}>
                {isFetching ? (
                    <LoadingIndicator />
                ) : service ? (
                    <ServiceForm
                        service={{
                            ...service,
                            loadTypes: service.loadTypes?.map((lt: any) => {
                                return { serviceId: lt.serviceId, name: lt.loadType?.name, id: lt.id, loadTypeId: lt.loadTypeId }
                            }) || []
                        }}
                        handleClose={handleClose}
                    />
                ) : null}
            </DialogContent>
        </Dialog>
    );
};

export default EditServiceForm;
