import React, { createContext, useContext, useMemo } from 'react';

interface OrderFormContextProps {
  currentPage: number;
  totalPages: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  isShipperEnabled: boolean;
}

const OrderFormContext = createContext<OrderFormContextProps | null>(null);

export const useOrderFormContext = () => {
  const context = useContext(OrderFormContext);
  if (!context) {
    throw new Error('useOrderFormContext must be used within OrderFormProvider');
  }
  return context;
};

export const OrderFormProvider: React.FC<{
  currentPage: number;
  totalPages: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  isShipperEnabled: boolean;
  children: React.ReactNode;
}> = ({ currentPage, totalPages, setCurrentPage, isShipperEnabled, children }) => {

  const value = useMemo(() => ({
    currentPage,
    totalPages,
    setCurrentPage,
    isShipperEnabled,
  }), [currentPage, totalPages, setCurrentPage, isShipperEnabled]);

  return (
    <OrderFormContext.Provider value={value}>
      {children}
    </OrderFormContext.Provider>
  );
};