import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useLoggedInUser } from '../../../app/hooks/useLoggedInUser'
import { useNotificationStack } from '../../../app/hooks/useNotificationStack'
import { useTrackingNumberTemplateQuery } from '../../../app/redux-fetch/apiQuery'
import {
  useAcceptOrderByTenantIdMutation,
  useCreateShipmentMutation,
  useSubmitOrderMutation,
  useUpdateShipmentMutation,
} from '../../../app/redux-fetch/apiShipment'
import CustomAlert from '../../../components/Alerts/CustomAlert'
import TooltipButton from '../../../components/Buttons/TooltipButton'
import commonMessages from '../../../components/Messages/commonMessages'
import { OrderStatuses } from '../../../types/common'
import { OrderFormProvider } from '../context/OrderFormContext'
import ConsignmentList from './ConsignmentList'
import messages from './messages'
import { IShipmentFormInput, schema } from './schema'
import ShipmentInformation from './ShipmentInformation'
import ShipmentSummary from './ShipmentSummary'
import { mapToShipmentData } from './utils'

interface ShipmentFormProps {
  initialValues?: Partial<IShipmentFormInput>
  onSubmit: (data: IShipmentFormInput) => void
  isEdit?: boolean
  onCancel?: () => void
}

const ShipmentForm: React.FC<ShipmentFormProps> = ({ initialValues, isEdit = false, onSubmit }) => {
  const { tenantId, customerId, isTenantUser } = useLoggedInUser()

  const { formatMessage } = useIntl()

  const methods = useForm<IShipmentFormInput>({
    resolver: zodResolver(schema),
    mode: 'all',
    defaultValues: initialValues || {
      tenantId: tenantId,
      customerId: customerId,
      bookings: [
        {
          shipFrom: {},
          deliverTo: {},
        },
      ],
    },
  })

  const {
    handleSubmit,
    reset,
    formState: { isDirty, errors },
    watch,
    trigger,
  } = methods

  React.useEffect(() => {
    if (initialValues) {
      reset(initialValues)
    }
  }, [initialValues, reset])

  console.log('errors', errors)

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const getPageFromHash = () => {
    if (isEdit) {
      const hash = window.location.hash;
      if (hash) {
        const pageNumber = parseInt(hash.substring(1), 10);
        if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= 4) {
          return pageNumber;
        }
      }
    }
    return 1;
  };

  const [currentPage, setCurrentPage] = useState(getPageFromHash());

  useEffect(() => {
    if (isEdit) {
      window.history.replaceState(null, '', `#${currentPage}`);
    }
  }, [currentPage, isEdit]);

  const [createShipment, { isLoading }] = useCreateShipmentMutation()
  const [updateShipment, { isLoading: isUpdating }] = useUpdateShipmentMutation()

  const [submitOrder] = useSubmitOrderMutation()
  const [acceptOrder] = useAcceptOrderByTenantIdMutation()

  const { enqueueSuccess, enqueueFailure } = useNotificationStack()

  const [isLoadRequired, setIsLoadRequired] = useState(false)
  const [isLoadContentRequired, setIsLoadContentRequired] = useState(false)

  const {
    data: template,
  } = useTrackingNumberTemplateQuery({ tenantId })

  const isTrackingNumberSetup = useCallback(() => !!template, [template])

  const navigate = useNavigate()

  const isSubmitted = false
  const customer = watch('customerId')
  const bookings = watch('bookings')
  const status = watch('status')

  const validateStops = async () => {
    const fieldsToTrigger = []

    for (let i = 0; i < bookings?.length; i++) {
      fieldsToTrigger.push(`bookings.${i}.waypoints`)
      fieldsToTrigger.push(`bookings.${i}.shipFrom`)
      fieldsToTrigger.push(`bookings.${i}.deliverTo`)
      fieldsToTrigger.push(`bookings.${i}.returnTo`)
      fieldsToTrigger.push(`bookings.${i}.containerCollections`)
    }

    return await trigger(fieldsToTrigger as any)
  }

  const validateLoads = async () => {
    const fieldsToTrigger = []

    for (let i = 0; i < bookings?.length; i++) {
      const booking = bookings[i]
      if (!booking || !booking.packaging) continue

      for (let j = 0; j < booking?.packaging?.length; j++) {
        const packaging = booking.packaging[j]
        if (!packaging) continue

        const packagingFields = Object.keys(packaging)
          .filter((key) => key !== 'products')
          .map((key) => `bookings.${i}.packaging.${j}.${key}`)

        fieldsToTrigger.push(...packagingFields)
      }
    }

    return await trigger(fieldsToTrigger as any)
  }

  const validateLoadsWithProducts = async () => {
    const fieldsToTrigger = []

    for (let i = 0; i < bookings?.length; i++) {
      const booking = bookings[i]
      if (!booking || !booking.packaging) continue

      for (let j = 0; j < booking?.packaging?.length; j++) {
        const packaging = booking.packaging[j]
        if (!packaging) continue

        const packagingFields = Object.keys(packaging).map(
          (key) => `bookings.${i}.packaging.${j}.${key}`
        )

        fieldsToTrigger.push(...packagingFields)

        if (packaging.products && Array.isArray(packaging.products)) {
          for (let k = 0; k < packaging.products.length; k++) {
            const productFields = Object.keys(packaging.products[k]).map(
              (key) => `bookings.${i}.packaging.${j}.products.${k}.${key}`
            )
            fieldsToTrigger.push(...productFields)
          }
        }
      }
    }

    return await trigger(fieldsToTrigger as any)
  }

  const handleSubmitForm = async (
    data: IShipmentFormInput,
    onAfterRequest?: (responseData?: any) => void
  ) => {
    const payload = mapToShipmentData(data)
    let isErrorOccurred = false
    if (payload) {
      let response
      try {
        if (isEdit) {
          response = await updateShipment({ shipment: payload, tenantId, customerId }).unwrap()
          enqueueSuccess(messages.shipmentUpdated)
        } else {
          response = await createShipment({ shipment: payload, tenantId, customerId }).unwrap()
          enqueueSuccess(messages.shipmentCreated)
        }
      } catch (error) {
        console.error('Failed to submit shipment:', error)
        enqueueFailure(messages.shipmentSaveDraftFailed)
        isErrorOccurred = true
      } finally {
        if (onAfterRequest && !isErrorOccurred) {
          onAfterRequest(response)
        }
      }
    }
  }

  const handleSubmitShipment = async () => {
    const data = methods.getValues()
    handleSubmitForm(data, async (response) => {
      if (response) {
        try {
          await submitOrder({ tenantId, id: response.id }).unwrap()
          enqueueSuccess(messages.shipmentSubmitted)
          navigate('/shipping/shipments')
        } catch (error) {
          enqueueFailure(messages.shipmentSubmitFailed)
        }
      }
    })
  }

  const handleAccept = async () => {
    const data = methods.getValues()
    handleSubmitForm(data, async (response) => {
      if (response) {
        try {
          if (!isSubmitted) {
            await submitOrder({ tenantId, id: response.id }).unwrap()
          }
          await acceptOrder({ tenantId, id: response.id }).unwrap()
          enqueueSuccess(messages.shipmentAccepted)
          navigate('/shipping/shipments')
        } catch (error) {
          enqueueFailure(messages.shipmentAcceptFailed)
        }
      }
    })
  }

  const handleSaveDraft = async () => {
    const data = methods.getValues()
    const isValid = await methods.trigger()

    if (!isValid) {
      return
    }

    handleSubmitForm(data, () => {
      navigate('/shipping/shipments')
    })

  }

  const handleSaveDraftForNext = async () => {

    const data = methods.getValues();
    const isValid = await methods.trigger();
    data.revision = (data.revision ?? 0) + 1
    if (isValid) {
      try {
        let response;

        await handleSubmitForm(data, (res) => {
          response = res;
        })

        return response;

      } catch (error) {
        enqueueFailure(messages.shipmentSaveDraftFailed);
        return null;
      }
    }
    return null;
  };

  const handleNext = async () => {
    let isValid = false;
    if (currentPage === 1) {
      isValid = await validateStops();
    } else if (currentPage === 2) {
      isValid = await validateLoads();
    } else if (currentPage === 3) {
      isValid = await validateLoadsWithProducts();
    } else {
      isValid = true;

    }


    if (isValid) {
      if (!isEdit) {
        const draftResponse = isDirty && await handleSaveDraftForNext();

        if (draftResponse) {
          const id = (draftResponse as any).id;
          navigate(`/shipping/shipments/${id}/edit#${currentPage + 1}`);
          setCurrentPage((prev) => prev + 1);
        }
      } else {
        isDirty && await handleSaveDraftForNext();
        setCurrentPage((prev) => prev + 1);
      }
    }
  };

  const handleBack = async () => {
    let isValid = false
    if (currentPage === 1) {
      return
    } else if (currentPage === 2) {
      isValid = await validateLoads()
    } else if (currentPage === 3) {
      isValid = await validateLoadsWithProducts()
    } else {
      isValid = true
    }

    if (isValid) {
      if (!isEdit) {
        const draftResponse = isDirty && await handleSaveDraftForNext();

        if (draftResponse) {
          const id = (draftResponse as any).id;
          navigate(`/shipping/shipments/${id}/edit#${currentPage - 1}`);
          setCurrentPage((prev) => prev - 1);
        }
      } else {
        isDirty && await handleSaveDraftForNext();
        setCurrentPage((prev) => prev - 1);
      }
    }
  }

  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (name?.includes('shipFrom.loadingDate') || name?.includes('deliverTo.deliveryDate')) {
        methods.trigger()
      }
    })
    return () => subscription.unsubscribe()
  }, [methods])

  const steps = [
    { label: formatMessage(messages.consignment) },
    { label: formatMessage(messages.load) },
    { label: formatMessage(messages.contents) },
    { label: formatMessage(messages.consignmentServices) },
    { label: formatMessage(messages.summary) },
  ]

  const activeStep = currentPage <= 4 ? currentPage - 1 : 4

  const isConsignmentRequired =
    (!bookings || bookings?.length === 0) && (currentPage !== 1 || isEdit)

  const getTooltipMessage = () => {
    if (isConsignmentRequired) {
      return formatMessage(messages.noConsignment)
    }
    if (isLoadContentRequired && !isLoadRequired) {
      return formatMessage(messages.noLoadContent)
    }
    return formatMessage(messages.noLoad)
  }
  const rev = watch('revision')

  console.log('🚀 --------------------🚀')
  console.log('🚀 ~ :385 ~ rev:', rev)
  console.log('🚀 --------------------🚀')


  const isRejected = status === OrderStatuses.Rejected

  return (
    <Paper elevation={3} sx={{ padding: 4 }}>
      <Box
        sx={{
          backgroundColor: '#f0f0f0',
          padding: 2,
          textAlign: 'center',
          marginBottom: 4,
        }}
      >
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((s, index) => (
            <Step key={s.label}>
              <StepLabel>{s.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <OrderFormProvider currentPage={currentPage} totalPages={4} setCurrentPage={setCurrentPage} isShipperEnabled={false}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display='flex'
              flexDirection={isSmallScreen ? 'column' : 'row'}
              justifyContent='flex-end'
              alignItems={isSmallScreen ? 'flex-start' : 'center'}
              sx={{ marginBottom: 2 }}
            >
              <Box>
                {currentPage > 1 && (
                  <Button
                    variant='contained'
                    sx={{ marginRight: 1, marginBottom: isSmallScreen ? 1 : 0 }}
                    onClick={handleBack}
                  >
                    {formatMessage(commonMessages.previous)}
                  </Button>
                )}

                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleSaveDraft}
                  disabled={!isTrackingNumberSetup()}
                  sx={{ marginRight: 1, marginBottom: isSmallScreen ? 1 : 0 }}
                >
                  {isLoading || isUpdating ? (
                    <CircularProgress size={24} />
                  ) : (
                    formatMessage(messages.saveDraft)
                  )}
                </Button>
                {currentPage === 5 && isTenantUser && !isRejected ? (
                  <TooltipButton
                    variant='contained'
                    color='primary'
                    onClick={handleAccept}
                    disabled={isConsignmentRequired || isLoadRequired || !isTrackingNumberSetup()}
                    showTooltip={isConsignmentRequired || isLoadRequired}
                    tooltipProps={{
                      placement: 'top',
                      title: getTooltipMessage(),
                      children: <></>,
                    }}
                  >
                    {isSubmitted
                      ? formatMessage(messages.acceptShipment)
                      : formatMessage(messages.submitAndAccept)}
                  </TooltipButton>
                ) : (
                  currentPage < 5 && (
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={!isTrackingNumberSetup()}
                      onClick={handleNext}
                    >
                      {formatMessage(commonMessages.next)}
                    </Button>
                  )
                )}
                {currentPage === 5 && !isRejected && (
                  <>
                    <TooltipButton
                      variant='contained'
                      color='primary'
                      sx={{ marginLeft: 1 }}
                      disabled={isConsignmentRequired || isLoadRequired || !isTrackingNumberSetup()}
                      showTooltip={isConsignmentRequired || isLoadRequired}
                      tooltipProps={{
                        placement: 'top',
                        title: getTooltipMessage(),
                        children: <></>,
                      }}
                      onClick={handleSubmitShipment}
                    >
                      {formatMessage(messages.submitShipment)}
                    </TooltipButton>
                  </>
                )}
              </Box>
            </Box>
            {isTrackingNumberSetup() && isConsignmentRequired && (
              <CustomAlert
                severity='error'
                title='Error'
                message={formatMessage(messages.noConsignment)}
              />
            )}
            {isTrackingNumberSetup() && isLoadRequired && (
              <CustomAlert
                severity='error'
                title='Error'
                message={formatMessage(messages.noLoad)}
              />
            )}
            {isTrackingNumberSetup() && isLoadContentRequired && !isLoadRequired && (
              <CustomAlert
                severity='error'
                title='Error'
                message={formatMessage(messages.noLoadContent)}
              />
            )}
            {isTrackingNumberSetup() && (
              <>
                {currentPage !== 5 ? (
                  <>
                    <ShipmentInformation isEdit={isEdit} />
                    {currentPage > 0 && customer && <ConsignmentList />}
                  </>
                ) : (
                  currentPage === 5 && <ShipmentSummary data={watch()} />
                )}
              </>
            )}
          </form>
        </FormProvider>
      </OrderFormProvider>
    </Paper>
  )
}

export default ShipmentForm
