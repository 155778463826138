import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useIntl } from 'react-intl';
import { useLoggedInUser } from '../../app/hooks/useLoggedInUser';
import ConditionalRender from '../../components/ConditionalRender/ConditionalRender';
import { SectionLabelTypography } from '../../components/Typographies/styles';
import { messages } from './messages';
import ServiceForm from './ServiceForm';
import { IService } from './types';

interface CreateServiceFormProps {
    isOpen: boolean;
    handleClose: () => void;
}

const CreateServiceForm = ({ isOpen, handleClose }: CreateServiceFormProps) => {
    const { formatMessage } = useIntl();
    const { tenantId } = useLoggedInUser();

    const emptyService: IService = {
        tenantId,
        name: '',
        description: '',
        code: '',
        customerSelectable: false,
        enabled: true,
        loadTypes: []
    };

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>
                <SectionLabelTypography>
                    {formatMessage(messages.createEditService)}
                </SectionLabelTypography>
            </DialogTitle>
            <DialogContent sx={{ minWidth: '500px' }}>
                <ServiceForm
                    service={emptyService}
                    handleClose={handleClose}
                />
            </DialogContent>
        </Dialog>
    );
};

export default CreateServiceForm;
