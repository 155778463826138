import { z } from 'zod'

export const carrierSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  code: z.string().optional(),
  transportModeId: z.number().min(0, 'Transport Mode is required'),
  tenantId: z.number(),
  id: z.number().optional(),
})

export type CarrierFormData = z.infer<typeof carrierSchema>
