import { AccordionDetails, Button, Grid, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useGetContactByEntityIdQuery } from '../../../app/redux-fetch/apiCoreQuery'
import StyledAccordion from '../../../components/Accordions/StyledAccordion'
import StyledAccordionSummary from '../../../components/Accordions/StyledAccordionSummary'
import FormSelect from '../../../components/Forms/FormSelect'
import commonMessages from '../../../components/Messages/commonMessages'
import { useLocationModal } from '../hooks/useLocationModal'
import messages from './messages'
import { getLocationAddress, mapContactsToOptions } from './utils'

interface ReturnToProps {
  index: number
}

const ReturnTo: React.FC<ReturnToProps> = ({ index }: ReturnToProps) => {
  const { setValue, watch } = useFormContext()

  const [expanded, setExpanded] = useState(true)
  const { formatMessage } = useIntl()

  const handleToggleAccordion = () => {
    setExpanded((prev) => !prev)
  }

  const customerId = watch('customerId')

  const { openModal, ModalComponent, locationOptions, locations } = useLocationModal({
    onSave: (id) => {
      setValue(`bookings.${index}.returnTo.location`, id)
    },
    filterFunction: (location) => {
      return (
        location.enabled &&
        location.locationTypeId !== 0 &&
        location.locationTypeId !== 1
      )
    },
    customerId,
  })

  const returnTo = watch(`bookings.${index}.shipFrom.returnTo`)

  const locationId = watch(`bookings.${index}.returnTo.locationId`)

  const { data: contacts } = useGetContactByEntityIdQuery(
    locationId,
    {
      skip: !locationId,
      refetchOnMountOrArgChange: true,
    }
  )

  const getContactOptions = useCallback(() => {
    return mapContactsToOptions(contacts)
  }, [contacts])

  if (!returnTo) {
    return null
  }

  return (
    <>
      {ModalComponent}
      <StyledAccordion defaultExpanded sx={{ marginTop: '10px' }} onChange={handleToggleAccordion}>
        <StyledAccordionSummary
          index={`returnTo-content-${index}`}
          expanded={expanded}
          title={formatMessage(messages.returnTo)}
        ></StyledAccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <FormSelect
                name={`bookings.${index}.returnTo.locationId`}
                label={formatMessage(messages.location)}
                options={locationOptions}
                onChange={(e) => {
                  setValue(`bookings.${index}.returnTo.contactId`, null)
                }}
                actionLabel={
                  <Button
                    size='small'
                    onClick={() => {
                      openModal()
                    }}
                    sx={{ textTransform: 'none', p: 0, mb: '6px' }}
                  >
                    {formatMessage(commonMessages.addNew)}
                  </Button>
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormSelect
                name={`bookings.${index}.returnTo.contactId`}
                label={formatMessage(messages.contact)}
                options={getContactOptions()}
                disableLabel={formatMessage(messages.noContactsAvailable)}
                actionLabel={
                  <Button
                    size='small'
                    onClick={() => {
                      setValue(`bookings.${index}.returnTo.contactId`, null)
                    }}
                    sx={{ textTransform: 'none', p: 0, mb: '6px' }}
                  >
                    {formatMessage(messages.clearSelection)}
                  </Button>
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant='subtitle1' gutterBottom sx={{ color: '#636363' }}>
                {formatMessage(messages.address)}
              </Typography>
              <Typography variant='body2' sx={{ color: '#636363' }}>
                {getLocationAddress(locations, locationId)}
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </StyledAccordion>
    </>
  )
}

export default ReturnTo
