import { AccordionDetails, Button, Grid } from '@mui/material'
import { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useGetMeasureUnitsQuery } from '../../../app/redux-fetch/apiQuery'
import { useGetAllLoadTypesQuery, useGetCurrenciesByCustomerIdQuery } from '../../../app/redux-fetch/apiShipment'
import { convertToLabelValue } from '../../../app/utils'
import StyledAccordion from '../../../components/Accordions/StyledAccordion'
import StyledAccordionSummary from '../../../components/Accordions/StyledAccordionSummary'
import FormInput from '../../../components/Forms/FormInput'
import FormSelect from '../../../components/Forms/FormSelect'
import commonMessages from '../../../components/Messages/commonMessages'
import { LoadType, LocationType } from '../../../types/common'
import { useLocationModal } from '../hooks/useLocationModal'
import messages from './messages'
import { getLocationRevision } from './utils'

interface ConsignmentInformationFormProps {
  index: number
}

const ConsignmentInformationForm: React.FC<ConsignmentInformationFormProps> = ({
  index,
}: ConsignmentInformationFormProps) => {
  const { watch, setValue } = useFormContext()
  const { formatMessage } = useIntl()
  const [expanded, setExpanded] = useState(true)

  const customerId = watch('customerId')

  const handleToggleAccordion = () => {
    setExpanded((prev) => !prev)
  }

  const { data: currencies = [] } = useGetCurrenciesByCustomerIdQuery({
    customerId: customerId ?? 0,
    hideDisabled: true,
  })

  const { data: measureUnits = [] } = useGetMeasureUnitsQuery()

  const { data: packagingTypes } = useGetAllLoadTypesQuery()

  const massUnitOptions = useMemo(
    () =>
      convertToLabelValue(
        measureUnits
          .filter((x) => x.measureTypeId === 1)
          .map((x) => {
            return { name: x.units, id: x.id }
          })
      ),
    [measureUnits]
  )

  const lengthUnitOptions = useMemo(
    () =>
      convertToLabelValue(
        measureUnits
          .filter((x) => x.measureTypeId === 4)
          .map((x) => {
            return { name: x.units, id: x.id }
          })
      ),
    [measureUnits]
  )



  const resetFields = (fields: any) => {
    const loads = watch(`bookings.${index}.packaging`) || [];
    loads.forEach((_: any, idx: number) => {
      fields.forEach((field: any) => {
        setValue(`bookings.${index}.packaging.${idx}.${field}`, undefined)
      })
    })

    const waypoints = watch(`bookings.${index}.waypoints`) || [];

    waypoints.forEach((_: any, idx: number) => {
      setValue(`bookings.${index}.waypoints.${idx}.services`, [])
    })

    setValue(`bookings.${index}.shipFrom.services`, [])
    setValue(`bookings.${index}.deliverTo.services`, [])

  }

  const handlePackagingTypeChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newPackagingTypeId = Number(event.target.value)
    setValue(`bookings.${index}.loadTypeId`, newPackagingTypeId)
    const fieldsToReset = [
      'packagingTemplateId',
      'containerType',
      'weight',
      'weightUnit',
      'length',
      'width',
      'height',
      'dimensionUnit',
    ]
    resetFields(fieldsToReset)
    if (newPackagingTypeId === LoadType.parcel) {
      const packagings = watch(`bookings.${index}.packaging`) || []
      packagings.forEach((_: any, idx: number) => {
        setValue(`bookings.${index}.packaging.${idx}.weightUnit`, massUnitOptions[0]?.value ?? 0)
        setValue(`bookings.${index}.packaging.${idx}.dimensionUnit`, lengthUnitOptions[0]?.value ?? 0)
      })
    }
  }

  const oldLocations = watch(`bookings.${index}.shipper`)

  const { ModalComponent, locationOptions, locations, openModal } =
    useLocationModal({
      onSave: (id) => {
        setValue(`bookings.${index}.shipper`, id)
      },
      customerId,
      locationTypeId: LocationType.Shipper,
      oldLocations: oldLocations ? [oldLocations] : [],
    })

  return (
    <>
      {ModalComponent}
      < StyledAccordion
        sx={{
          '&:before': {
            display: 'none',
          },
        }
        }
        expanded={expanded}
        onChange={handleToggleAccordion}
      >
        <StyledAccordionSummary
          index={`consignment-info-content-${index}`}
          title='Consignment Information'
          expanded={expanded}
        ></StyledAccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <FormSelect
                name={`bookings.${index}.loadTypeId`}
                label={formatMessage(messages.consignmentType)}
                options={convertToLabelValue(packagingTypes ?? [])}
                placeholder={formatMessage(commonMessages.select)}
                onChange={handlePackagingTypeChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormSelect
                name={`bookings.${index}.shipperId`}
                label={formatMessage(messages.shipper)}
                options={locationOptions}
                allOptions={locationOptions}
                onChange={(e) => {
                  const revision = getLocationRevision(locations, +e.target.value)
                  setValue(`bookings.${index}.shipperRevision`, revision)
                }}
                actionLabel={
                  <Button
                    size='small'
                    onClick={() => {
                      openModal()
                    }}
                    sx={{ textTransform: 'none', p: 0, mb: '6px' }}
                  >
                    {formatMessage(commonMessages.addNew)}
                  </Button>
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormSelect
                name={`bookings.${index}.currencyId`}
                label={formatMessage(messages.currency)}
                options={convertToLabelValue(
                  currencies.map((currency) => ({
                    id: currency.id,
                    name: `${currency.iso3} - ${currency.localisedSymbol}`,
                  }))
                )}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormInput
                name={`bookings.${index}.referenceNumber`}
                label={formatMessage(messages.consignmentReferenceNumber)}
                placeholder={formatMessage(commonMessages.pleaseEnter)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <FormInput
                name={`bookings.${index}.description`}
                label={formatMessage(messages.consignmentDescription)}
                placeholder={formatMessage(messages.consignmentDescription)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormInput
                name={`bookings.${index}.trackingNumber`}
                label={formatMessage(messages.consignmentTrackingNumber)}
                placeholder={formatMessage(messages.systemGenerated)}
                disabled={true}
                fullWidth
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </StyledAccordion >
    </>
  )
}

export default ConsignmentInformationForm
