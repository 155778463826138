import { useEffect, useState } from 'react';
import { useLoggedInUser } from '../../../app/hooks/useLoggedInUser';
import { useModalWithForm } from '../../../app/hooks/useModalWithForm';
import { useGetLocationsQuery } from '../../../app/redux-fetch/apiCoreQuery';
import { convertToLabelValue } from '../../../app/utils';
import { Option } from '../../../components/Forms/FormSelect';
import { LocationType } from '../../../types/common';

interface UseLocationModalProps {
  onSave: (data: any) => void;
  customerId?: number;
  filterFunction?: (item: any) => boolean;
  oldLocations?: any[];
  locationTypeId?: LocationType;
  isSelectLocationType?: boolean;
}

export const useLocationModal = ({
  onSave,
  filterFunction,
  customerId,
  locationTypeId,
  isSelectLocationType = false,
  oldLocations: oldShipmentLocations = [],
}: UseLocationModalProps) => {
  const { tenantId } = useLoggedInUser();

  const [locationOptions, setLocationOptions] = useState<Option[]>([]);
  const [editingLocation, setEditingLocation] = useState(null);

  const { data: locations = [] } = useGetLocationsQuery({ tenantId, customerId, locationTypeId: locationTypeId });


  useEffect(() => {
    let filteredLocations = locations;

    if (filterFunction) {
      filteredLocations = filteredLocations.filter(filterFunction);
    }

    const mergedLocations = filteredLocations.map((location: any) => {
      return location;
    });

    const allLocations = [...mergedLocations];

    const locationOptionsValue = convertToLabelValue(allLocations);
    setLocationOptions(locationOptionsValue);
  }, [locations]);

  const handleOnSave = async (createdOrUpdatedLocation: any) => {
    const locationId = createdOrUpdatedLocation.data?.id;

    if (!locationId) {
      onSave(null);
      return;
    }
  };

  const {
    isModalOpen,
    openModal: baseOpenModal,
    closeModal,
    ModalComponent,
  } = useModalWithForm({
    type: 'location',
    onSave: handleOnSave,
    data: editingLocation ? editingLocation : { customerId, enabled: true, isOrderLocation: true, locationTypeId: locationTypeId ?? 0, isSelectLocationType: isSelectLocationType },
  });

  const openModal = (location?: any) => {
    setEditingLocation(location ? { ...location, isOrderLocation: true, isSelectLocationType: isSelectLocationType } : { customerId, isOrderLocation: true, locationTypeId: locationTypeId ?? 0, isSelectLocationType: isSelectLocationType });
    baseOpenModal();
  };

  return {
    locations,
    locationOptions,
    isModalOpen,
    openModal,
    closeModal,
    ModalComponent,
  };
};