import { z } from 'zod'

export const serviceLoadTypeSchema = z.object({
  id: z.number().optional(),
  name: z.string().min(1, 'Load type name is required'),
  loadTypeId: z.number().optional(),
  serviceId: z.number().optional(),
})

export const serviceSchema = z.object({
  id: z.number().optional(),
  tenantId: z.number(),
  name: z.string().min(1, 'Name is required'),
  description: z.string(),
  code: z.string().min(1, 'Code is required'),
  customerSelectable: z.boolean().default(false),
  enabled: z.boolean().default(true),
  inUse: z.boolean().optional(),
  loadTypes: z.array(serviceLoadTypeSchema).default([]),
})

export type ServiceFormData = z.infer<typeof serviceSchema>
export type ServiceLoadTypeFormData = z.infer<typeof serviceLoadTypeSchema>
