import { type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import TabWrapper from '../../components/TabWrapper/TabWrapper'
import { LocationType } from '../../types/common'
import LocationTableWrapper from './LocationTableWrapper'
import messages from './messages'


const getLocationTitle = (formatMessage: any, typeId?: LocationType) => {
  switch (typeId) {
    case LocationType.Airport:
      return formatMessage(messages.airports)
    case LocationType.FuelStation:
      return formatMessage(messages.fuelStations)
    case LocationType.Terminal:
      return formatMessage(messages.terminals)
    case LocationType.Seaport:
      return formatMessage(messages.seaports)
    default:
      return formatMessage(messages.standardLocations)
  }
}

const Locations = (): ReactElement<any, any> => {
  const { formatMessage } = useIntl()
  const renderLocationTable = (locationTypeId?: LocationType) => (
    <LocationTableWrapper
      locationTypeId={locationTypeId}
      title={getLocationTitle(formatMessage, locationTypeId)}
    />
  )

  const tabs = [
    {
      label: formatMessage(messages.standardLocations),
      content: renderLocationTable(LocationType.Standard)
    },
    {
      label: formatMessage(messages.airports),
      content: renderLocationTable(LocationType.Airport)
    },
    {
      label: formatMessage(messages.seaports),
      content: renderLocationTable(LocationType.Seaport)
    },
    {
      label: formatMessage(messages.fuelStations),
      content: renderLocationTable(LocationType.FuelStation)
    },
    {
      label: formatMessage(messages.terminals),
      content: renderLocationTable(LocationType.Terminal)
    },
  ]

  return (
    <TabWrapper tabs={tabs} />
  )
}

export default Locations
