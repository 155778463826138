import CancelIcon from '@mui/icons-material/CancelOutlined'
import { Box, Chip, Link } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useLoggedInUser } from '../../app/hooks/useLoggedInUser'
import { useNotificationStack } from '../../app/hooks/useNotificationStack'

import { useDeleteServiceMutation, useGetServicesQuery } from '../../app/redux-fetch/apiShipment'
import AddButton from '../../components/Buttons/AddButton'
import ConditionalRender from '../../components/ConditionalRender/ConditionalRender'
import MRTDataGridv2 from '../../components/MRTDataGrid/MRTDataGridv2'
import { TableTitleTypography } from '../../components/Typographies/TableTitleTypography'
import YesNoTypography from '../../components/YesNoTypograpphy/YesNoTypography'
import CreateServiceForm from './CreateServiceForm'
import EditServiceForm from './EditServiceForm'
import { messages } from './messages'
import { IService } from './types'

const ServiceTable = () => {
    const { formatMessage } = useIntl()
    const { tenantId } = useLoggedInUser()
    const [selectedId, setSelectedId] = useState(-1)
    const [createOpen, setCreateOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)

    const [deleteService] = useDeleteServiceMutation()
    const { enqueueSuccess, enqueueFailure } = useNotificationStack()

    const {
        data: services = [],
        isFetching,
        isSuccess,
    } = useGetServicesQuery({ tenantId })

    const columns: MRTColumnDef<IService>[] = [
        {
            accessorKey: 'name',
            header: formatMessage(messages.name),
            filterFn: 'includesString',
            enableColumnFilter: true,
        },
        {
            accessorKey: 'code',
            header: formatMessage(messages.code),
            filterFn: 'includesString',
            enableColumnFilter: true,
        },
        {
            accessorKey: 'customerSelectable',
            header: formatMessage(messages.customerSelectable),
            Cell: ({ cell }: any) => <YesNoTypography isYes={cell.getValue()} />,
            filterFn: 'equals',
            filterSelectOptions: [
                { text: 'Yes', value: true },
                { text: 'No', value: false }
            ],
            enableColumnFilter: true,
        },
        {
            accessorKey: 'loadTypes',
            header: formatMessage(messages.loadTypes),
            Cell: ({ cell }: any) => (
                <Box sx={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
                    {cell.getValue()?.map((loadType: any, index: number) => (
                        <Chip
                            key={index}
                            label={loadType.loadType.name}
                            size="small"
                            sx={{ cursor: 'pointer' }}
                        />
                    )) || ''}
                </Box>
            ),
            enableColumnFilter: true,
            filterFn: (row, columnId, filterValue) => {
                if (!filterValue || filterValue === '') return true;
                const loadTypes = row.getValue(columnId) as { loadType: { name: string } }[] | undefined;
                if (!loadTypes) return false;
                return loadTypes.some(item =>
                    item.loadType.name.toLowerCase().includes(filterValue.toLowerCase())
                );
            },
        },
        {
            accessorKey: 'enabled',
            header: formatMessage(messages.enabled),
            Cell: ({ cell }: any) => <YesNoTypography isYes={cell.getValue()} />,
            filterFn: 'equals',
            filterSelectOptions: [
                { text: 'Yes', value: true },
                { text: 'No', value: false }
            ],
            enableColumnFilter: true,
        },
        {
            accessorKey: 'inUse',
            header: formatMessage(messages.inUse),
            Cell: ({ cell }: any) => <YesNoTypography isYes={cell.getValue()} />,
            filterFn: 'equals',
            filterSelectOptions: [
                { text: 'Yes', value: true },
                { text: 'No', value: false }
            ],
            enableColumnFilter: true,
        },
    ]

    const handleDelete = (id: number) => {
        deleteService({ tenantId, body: { id } })
            .then(() => {
                enqueueSuccess(messages.serviceDeleted)
            })
            .catch(() => {
                enqueueFailure(messages.serviceNotDeleted)
            })
    }

    const handleCreateClose = () => setCreateOpen(false);
    const handleEditClose = () => {
        setEditOpen(false);
        setSelectedId(-1);
    };

    return (
        <>
            <CreateServiceForm
                isOpen={createOpen}
                handleClose={handleCreateClose}
            />
            <EditServiceForm
                serviceId={selectedId}
                isOpen={editOpen}
                handleClose={handleEditClose}
            />
            <Box>
                <TableTitleTypography>
                    {formatMessage(messages.serviceTableTitle)}
                </TableTitleTypography>
            </Box>

            <MRTDataGridv2
                initialState={{
                    pagination: { pageSize: 100, pageIndex: 0 },
                    density: 'comfortable',
                    sorting: [{ id: 'name', desc: false }],
                }}
                rightHeadingComponent={
                    <AddButton
                        text={formatMessage(messages.newServiceBtn)}
                        onClick={() => setCreateOpen(true)}
                    />
                }
                state={{
                    isLoading: isFetching || !isSuccess,
                }}
                columns={columns}
                data={services}
                getRowId={(row) => row.id as any}
                enableEditing
                enableRowActions
                positionActionsColumn='last'
                renderRowActions={({ row }) => (
                    <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                        <Link
                            underline='none'
                            color='secondary'
                            onClick={() => {
                                setSelectedId(row.original.id ?? -1)
                                setEditOpen(true)
                            }}
                            sx={{ fontWeight: 700, cursor: 'pointer' }}
                        >
                            {formatMessage(messages.editBtn)}
                        </Link>
                        <ConditionalRender condition={!row.original.inUse}>
                            <CancelIcon
                                color='secondary'
                                sx={{ cursor: 'pointer', pl: '4px' }}
                                onClick={() => handleDelete(row.original.id ?? -1)}
                            />
                        </ConditionalRender>
                    </Box>
                )}
            />
        </>
    )
}

export default ServiceTable
