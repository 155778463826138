import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch, TextField, Tooltip, Typography } from '@mui/material'
import { Form, Formik, FormikErrors, setNestedObjectValues } from 'formik'
import { useIntl } from 'react-intl'
import { useNotificationStack } from '../../app/hooks/useNotificationStack'
import { useAddCurrencyMutation, useUpdateCurrencyMutation } from '../../app/redux-fetch/apiShipment'
import RequiredAsterisk from '../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography } from '../../components/Typographies/styles'
import { currencyValidationSchema } from './currencyValidation'
import { messages } from './messages'
import { ICurrency } from './types'

type CurrencyFormProps = {
    currency: ICurrency
    handleClose: () => void
    isOpen: boolean
    customerId?: number
    enabledCurrencies: number
}


const CurrencyForm = ({ currency, customerId, handleClose, isOpen, enabledCurrencies }: CurrencyFormProps) => {
    const { formatMessage } = useIntl()

    const isNew = !currency?.id || currency?.id === 0

    const [addCurrency] = useAddCurrencyMutation()
    const [updateCurrency] = useUpdateCurrencyMutation()

    const { enqueueSuccess, enqueueFailure } = useNotificationStack()

    const onSubmit = async (data: ICurrency) => {
        if (customerId) {
            data.customerId = customerId
        }
        if (isNew) {
            await addCurrency({
                body: data
            }).then(() => {
                enqueueSuccess(messages.currencyCreated)
            })
                .catch(() => {
                    enqueueFailure(messages.currencyNotAdded)
                })
        }
        else {
            await updateCurrency({
                body: data
            })
                .then(() => {
                    enqueueSuccess(messages.currencyUpdated)
                })
                .catch(() => {
                    enqueueFailure(messages.currencyNotUpdated)
                })
        }
        handleClose()
    }

    return (
        <Formik
            initialValues={currency}
            validationSchema={currencyValidationSchema}
            onSubmit={onSubmit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                setFieldValue,
                submitForm,
                validateForm,
                setTouched
            }) => {
                return (
                    <Form>
                        <Dialog open={isOpen} onClose={handleClose}>
                            <DialogTitle>
                                <Typography
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: '20px',
                                    }}
                                >
                                    {formatMessage(messages.createEditCurrency)}
                                </Typography>
                            </DialogTitle>
                            <DialogContent sx={{ minWidth: '450px' }}>
                                <Box sx={{ mb: '16px' }}>
                                    <Box sx={{ mb: '16px' }}>
                                        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                                            {formatMessage(messages.currencyName)}
                                            <RequiredAsterisk />
                                        </SectionLabelTypography>
                                        <TextField
                                            fullWidth
                                            id={'name'}
                                            name={'name'}
                                            value={values?.name ?? ''}
                                            inputProps={{ maxLength: 100 }}
                                            onChange={handleChange}
                                            helperText={touched.name && errors?.name as React.ReactNode}
                                            error={touched.name && Boolean(errors?.name)}
                                        />
                                    </Box>
                                    <Box sx={{ mb: '16px' }}>
                                        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                                            {formatMessage(messages.iso3code)}
                                            <RequiredAsterisk />
                                        </SectionLabelTypography>
                                        <TextField
                                            fullWidth
                                            id={'iso3'}
                                            name={'iso3'}
                                            value={values?.iso3 ?? ''}
                                            inputProps={{ maxLength: 3 }}
                                            onChange={handleChange}
                                            helperText={touched.iso3 && errors?.iso3 as React.ReactNode}
                                            error={touched.iso3 && Boolean(errors?.iso3)}
                                        />
                                    </Box>
                                    <Box sx={{ mb: '16px' }}>
                                        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                                            {formatMessage(messages.symbol)}
                                            <RequiredAsterisk />
                                        </SectionLabelTypography>
                                        <TextField
                                            fullWidth
                                            id={'symbol'}
                                            name={'symbol'}
                                            value={values?.symbol ?? ''}
                                            inputProps={{ maxLength: 10 }}
                                            onChange={handleChange}
                                            helperText={touched.symbol && errors?.symbol as React.ReactNode}
                                            error={touched.symbol && Boolean(errors?.symbol)}
                                        />
                                    </Box>
                                    <Box sx={{ mb: '16px' }}>
                                        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                                            {formatMessage(messages.localisedSymbol)}
                                            <RequiredAsterisk />
                                        </SectionLabelTypography>
                                        <TextField
                                            fullWidth
                                            id={'localisedSymbol'}
                                            name={'localisedSymbol'}
                                            value={values?.localisedSymbol ?? ''}
                                            inputProps={{ maxLength: 10 }}
                                            onChange={handleChange}
                                            helperText={touched.localisedSymbol && errors?.localisedSymbol as React.ReactNode}
                                            error={touched.localisedSymbol && Boolean(errors?.localisedSymbol)}
                                        />
                                    </Box>

                                    <Box>
                                        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                                            {formatMessage(messages.enabledDisabled)}
                                        </SectionLabelTypography>
                                        <Tooltip
                                            title={enabledCurrencies === 1 && values.enabled ? formatMessage(messages.activeCurrencyRequired) : ''}
                                            arrow
                                        >
                                            <FormControlLabel
                                                sx={{ justifyContent: 'end', minHeight: '56px' }}
                                                value={values.enabled}
                                                labelPlacement='end'
                                                disabled={enabledCurrencies === 1 && values.enabled}
                                                control={
                                                    <Switch
                                                        color='secondary'
                                                        checked={values.enabled}
                                                        value={values.enabled}
                                                        onChange={({ target }) => {
                                                            setFieldValue('enabled', target.checked)
                                                        }}
                                                    />
                                                }
                                                label={'Enabled'}
                                            />
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button color='secondary' variant='outlined' onClick={() => { handleClose() }}>
                                    {formatMessage(messages.cancelBtn)}
                                </Button>
                                <Button color='secondary' variant='contained' onClick={() => {
                                    const handleSubmit = () => {
                                        validateForm().then((errors: FormikErrors<any>) => {
                                            submitForm().finally(() => {
                                                setTouched(setNestedObjectValues(errors, true))
                                            })
                                        })
                                    }
                                    handleSubmit()
                                }}>
                                    {isNew ? 'Create' : 'Update'}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Form>)
            }}
        </Formik >
    )
}

export default CurrencyForm;