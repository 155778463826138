import CancelIcon from '@mui/icons-material/CancelOutlined'
import { Box, Link } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useLoggedInUser } from '../../app/hooks/useLoggedInUser'
import { useNotificationStack } from '../../app/hooks/useNotificationStack'

import { useGetTransportModeQuery } from '../../app/redux-fetch/apiCoreQuery'
import { useDeleteCarrierMutation, useGetCarriersQuery } from '../../app/redux-fetch/apiShipment'
import AddButton from '../../components/Buttons/AddButton'
import ConditionalRender from '../../components/ConditionalRender/ConditionalRender'
import MRTDataGridv2 from '../../components/MRTDataGrid/MRTDataGridv2'
import { TableTitleTypography } from '../../components/Typographies/TableTitleTypography'
import YesNoTypography from '../../components/YesNoTypograpphy/YesNoTypography'
import CarrierForm from './CarrierForm'
import { messages } from './messages'
import { ICarrier } from './types'

const CarrierTable = () => {
    const { formatMessage } = useIntl()
    const { tenantId } = useLoggedInUser()
    const [selectedId, setSelectedId] = useState(-1)
    const [createOpen, setCreateOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)

    const [deleteCarrier] = useDeleteCarrierMutation()
    const { enqueueSuccess, enqueueFailure } = useNotificationStack()

    const {
        data: carriers = [],
        isFetching,
        isSuccess,
    } = useGetCarriersQuery({ tenantId })

    const {
        data: transportModes = [],
        isFetching: isFetchingTransportModes,
        isSuccess: isSuccessTransportModes,
    } = useGetTransportModeQuery()

    const columns: MRTColumnDef<ICarrier>[] = [
        {
            accessorKey: 'name',
            header: formatMessage(messages.name),
        },
        {
            accessorKey: 'code',
            header: formatMessage(messages.code),
        },
        {
            accessorKey: 'transportModeId',
            header: formatMessage(messages.transportMode),
            Cell: ({ cell }: any) => {
                const transportMode = transportModes.find(
                    (tm) => tm.id === cell.row.original.transportModeId,
                )
                return (
                    <span>
                        {isFetchingTransportModes || isSuccessTransportModes
                            ? transportMode?.name
                            : ''}
                    </span>
                )
            },
        },
        {
            accessorKey: 'inUse',
            header: formatMessage(messages.inUse),
            Cell: ({ cell }: any) => <YesNoTypography isYes={cell.getValue()} />,
        },
    ]

    const handleDelete = (id: number) => {
        deleteCarrier({ tenantId, id })
            .then(() => {
                enqueueSuccess(messages.carrierDeleted)
            })
            .catch(() => {
                enqueueFailure(messages.carrierNotDeleted)
            })
    }

    return (
        <>
            <ConditionalRender condition={createOpen}>
                <CarrierForm
                    carrier={{
                        tenantId,
                        name: '',
                        code: '',
                        transportModeId: 0,
                    }}
                    handleClose={() => setCreateOpen(false)}
                    isOpen={createOpen}
                />
            </ConditionalRender>

            <ConditionalRender condition={editOpen}>
                <CarrierForm
                    carrier={
                        carriers.find((c) => c.id === selectedId) ?? {
                            tenantId,
                            name: '',
                            code: '',
                            transportModeId: 0,
                        }
                    }
                    handleClose={() => setEditOpen(false)}
                    isOpen={editOpen}
                />
            </ConditionalRender>

            <Box>
                <TableTitleTypography>
                    {formatMessage(messages.carrierTableTitle)}
                </TableTitleTypography>
            </Box>

            <MRTDataGridv2
                initialState={{
                    pagination: { pageSize: 100, pageIndex: 0 },
                    density: 'comfortable',
                    sorting: [{ id: 'name', desc: false }],
                }}
                rightHeadingComponent={
                    <AddButton
                        text={formatMessage(messages.newCarrierBtn)}
                        onClick={() => setCreateOpen(true)}
                    />
                }
                state={{
                    isLoading: isFetching || !isSuccess,
                }}
                columns={columns}
                data={carriers}
                getRowId={(row) => row.id as any}
                enableEditing
                enableRowActions
                positionActionsColumn='last'
                renderRowActions={({ row }) => (
                    <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                        <Link
                            underline='none'
                            color='secondary'
                            onClick={() => {
                                setSelectedId(row.original.id ?? -1)
                                setEditOpen(true)
                            }}
                            sx={{ fontWeight: 700, cursor: 'pointer' }}
                        >
                            {formatMessage(messages.editBtn)}
                        </Link>
                        <ConditionalRender condition={row.original.inUse === false}>
                            <CancelIcon
                                color='secondary'
                                sx={{ cursor: 'pointer', pl: '4px' }}
                                onClick={() => handleDelete(row.original.id ?? -1)}
                            />
                        </ConditionalRender>
                    </Box>
                )}
            />
        </>
    )
}

export default CarrierTable
