import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { useLocationByIdQuery } from '../../app/redux-fetch/apiCoreQuery'
import SpinnerModalBlock from '../../components/Spinner/SpinnerModalBlock'
import {
  fetchAllCountriesSelector,
  fetchAllTransportModesSelector,
  fetchSystemCurrenciesSelector,
} from '../Shipments/selectors'
import {
  fetchAllCountriesThunk,
  fetchAllLocationTypesThunk,
  fetchAllTransportModesThunk,
  fetchSystemCurrenciesThunk,
} from '../Shipments/slice'
import { fetchLoggedInUserSelector } from '../selectors'
import { ILoggedInUser } from '../types'
import LocationForm from './LocationForm'

interface IEditLocationProps {
  id: number
  locationTypeId?: number
  isDisabled?: boolean
}

const EditLocation = ({ id, isDisabled, locationTypeId }: IEditLocationProps) => {
  const dispatch = useAppDispatch()

  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

  const isCountriesLoaded = useAppSelector(fetchAllCountriesSelector.isFinished)
  const isTransportModeLoaded = useAppSelector(fetchAllTransportModesSelector.isFinished)
  const isSystemCurrencyLoaded = useAppSelector(fetchSystemCurrenciesSelector.isFinished)

  const isCountriesFetching = useAppSelector(fetchAllCountriesSelector.isFetching)
  const isTransportModeFetching = useAppSelector(fetchAllTransportModesSelector.isFetching)
  const isSystemCurrencyFetching = useAppSelector(fetchSystemCurrenciesSelector.isFetching)

  const tenantId = loggedInUser.tenantId
  const customerId = loggedInUser.customerId


  const {
    data: location,
    isSuccess: locationLoaded,
    refetch,
  } = useLocationByIdQuery({
    locationId: +id,
    tenantId: tenantId ?? -1,
    customerId: customerId,
  })

  useEffect(() => {
    refetch()
    dispatch(fetchAllCountriesThunk())
    dispatch(fetchAllTransportModesThunk())
    dispatch(fetchAllLocationTypesThunk())
    dispatch(fetchSystemCurrenciesThunk())
  }, [customerId])

  const isLoaded =
    locationLoaded &&
    isCountriesLoaded &&
    !isCountriesFetching &&
    isTransportModeLoaded &&
    !isTransportModeFetching &&
    isSystemCurrencyLoaded &&
    !isSystemCurrencyFetching

  if (!isLoaded) return <SpinnerModalBlock />

  return (
    <>
      <LocationForm location={location} isDisabled={isDisabled} locationTypeId={locationTypeId} />
    </>
  )
}

export default EditLocation
