import { Box, Typography } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { FC, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useLoggedInUser } from '../../app/hooks/useLoggedInUser'
import { useGetCountriesQuery } from '../../app/redux-fetch/apiCoreQuery'
import { useGetCustomersQuery } from '../../app/redux-fetch/apiQuery'
import YesNoTypography from '../../components/YesNoTypograpphy/YesNoTypography'
import { LocationType } from '../../types/common'
import { ILocationByTenantCustomer } from '../Shipments/types'
import LocationsTable from './LocationsTable'
import messages from './messages'

interface LocationTableWrapperProps {
    locationTypeId?: LocationType
    title: string
}

const LocationTableWrapper: FC<LocationTableWrapperProps> = ({
    locationTypeId,
    title,
}) => {
    const { formatMessage } = useIntl()
    const { tenantId, isTenantUser } = useLoggedInUser()
    const { data: countries = [] } = useGetCountriesQuery()
    const {
        data: customers = [],
    } = useGetCustomersQuery(tenantId ?? -1, { skip: !isTenantUser })


    const columns = useMemo<MRTColumnDef<ILocationByTenantCustomer>[]>(() => {
        if (isTenantUser) {
            return [
                {
                    accessorKey: 'name',
                    header: formatMessage(messages.name),
                },
                {
                    accessorFn: (row) => row.locationType?.name ?? '',
                    header: formatMessage(messages.locationType),
                },
                {
                    accessorFn: (row) => {
                        if (row.customerId) {
                            const customer = customers?.find((item) => item.id === row.customerId)
                            return customer?.name ?? ''
                        }
                        return ''
                    },
                    header: formatMessage(messages.customerLabel),
                },
                {
                    accessorKey: 'address.address1',
                    header: formatMessage(messages.address),
                },
                {
                    accessorKey: 'address.city',
                    header: formatMessage(messages.city),
                },
                {
                    accessorKey: 'address.state',
                    header: formatMessage(messages.state),
                },
                {
                    accessorKey: 'enabled',
                    header: 'Enabled',
                    Cell: ({ cell }: any) => {
                        return <YesNoTypography isYes={cell.getValue()} />
                    }
                },
                {
                    id: 'address.countryId',
                    header: formatMessage(messages.country),
                    accessorFn: (row) => {
                        const country = countries?.find((item: any) => item.id === row.address?.countryId)
                        return country?.name ?? ''
                    },
                    Cell: ({ cell }: any) => {
                        return <Typography>{cell.getValue()}</Typography>
                    },
                },
            ]
        } else {
            return [
                {
                    accessorKey: 'name',
                    header: formatMessage(messages.name),
                },
                {
                    accessorFn: (row) => row.locationType?.name ?? '',
                    header: formatMessage(messages.locationType),
                },
                {
                    accessorKey: 'address.address1',
                    header: formatMessage(messages.address),
                },
                {
                    accessorKey: 'address.city',
                    header: formatMessage(messages.city),
                },
                {
                    accessorKey: 'address.state',
                    header: formatMessage(messages.state),
                },
                {
                    accessorKey: 'address.enabled',
                    header: 'Enabled',
                    Cell: ({ cell }: any) => {
                        return <Typography>{cell.getValue() ? 'Yes' : 'No'}</Typography>
                    }
                },
                {
                    id: 'address.countryId',
                    header: formatMessage(messages.country),
                    accessorFn: (row) => {
                        const country = countries?.find((item: any) => item.id === row.address?.countryId)
                        return country?.name ?? ''
                    },
                    Cell: ({ cell }: any) => {
                        return <Typography>{cell.getValue()}</Typography>
                    },
                },
            ]
        }
    }, [countries, isTenantUser, customers])

    return (
        <Box sx={{ width: '100%' }}>
            <LocationsTable
                columns={columns}
                locationTypeId={locationTypeId}
                title={title}
            />
        </Box>
    )
}

export default LocationTableWrapper
