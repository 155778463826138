export interface CountryDto {
  id?: number
  name?: string
  iso2?: string
  iso3?: string
}

export interface AddressDto {
  id?: number
  countryId?: number
  country?: CountryDto
  address1?: string
  address2?: string
  address3?: string
  city?: string
  state?: string
  postalCode?: string
}

export interface AddressAuditDto {
  id?: number
  userId?: number
  auditDate?: string
  action?: string
  addressId?: number
  countryId?: number
  country?: CountryDto
  address1?: string
  address2?: string
  address3?: string
  city?: string
  state?: string
  postalCode?: string
}

export interface ContactCategoryDto {
  id?: number
  name?: string
  tenantId?: number
  customerId?: number
  inUse?: boolean
  contactTypes?: ContactTypeDto[]
}

export interface ContactTypeDto {
  id?: number
  name?: string
  tenantId?: number
  customerId?: number
  contactCategoryId?: number
  inUse?: boolean
  contactCategory?: ContactCategoryDto
}

export interface ContactDto {
  id?: number
  entityId?: number
  contactTypeId?: number
  contactType?: ContactTypeDto
  name?: string
  email?: string
  phone?: string
}

export interface DocumentCategoryDto {
  id?: number
  name?: string
  tenantId?: number
  customerId?: number
  documentTypes?: DocumentTypeDto[]
}

export interface DocumentTypeDto {
  id?: number
  name?: string
  documentCategoryId?: number
  documentCategory?: DocumentCategoryDto
}

export interface DocumentDto {
  id?: number
  entityId?: number
  fileId?: string
  documentTypeId?: number
  documentType?: DocumentTypeDto
  name?: string
  issueDate?: string
  expiryDate?: string
}

export interface IndustryDto {
  id?: number
  name?: string
}

export interface MeasureUnitDto {
  id?: number
  tenantId?: number
  customerId?: number
  measureTypeId?: number
  name?: string
  units?: string
  siConversionFactor?: number
  isSiUnit?: boolean
}

export interface TemperatureRangeDto {
  id?: number
  name?: string
}

export interface TemperatureSettingDto {
  id?: number
  temperatureRangeId?: number
  temperatureRange?: TemperatureRangeDto
  temperatureUnitId?: number
  temperatureUnit?: MeasureUnitDto
  setPoint?: number
  upperThresholdWarning?: number
  upperThresholdCritical?: number
  lowerThresholdWarning?: number
  lowerThresholdCritical?: number
}

export interface PhysicalPropertiesDto {
  id?: number
  weightMeasureUnitId?: number
  volumeMeasureUnitId?: number
  densityMeasureUnitId?: number
  lengthMeasureUnitId?: number
  netWeight?: number
  grossWeight?: number
  chargeableWeight?: number
  volume?: number
  density?: number
  height?: number
  length?: number
  width?: number
  radius?: number
}

export interface LoadTypeDto {
  id?: number
  name?: string
}

export interface LoadTemplateDto {
  id?: number
  loadTypeId?: number
  loadType?: LoadTypeDto
  physicalPropertiesId?: number
  physicalProperties?: PhysicalPropertiesDto
  name?: string
}

export interface LoadDetailDto {
  id?: number
  loadTypeId?: number
  loadType?: LoadTypeDto
  loadTemplateId?: number
  loadTemplate?: LoadTemplateDto
  physicalPropertiesId?: number
  physicalProperties?: PhysicalPropertiesDto
  quantity?: number
  containerNumber?: string
  genSetRequired?: boolean
  isStackable?: boolean
}

export interface LoadContentsDto {
  id?: number
  loadId?: number
  productId?: number
  countryOfOriginId?: number
  countryOfOrigin?: CountryDto
  unitPrice?: number
  quantity?: number
  description?: string
}

export interface LoadDto {
  id?: number
  consignmentId?: number
  shipmentBookingId?: number
  loadDetailId?: number
  detail?: LoadDetailDto
  temperatureSettingId?: number
  temperatureSetting?: TemperatureSettingDto
  value?: number
  quantity?: number
  isHazardous?: boolean
  description?: string
  trackingNumbers?: (string | undefined)[]
  contents?: LoadContentsDto[]
}

export interface TransportModeDto {
  id?: number
  name?: string
}

export interface CarrierDto {
  id?: number
  tenantId?: number
  transportModeId?: number
  transportMode?: TransportModeDto
  name?: string
  code?: string
}

export interface TransitDetailDto {
  id?: number
  waypointId?: number
  transportModeId?: number
  transportMode?: TransportModeDto
  carrierId?: number
  carrier?: CarrierDto
  transitIdentifier?: string
  isCrossBorder?: boolean
}

export interface LocationTypeDto {
  id?: number
  tenantId?: number
  customerId?: number
  name?: string
}

export interface CoordinateDto {
  id?: number
  locationId?: number
  lat?: number
  lng?: number
}

export interface CoordinateAuditDto {
  id?: number
  userId?: number
  auditDate?: string
  action?: string
  locationId?: number
  latitude?: number
  longitude?: number
}

export interface LocationDto {
  id?: number
  tenantId?: number
  customerId?: number
  locationTypeId?: number
  locationType?: LocationTypeDto
  addressId?: number
  address?: AddressDto
  defaultTransportModeId?: number
  defaultTransportMode?: TransportModeDto
  name?: string
  lat?: number
  lng?: number
  geofenceHash?: number
  deleted?: boolean
  geofence?: CoordinateDto[]
  contacts?: ContactDto[]
}

export interface CustomerDto {
  id?: number
  tenantId?: number
  locationId?: number
  industryId?: number
  industry?: IndustryDto
  location?: LocationDto
  defaultCurrencyId?: number
  currency?: CurrencyDto
  contacts?: ContactDto[]
  documents?: DocumentDto[]
  registrationNumber?: string
  accountNumber?: string
  vatNumber?: string
  webSite?: string
  email?: string
  secondaryEmail?: string
  alias?: string
  name?: string
  disabled?: boolean
  accountOwnerEmail?: string
  accountOwnerFirstName?: string
  accountOwnerLastName?: string
  accountOwnerId?: number
}

export interface CurrencyDto {
  id?: number
  tenantId?: number
  customerId?: number
  name?: string
  iso3?: string
  symbol?: string
  localisedSymbol?: string
  enabled?: boolean
  inUse?: boolean
}

export interface AssetRequestDto {
  id?: number
  shipmentId?: number
  assetConfigurationId?: number
  genSetRequired?: boolean
  quantity?: number
  displayOrder?: number
  consignmentIds?: number[]
}

export interface AssetRequestConsignmentAllocationDto {
  id?: number
  assetRequestId?: number
  assetRequest?: AssetRequestDto
  consignmentId?: number
}

export interface RouteConsignmentHistoryDto {
  consignmentId?: number
  consignmentDescription?: string
  action?: string
  location?: string
  scheduledDate?: string
  completedDate?: string
}

export interface WaypointTypeDto {
  id?: number
  name?: string
}

export interface WaypointDto {
  id?: number
  consignmentId?: number
  waypointTypeId?: number
  waypointType?: WaypointTypeDto
  contactId?: number
  locationId?: number
  location?: LocationDto
  locationRevision?: number
  scheduledDate?: string
  sequence?: number
  clearingRequired?: boolean
  instructions?: string
  transitDetail?: TransitDetailDto
  bookingId?: number
  services?: ServiceWaypoint[]
}

export interface ConsignmentDto {
  id?: number
  bookingId?: number
  shipmentId?: number
  shipment?: ShipmentDto
  shipperId?: number
  shipper?: LocationDto
  shipperRevision?: number
  loadTypeId?: number
  currencyId?: number
  currency?: CurrencyDto
  description?: string
  referenceNumber?: string
  trackingNumber?: string
  loads?: LoadDto[]
  waypoints?: WaypointDto[]
  assetRequestAllocations?: AssetRequestConsignmentAllocationDto[]
  routeConsignmentHistories?: RouteConsignmentHistoryDto[]
  containerCollections?: ContainerCollectionDto[]
}

export interface ShipmentDto {
  id?: number
  tenantId?: number
  customerId?: number
  customer?: CustomerDto
  shipper?: LocationDto
  locationRevision?: number
  code?: string
  customerReferenceNumber?: string
  description?: string
  status?: string
  submittedDate?: string
  acceptedDate?: string
  underReviewUntil?: string
  underReviewByUserId?: number
  revision?: number
  bookings?: ConsignmentDto[]
  statuses?: any
  assetRequests?: AssetRequestDto[]
  lastStatusChangeDate?: string
}

export enum StopType {
  ShipFrom = 'shipFrom',
  DeliveryTo = 'deliverTo',
  Waypoint = 'waypoints',
}

export interface ServiceWaypointLoad {
  id?: number
  serviceWaypointId?: number
  loadId?: number
}
export interface ServiceWaypoint {
  id?: number
  serviceId?: number
  waypointId?: number
  loads?: ServiceWaypointLoad[]
}

export interface ContainerCollectionDto {
  id?: number
  shipmentBookingId?: number
  shipmentBooking?: ShipmentDto
  loadTemplateId?: number
  loadTemplate?: LoadTemplateDto
  quantity?: number
  carrierId?: number
  carrier?: CarrierDto
  locationId?: number
  location?: LocationDto
  croNumber?: string
  croValidFrom?: Date
  croValidTo?: Date
}

export interface AuditDto {
  userId?: number
}
